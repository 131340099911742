<template>
  <div class="birthday-input-form">
    <div class="heading">
      <div class="text"><slot></slot></div>
      <div class="required" v-if="requiredFlg">必須</div>
    </div>
    <div class="body">
      <div class="select-boxes">
        <base-select-box
          :selectData="years"
          :styles="yearsBoxStyle"
          @blur="focus"
          @input="focus"
          v-model="year"
        />
        <base-select-box
          class="month"
          :selectData="months"
          :styles="monthsBoxStyle"
          :disabled="disabledMonth"
          @blur="focus"
          @input="focus"
          v-model="month"
        />
        <base-select-box
          class="day"
          :selectData="days"
          :styles="daysBoxStyle"
          :disabled="disabledDay"
          @blur="focus"
          @input="focus"
          v-model="day"
        />
      </div>
      <span>
        <div v-if="errorShowFlg && focusFlg && error.length > 0" class="error">
          {{ error }}
        </div>
      </span>
    </div>
  </div>
</template>

<script>
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import moment from 'moment'
import { convertEraName } from '@/utils/date'
import { VALID_DATE_REGEX, VALID_DATE_EMPTY_REGEX } from '@/utils/define'

export default {
  name: 'BirthdayInputForm',

  components: {
    BaseSelectBox
  },

  props: {
    birthday: { type: String, default: '' },
    requiredFlg: { type: Boolean, default: false },
    yearsBoxStyle: { type: Object },
    monthsBoxStyle: { type: Object },
    daysBoxStyle: { type: Object },
    errorShowFlg: { type: Boolean, default: false }
  },

  data() {
    return {
      selectedYear:
        this.birthday.length >= 4 ? Number(this.birthday.slice(0, 4)) : 0,
      selectedMonth:
        this.birthday.length >= 6 ? Number(this.birthday.slice(4, 6)) : 0,
      selectedDay:
        this.birthday.length === 8 ? Number(this.birthday.slice(6, 8)) : 0,
      focusFlg: false
    }
  },

  computed: {
    year: {
      get() {
        return this.birthday.length >= 4 ? Number(this.birthday.slice(0, 4)) : 0
      },
      set(val) {
        this.selectedYear = val
      }
    },
    month: {
      get() {
        return this.birthday.length >= 6 ? Number(this.birthday.slice(4, 6)) : 0
      },
      set(val) {
        this.selectedMonth = val
      }
    },
    day: {
      get() {
        return this.birthday.length === 8
          ? Number(this.birthday.slice(6, 8))
          : 0
      },
      set(val) {
        this.selectedDay = val
      }
    },
    years() {
      const start = 1900
      const end = moment().year()
      const yearsArray = [...Array(end - start + 1).keys()]
        .map(v => {
          return {
            id: v + start,
            name: `${v + start}年(${convertEraName(v + start)})`
          }
        })
        .reverse()
      return [{ id: 0, name: '' }].concat(yearsArray)
    },
    months() {
      return this.crateSelectData(12, '月')
    },
    days() {
      return this.year === 0 || this.month === 0
        ? this.crateSelectData(31, '日')
        : this.crateSelectData(this.getFinalDate(this.year, this.month), '日')
    },
    newBirthday() {
      const year = this.selectedYear === 0 ? '' : `${this.selectedYear}`
      const month =
        this.selectedMonth === 0 ? '' : `0${this.selectedMonth}`.slice(-2)
      const day = this.selectedDay === 0 ? '' : `0${this.selectedDay}`.slice(-2)
      const date = year + month + day
      return date
    },
    disabledMonth() {
      return this.year === 0 ? true : false
    },
    disabledDay() {
      return this.year === 0 || this.month === 0 ? true : false
    },
    error() {
      const regex = this.requiredFlg ? VALID_DATE_REGEX : VALID_DATE_EMPTY_REGEX
      const invalid = !regex.test(this.birthday)
      const target = invalid
        ? this.year === 0
          ? '年'
          : this.month === 0
          ? '月'
          : this.day === 0
          ? '日'
          : ''
        : ''
      const text = target !== '' ? `${target}を選択してください` : ''
      return text
    }
  },

  watch: {
    birthday: function() {
      this.selectedYear =
        this.birthday.length >= 4 ? Number(this.birthday.slice(0, 4)) : 0
      this.selectedMonth =
        this.birthday.length >= 6 ? Number(this.birthday.slice(4, 6)) : 0
      this.selectedDay =
        this.birthday.length === 8 ? Number(this.birthday.slice(6, 8)) : 0
    },
    selectedYear: function(y) {
      if (y === 0) {
        this.selectedMonth = 0
        this.selectedDay = 0
      } else if (
        y !== 0 &&
        this.selectedMonth !== 0 &&
        this.selectedDay !== 0
      ) {
        if (!moment([y, this.selectedMonth - 1, this.selectedDay]).isValid()) {
          this.selectedDay = this.getFinalDate(y, this.selectedMonth)
        }
      }
    },
    selectedMonth: function(m) {
      if (m === 0) {
        this.selectedDay = 0
      } else if (this.selectedYear !== 0 && m !== 0 && this.selectedDay !== 0) {
        if (!moment([this.selectedYear, m - 1, this.selectedDay]).isValid()) {
          this.selectedDay = this.getFinalDate(this.selectedYear, m)
        }
      }
    },
    newBirthday: function() {
      this.inputBirthday()
    }
  },

  methods: {
    crateSelectData(number, text) {
      const selectData = [...Array(number).keys()].map(v => {
        return { id: v + 1, name: ('0' + String(v + 1)).slice(-2) + text }
      })
      return [{ id: 0, name: '' }].concat(selectData)
    },
    getFinalDate(year, month) {
      return moment([year, month - 1])
        .endOf('month')
        .date()
    },
    inputBirthday() {
      this.$emit('input', this.newBirthday, 'birthday')
    },
    focus() {
      this.focusFlg = true
    }
  }
}
</script>

<style lang="scss" scoped>
.birthday-input-form {
  display: flex;
  align-items: flex-start;
  > .heading {
    display: flex;
    align-items: center;
    height: 33px;
    width: 40%;
    font-size: 15px;
    > .required {
      margin-left: 10px;
      font-size: 13px;
      color: #{$tomato};
    }
  }
  > .body {
    width: 60%;
    > .select-boxes {
      display: flex;
      & .month,
      .day {
        margin-left: 10px;
      }
    }
    // > .err-msg {
    //   font-size: 13px;
    //   color: #{$tomato};
    // }
    @include validate-message();
  }
}
</style>
