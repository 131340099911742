<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div class="vaccine-popup" @keyup.esc.stop="handleCancel" tabindex="-1">
      <validation-observer class="validation-observer" v-slot="{ invalid }">
        <popup
          :buttons="['閉じる', '登録']"
          :disabledFlg="
            invalid || waitFlg || validationError || lookOnlyFlg === 1
          "
          @cancel="handleCancel"
          @decision="handleDecision"
          @click-close-mark="handleCancel"
        >
          <template v-slot:content>
            <div class="content">
              <div class="header">
                <div class="title">予防接種設定</div>
                <base-button-small-red
                  v-if="lookOnlyFlg === 0 && patientVaccine"
                  class="delete"
                  @click="handleDelete"
                  >削除</base-button-small-red
                >
              </div>
              <div class="vaccine">
                <div class="label">種類</div>
                <div v-if="patientVaccine" data-test="vaccine-name">
                  {{ vaccineName }}
                </div>
                <base-select-box
                  v-else
                  class="select-box"
                  :selectData="selectVaccineData"
                  v-model="inputPatientVaccine.vaccineId"
                />
              </div>
              <div class="vaccine-periods">
                <div class="vaccine-period-table">
                  <div
                    v-for="(period, index) in displayPatientVaccinePeriods"
                    :key="period.key"
                    class="row"
                  >
                    <div class="dates-area">
                      <div class="label">接種期間{{ index + 1 }}</div>
                      <base-date-picker
                        class="date-picker"
                        :clearFlg="true"
                        :value="period.startDate"
                        :maxDate="period.endDate"
                        :popoverProps="{ visibility: 'click' }"
                        @input="inputDate('start', period, $event)"
                        @clear="clearDate('start', period)"
                      />
                      <div class="wavy-line">～</div>
                      <base-date-picker
                        v-if="period.startDate"
                        class="date-picker"
                        :clearFlg="true"
                        :value="period.endDate"
                        :minDate="period.startDate"
                        :popoverProps="{ visibility: 'click' }"
                        @input="inputDate('end', period, $event)"
                        @clear="clearDate('end', period)"
                      />
                    </div>
                    <div
                      v-if="index === displayPatientVaccinePeriods.length - 1"
                      class="repeat"
                    >
                      <base-check-box
                        class="checkbox"
                        data-test="repeat-flg"
                        :labelText="'年繰返し設定'"
                        :numFlg="true"
                        :isChecked="period.repeatFlg"
                        :disabled="period.startDate === ''"
                        v-model="period.repeatFlg"
                      />
                    </div>
                    <base-button-trash
                      v-if="lookOnlyFlg === 0"
                      class="button-trash"
                      :orangeFlg="true"
                      :styles="{
                        backgroundColor: 'white',
                        borderColor: '#ef6c00',
                        width: '30px',
                        height: '30px'
                      }"
                      @click="deletePeriod(period.key)"
                    />
                    <div
                      v-if="
                        overlappingPeriod &&
                          period.key === overlappingPeriod.key
                      "
                      class="error"
                      data-test="period-error"
                    >
                      有効な期間を入力してください
                    </div>
                    <div
                      v-if="
                        !overlappingPeriod &&
                          tooLongPeriod &&
                          period.key === tooLongPeriod.key
                      "
                      class="error"
                      data-test="period-error"
                    >
                      一年より短い期間を入力してください
                    </div>
                  </div>
                </div>
                <base-button-plus
                  class="add-button"
                  :styles="{ width: '130px' }"
                  :text="'期間追加'"
                  @click="addPeriod"
                />
              </div>
              <div class="reminder">
                <base-check-box
                  class="checkbox"
                  data-test="alert-flg"
                  :isChecked="inputPatientVaccine.alertFlg"
                  :labelText="'アラート登録'"
                  :numFlg="true"
                  v-model="inputPatientVaccine.alertFlg"
                />
                <validation-provider
                  v-if="inputPatientVaccine.alertFlg"
                  v-slot="{ errors }"
                  :immediate="true"
                  :rules="{ intRule: true, requiredRule: true }"
                  ><div class="reminder-setting">
                    次の接種期間開始の<base-text-box
                      v-model="inputPatientVaccine.alertBeforeDay"
                      class="text-box"
                      data-test="alert-before-day"
                      :maxlength="3"
                      :styles="{ width: '50px', height: '33px' }"
                      :fullWidthNumbersToHalfWidthNumbers="true"
                    />日<base-select-box
                      class="select-box"
                      :selectData="beforeAfterSelectData"
                      v-model="inputPatientVaccine.alertBeforeFlg"
                      data-test="before-after"
                    />
                  </div>
                  <div class="error">{{ errors[0] }}</div>
                </validation-provider>
              </div>
            </div>
          </template>
        </popup>
      </validation-observer>
      <announce-popup
        v-if="announcePopupFlg"
        v-bind="announcePopup"
        :disabled="waitFlg"
        @close="announcePopup.close"
        @cancel="announcePopup.cancel"
        @decision="announcePopup.decision"
        >{{ announcePopup.message }}</announce-popup
      >
    </div>
  </focus-trap>
</template>

<script>
import BaseButtonPlus from '@/components/parts/atoms/BaseButtonPlus'
import BaseButtonTrash from '@/components/parts/atoms/BaseButtonTrash'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import BaseDatePicker from '@/components/parts/atoms/BaseDatePicker'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import Popup from '@/components/popups/Popup'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import { FocusTrap } from 'focus-trap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'VaccinePopup',

  components: {
    BaseButtonPlus,
    BaseButtonSmallRed,
    BaseButtonTrash,
    BaseCheckBox,
    BaseDatePicker,
    BaseSelectBox,
    BaseTextBox,
    Popup,
    AnnouncePopup,
    FocusTrap,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [CheckPopupInputDifference],

  props: {
    patientId: { type: Number, required: true },
    apiFlg: { type: Boolean, default: false },
    patientVaccine: { type: Object },
    patientVaccines: { type: Array, default: () => [] },
    patientVaccinePeriods: { type: Array, default: () => [] }
  },

  data() {
    return {
      basePeriod: {
        startDate: '',
        endDate: '',
        repeatFlg: 0
      },
      beforeAfterSelectData: [
        { id: 1, name: '前' },
        { id: 2, name: '後' }
      ],
      key: 0,
      inputPatientVaccine: {},
      inputPatientVaccinePeriods: [],
      initialData: {},
      inputData: {},
      announcePopupFlg: false,
      announcePopup: {
        title: '',
        buttons: ['閉じる'],
        type: '',
        message: '',
        layerNumber: 2,
        cancel: () => {},
        decision: () => {},
        close: () => {}
      },
      noDatFlg: false,
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      getMasterDatum: 'master/getDataById',
      selectVaccinesZero: 'master/selectVaccinesZero'
    }),
    diffFlg() {
      return !_.isEqual(this.initialData, this.inputData)
    },
    selectVaccineData() {
      const usedVaccineIds = this.patientVaccines.map(v => v.vaccineId)
      const dogOneVaccineIds = [999, 6, 7, 8, 9, 10, 11, 12, 14]
      const dogManyVaccineIds = [15, 16, 17, 1, 2, 3, 4, 5, 13]
      const catOneVaccineIds = [104, 105, 106, 107, 108, 109, 111]
      const catManyVaccineIds = [101, 102, 103, 110]
      const selectVaccines = [
        0,
        ...dogOneVaccineIds,
        ...dogManyVaccineIds,
        ...catOneVaccineIds,
        ...catManyVaccineIds
      ].flatMap(vaccineId => {
        return usedVaccineIds.includes(vaccineId)
          ? []
          : this.selectVaccinesZero.find(vaccine => vaccine.id === vaccineId)
      })
      return selectVaccines
    },
    vaccineName() {
      return this.getMasterDatum('vaccines', this.patientVaccine.vaccineId)
        ?.name
    },
    displayPatientVaccinePeriods() {
      return this.inputPatientVaccinePeriods.filter(v => v.delFlg !== 1)
    },
    overlappingPeriod() {
      const periods = this.displayPatientVaccinePeriods.filter(
        v => v.startDate !== ''
      )
      return periods.find((period, i) => {
        if (i === 0) return false
        else {
          const previousPeriod = periods[i - 1]
          if (previousPeriod.endDate)
            return period.startDate <= previousPeriod.endDate
          else return period.startDate <= previousPeriod.startDate
        }
      })
    },
    tooLongPeriod() {
      const periods = this.displayPatientVaccinePeriods.filter(
        v => v.startDate !== '' && v.endDate !== ''
      )
      return periods.find(
        v =>
          moment(v.endDate, 'YYYYMMDD').diff(
            moment(v.startDate, 'YYYYMMDD'),
            'years'
          ) >= 1
      )
    },
    validationError() {
      return Boolean(
        this.inputPatientVaccine.vaccineId === 0 ||
          this.overlappingPeriod ||
          this.tooLongPeriod
      )
    }
  },

  created() {
    if (this.patientVaccine) {
      this.inputPatientVaccine = {
        ...this.patientVaccine,
        alertBeforeFlg: this.patientVaccine.alertBeforeFlg === 1 ? 1 : 2
      }
    } else {
      this.inputPatientVaccine = {
        patientId: this.patientId,
        vaccineId: 0,
        alertFlg: 0,
        alertBeforeDay: 1,
        alertBeforeFlg: 1
      }
    }
    this.inputPatientVaccinePeriods =
      this.patientVaccinePeriods.length !== 0
        ? this.patientVaccinePeriods.map(v => {
            return { ...v, key: this.key++ }
          })
        : [{ ...this.basePeriod, key: this.key++ }]
    this.inputData = {
      patientVaccine: this.inputPatientVaccine,
      patientVaccinePeriods: this.inputPatientVaccinePeriods
    }
    this.initialData = _.cloneDeep(this.inputData)
  },

  methods: {
    inputDate(startOrEnd, period, val) {
      const attribute = startOrEnd === 'start' ? 'startDate' : 'endDate'
      period[attribute] = moment(val).format('YYYYMMDD')
    },
    clearDate(startOrEnd, period) {
      if (startOrEnd === 'start') {
        period.startDate = ''
        period.repeatFlg = 0
      }
      period.endDate = ''
    },
    addPeriod() {
      this.inputPatientVaccinePeriods.forEach(v => (v.repeatFlg = 0))
      this.inputPatientVaccinePeriods.push({
        ...this.basePeriod,
        key: this.key++
      })
    },
    deletePeriod(periodKey) {
      const index = this.inputPatientVaccinePeriods.findIndex(
        v => v.key === periodKey
      )
      if (this.inputPatientVaccinePeriods[index].id) {
        const deletedPeriod = {
          ...this.inputPatientVaccinePeriods[index],
          delFlg: 1
        }
        this.inputPatientVaccinePeriods.splice(index, 1, deletedPeriod)
      } else {
        this.inputPatientVaccinePeriods.splice(index, 1)
      }
    },
    handleCancel() {
      if (this.diffFlg === true) {
        this.announcePopup.title = '確認'
        this.announcePopup.type = 'alert'
        this.announcePopup.buttons = ['キャンセル', '閉じる']
        this.announcePopup.message =
          '入力内容を保存せずに閉じようとしています。\nよろしいですか？'
        this.announcePopup.cancel = () => {
          this.announcePopupFlg = false
        }
        this.announcePopup.decision = () => {
          this.$emit('close')
        }
        this.announcePopupFlg = true
      } else {
        this.$emit('close')
      }
    },
    async handleDecision() {
      this.patientVaccine
        ? await this.updatePatientVaccine()
        : await this.createPatientVaccine()
    },
    async createPatientVaccine() {
      const sendVaccine = {
        ...this.inputPatientVaccine,
        alertBeforeFlg: this.inputPatientVaccine.alertBeforeFlg % 2,
        alertBeforeDay: Number(this.inputPatientVaccine.alertBeforeDay)
      }
      const sendVaccinePeriods = this.inputPatientVaccinePeriods
        .filter(v => v.startDate !== '')
        .map(v => {
          return { ...v, vaccineId: this.inputPatientVaccine.vaccineId }
        })
      if (this.apiFlg) {
        // PatientShowContentからの追加
        this.waitFlg = true
        const result = await this.$store.dispatch('patientVaccines/create', {
          patientVaccines: [sendVaccine],
          patientVaccinePeriodGroups: [sendVaccinePeriods]
        })
        if (result === true) {
          this.initialData = _.cloneDeep(this.inputData)
          this.announcePopup.type = 'success'
          this.announcePopup.title = '完了'
          this.announcePopup.buttons = ['閉じる']
          this.announcePopup.message = '登録しました'
          this.announcePopup.close = this.closeAnnouncePopup
        } else {
          this.announcePopup.type = 'failure'
          this.announcePopup.title = '失敗'
          this.announcePopup.buttons = ['閉じる']
          this.announcePopup.message = '登録に失敗しました'
          this.announcePopup.close = this.closeAnnouncePopup
        }
        this.waitFlg = false
        this.announcePopupFlg = true
      } else {
        // PatientNew、PatientEdit.vueからの追加
        const vaccineHistory = this.patientVaccines.find(
          i => i.vaccineId === this.inputPatientVaccine.vaccineId
        )
        this.$emit('add-patient-vaccine', {
          patientVaccine: vaccineHistory
            ? {
                ...sendVaccine,
                id: vaccineHistory.id,
                key: vaccineHistory.key
              }
            : sendVaccine,
          patientVaccinePeriods: sendVaccinePeriods
        })
        this.$emit('close')
      }
    },
    async updatePatientVaccine() {
      const sendVaccine = {
        ...this.inputPatientVaccine,
        alertBeforeFlg: this.inputPatientVaccine.alertBeforeFlg % 2,
        alertBeforeDay: Number(this.inputPatientVaccine.alertBeforeDay)
      }
      const sendVaccinePeriods = this.inputPatientVaccinePeriods
        .filter(v => v.startDate !== '' || (v.startDate === '' && v.id))
        .map(v => {
          return v.startDate !== ''
            ? { ...v, vaccineId: this.inputPatientVaccine.vaccineId }
            : { ...v, vaccineId: this.inputPatientVaccine.vaccineId, delFlg: 1 }
        })
      if (this.apiFlg) {
        // PatientShowContentからの変更
        this.waitFlg = true
        const result = await this.$store.dispatch('patientVaccines/update', {
          patientVaccines: [sendVaccine],
          patientVaccinePeriodGroups: [sendVaccinePeriods]
        })
        if (result === true) {
          this.initialData = _.cloneDeep(this.inputData)
          this.announcePopup.type = 'success'
          this.announcePopup.title = '完了'
          this.announcePopup.buttons = ['閉じる']
          this.announcePopup.message = '編集しました'
          this.announcePopup.close = this.closeAnnouncePopup
        } else {
          this.announcePopup.type = 'failure'
          this.announcePopup.title = '失敗'
          this.announcePopup.buttons = ['閉じる']
          this.announcePopup.close = this.closeAnnouncePopup
          this.announcePopup.message = '編集に失敗しました'
        }
        this.waitFlg = false
        this.announcePopupFlg = true
      } else {
        // PatientNew、PatientEdit.vueからの変更
        this.$emit('update-patient-vaccine', {
          patientVaccine: sendVaccine,
          patientVaccinePeriods: sendVaccinePeriods
        })
        this.$emit('close')
      }
    },
    async handleDelete() {
      if (this.apiFlg) {
        // PatientShowContentからの削除
        await this.openDeletePopup()
      } else {
        // PatientNew、PatientEdit.vueからの削除
        const sendVaccine = {
          ...this.inputPatientVaccine,
          alertBeforeFlg: this.inputPatientVaccine.alertBeforeFlg % 2,
          delFlg: 1
        }
        const sendVaccinePeriods = this.inputPatientVaccinePeriods
          .filter(v => v.startDate !== '')
          .map(v => {
            return {
              ...v,
              vaccineId: this.inputPatientVaccine.vaccineId,
              delFlg: 1
            }
          })
        this.$emit('update-patient-vaccine', {
          patientVaccine: sendVaccine,
          patientVaccinePeriods: sendVaccinePeriods
        })
        this.$emit('close')
      }
    },
    openDeletePopup() {
      this.announcePopup.type = 'alert'
      this.announcePopup.title = '注意'
      this.announcePopup.buttons = ['削除しない', '削除する']
      this.announcePopup.message = '削除してもよろしいですか？'
      this.announcePopup.cancel = () => {
        this.announcePopupFlg = false
      }
      this.announcePopup.decision = this.deletePatientVaccine
      this.announcePopupFlg = true
    },
    async deletePatientVaccine() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'patientVaccines/delete',
        this.inputPatientVaccine.id
      )
      if (result === true) {
        this.initialData = _.cloneDeep(this.inputData)
        this.announcePopup.type = 'success'
        this.announcePopup.title = '完了'
        this.announcePopup.buttons = ['閉じる']
        this.announcePopup.message = '削除しました'
        this.announcePopup.close = this.closeAnnouncePopup
      } else {
        this.announcePopup.type = 'failure'
        this.announcePopup.title = '失敗'
        this.announcePopup.buttons = ['閉じる']
        this.announcePopup.message = '削除に失敗しました'
        this.announcePopup.close = this.closeAnnouncePopup
        if (result === 'no data in clinic') this.noDatFlg = true
      }
      this.waitFlg = false
      this.announcePopupFlg = true
    },
    closeAnnouncePopup() {
      this.announcePopupFlg = false
      if (this.announcePopup.type === 'success' || this.noDatFlg) {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vaccine-popup {
  position: absolute;
  top: 0;
  left: 0;
  .content {
    overflow: hidden;
    min-width: 700px;
    text-align: left;
    > .header {
      display: flex;
      align-items: center;
      > .title {
        font-size: 16px;
        font-weight: bold;
      }
      > .delete {
        margin-left: auto;
      }
    }
    > .vaccine {
      margin-top: 20px;
      padding: 0 15px;
      display: flex;
      align-items: center;
      font-size: 15px;
      > .label {
        width: 80px;
      }
    }
    > .vaccine-periods {
      font-size: 13px;
      margin-top: 20px;
      border: solid 1px #{$light-grey};
      padding: 20px 15px;
      max-height: 200px;
      overflow-y: auto;
      > .vaccine-period-table {
        > .row {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          width: 100%;
          height: 31px;
          > .dates-area {
            display: flex;
            align-items: center;
            width: 461px;
            > .label {
              width: 80px;
            }
            > .wavy-line {
              margin: 0 10px;
            }
          }
          > .repeat {
            margin-left: 20px;
          }
          > .button-trash {
            margin-left: auto;
          }
          > .error {
            position: absolute;
            left: 130px;
            margin-top: 50px;
            height: 18px;
            line-height: 18px;
            color: #{$tomato};
            font-size: 12px;
          }
        }
      }
    }
    > .reminder {
      margin-top: 20px;
      padding: 0 15px;
      display: flex;
      align-items: center;
      font-size: 13px;
      width: 100%;
      height: 33px;
      .reminder-setting {
        display: flex;
        align-items: center;
        margin-left: 20px;
        > .text-box,
        .select-box {
          margin: 0 10px;
        }
      }
      .error {
        position: absolute;
        left: 300px;
        height: 18px;
        line-height: 18px;
        color: #{$tomato};
        font-size: 12px;
      }
    }
  }
}
</style>
