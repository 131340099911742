<template>
  <div class="patient-edit">
    <validation-observer v-slot="{ invalid }" class="observer">
      <div class="patient-wrapper">
        <div class="setting-title">患者基本情報編集</div>
        <div class="content-title">基本項目</div>
        <div class="content">
          <base-image-uploader
            class="img"
            :image="inputPatient.image"
            @input="inputPatientImage"
            @delete="deletePatientImage"
            @uploadingImg="setUploadingImg"
          />
          <div class="input-form">
            <patient-input-form
              :patient="inputPatient"
              :anicomPatient="inputAnicomPatient"
              :ipetPatientNative="inputIpetPatientNative"
              :ipetPatientDocomo="inputIpetPatientDocomo"
              :reservations="futureReservations"
              :patientVaccines="displayPatientVaccines"
              :patientVaccinePeriods="displayPatientVaccinePeriods"
              @input="inputProperty"
              @input-anicom="inputAnicom"
              @input-ipet="inputIpet"
              @input-docomo="inputDocomo"
              @renew-period="renewPeriod"
              @clear-date="clearDate"
              @click="createPatientShowId"
              @open-new-vaccine-popup="vaccinePopupFlg = true"
              @open-edit-vaccine-popup="openEditVaccinePopup"
            />
          </div>
        </div>
      </div>
      <div class="button-area">
        <base-button-medium-white class="button" @click="backPatientsListPage"
          >戻る</base-button-medium-white
        >
        <base-button-medium-orange
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="editData"
          :disabled="!isValidBirthday || invalid || waitFlg || uploadingImg"
          >登録</base-button-medium-orange
        >
      </div>
      <vaccine-popup
        v-if="vaccinePopupFlg"
        :patientId="id"
        :apiFlg="false"
        :patientVaccine="editPatientVaccine"
        :patientVaccines="inputPatientVaccines"
        :patientVaccinePeriods="editPatientVaccinePeriods"
        @add-patient-vaccine="addPatientVaccine"
        @update-patient-vaccine="updatePatientVaccine"
        @close="closeVaccinePopup"
      />
      <announce-popup
        v-if="popupFlg"
        :type="popup.type"
        :title="popup.title"
        :buttons="popup.buttons"
        @close="closePopup"
        @cancel="popup.cancel"
        @decision="popup.decision"
        >{{ popup.message }}</announce-popup
      >
    </validation-observer>
    <unsaved-leave-popup :layerNumber="2" />
  </div>
</template>
<script>
import BaseImageUploader from '@/components/parts/atoms/BaseImageUploader'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import PatientInputForm from '@/components/parts/organisms/PatientInputForm'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import AllocateShowId from '@/components/mixins/AllocateShowId'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import VaccinePopup from '@/components/popups/VaccinePopup'
import { ValidationObserver } from 'vee-validate'
import moment from 'moment'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { VALID_DATE_REGEX } from '@/utils/define'

const baseAnicomPatient = { cId: '', planName: '', startDate: '', endDate: '' }
const baseIpetPatient = {
  planNm: '',
  claimRate: 0,
  policyNo: '',
  startDate: '',
  endDate: '',
  firstContractDate: ''
}

export default {
  name: 'PatientEdit',
  components: {
    BaseImageUploader,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    PatientInputForm,
    AnnouncePopup,
    UnsavedLeavePopup,
    VaccinePopup,
    ValidationObserver
  },

  mixins: [AllocateShowId, CheckInputDifference],

  props: {
    ownerId: { type: Number },
    id: { type: Number }
  },

  data() {
    return {
      inputPatient: {},
      inputAnicomPatient: { ...baseAnicomPatient },
      inputIpetPatientNative: { ...baseIpetPatient },
      inputIpetPatientDocomo: { ...baseIpetPatient },
      patientReservations: [],
      waitFlg: false,
      popupFlg: false,
      popup: {
        type: '',
        title: '',
        message: '',
        buttons: ['閉じる'],
        cancel: () => {},
        decision: () => {}
      },
      noDataFlg: false,
      vaccinePopupFlg: false,
      editPatientVaccine: undefined,
      editPatientVaccinePeriods: [],
      inputPatientVaccines: [],
      patientVaccineKey: 0,
      inputPatientVaccinePeriodGroups: [],
      uploadingImg: false
    }
  },

  computed: {
    ...mapGetters({
      karteFlg: 'auth/karteFlg',
      lookOnlyFlg: 'auth/lookOnlyFlg',
      getMasterDatum: 'master/getDataById',
      patientVaccinePeriodsByPatientId:
        'patientVaccinePeriods/getDataByPatientId',
      patientVaccinesByPatientId: 'patientVaccines/getDataByPatientId'
    }),
    futureReservations() {
      const futureActiveReservations = []
      const now = new Date()
      const today = moment(now).format('YYYYMMDD')
      const currentTime = moment(now).format('HHmm')
      this.patientReservations
        .filter(v => v.cancelFlg === 0)
        .forEach(v => {
          if (
            v.date > today ||
            (v.date === today && v.startTime > currentTime)
          ) {
            futureActiveReservations.push(v)
          }
        })
      return futureActiveReservations
    },
    displayPatientVaccines() {
      return this.inputPatientVaccines.filter(v => v.delFlg !== 1)
    },
    displayPatientVaccinePeriods() {
      return this.inputPatientVaccinePeriodGroups
        .map(group => group.filter(v => v.delFlg !== 1))
        .flat()
    },
    isValidBirthday() {
      const { insurance, birthday } = this.inputPatient
      return insurance ? VALID_DATE_REGEX.test(birthday) : true
    },
    insuranceDetail() {
      const inputKey = this.getCurrentInputKey()
      return this[inputKey] ?? {}
    },
    filteredInputPatientVaccines() {
      return this.inputPatientVaccines.flatMap(
        ({ clinicId, createdAt, updatedAt, delFlg, ...vaccine }) => ({
          ...vaccine,
          delFlg: delFlg || 0
        })
      )
    },
    filteredInputPatientVaccinePeriods() {
      return this.inputPatientVaccinePeriodGroups
        .flat()
        .flatMap(({ key, ...item }) => item)
    }
  },

  watch: {
    'inputPatient.deathFlg': function(deathFlg) {
      if (deathFlg === false) this.inputPatient.deathDate = ''
    },
    insuranceDetail(newVal) {
      this.mixinInputData.insuranceDetail = newVal
    },
    filteredInputPatientVaccines(newVal) {
      this.mixinInputData.patientVaccines = newVal
    },
    filteredInputPatientVaccinePeriods(newVal) {
      this.mixinInputData.patientVaccinePeriods = newVal
    }
  },

  async created() {
    this.inputPatient.mcDate = ''
    // 対象患者データの取得状況の確認 -> 未取得であれば取得する
    const acquiredPatientIds = this.$store.getters[
      'owners/getAcquiredPatientIds'
    ]
    const isAcquiredPatient = acquiredPatientIds.has(this.id)
    const result = isAcquiredPatient
      ? true
      : await this.$store.dispatch('patients/get', {
          id: this.id,
          ownerId: this.ownerId
        })
    if (!result) {
      this.popup.type = 'failure'
      this.popup.title = '失敗'
      this.popup.message = '患者データの取得時に通信エラーが発生しました'
      this.popup.buttons = ['閉じる']
      this.popupFlg = true
    }

    // 患者の予約データを設定
    this.patientReservations = this.$store.getters[
      'reservations/getPatientReservations'
    ](this.id)

    // 患者の入力データの設定
    this.inputPatient = _.cloneDeep(
      this.$store.getters['patients/getDataById'](this.id)
    )
    this.inputPatient.deathFlg = this.inputPatient.deathFlg ? true : false
    this.inputPatient.cancelReservations = false

    // 保険の入力データを設定
    const { insurance } = this.inputPatient
    if (insurance === 'anicom') {
      const anicomPatient = this.$store.getters[
        'anicomPatients/getAnicomPatient'
      ](this.id)
      if (anicomPatient) {
        this.inputAnicomPatient = { ...anicomPatient }
      }
    } else if (insurance === 'ipet' || insurance === 'docomo') {
      const ipetPatient = this.$store.getters['ipetPatients/getIpetPatient'](
        this.id
      )
      if (ipetPatient) {
        const inputKeys = {
          ipet: 'inputIpetPatientNative',
          docomo: 'inputIpetPatientDocomo'
        }
        this[inputKeys[insurance]] = { ...ipetPatient }
      }
    }

    // 予防接種の入力データを設定
    this.inputPatientVaccines = this.patientVaccinesByPatientId(this.id)
      ? this.patientVaccinesByPatientId(this.id).map(v => {
          return { ...v, key: this.patientVaccineKey++ }
        })
      : []
    this.inputPatientVaccinePeriodGroups = Object.values(
      _.groupBy(this.patientVaccinePeriodsByPatientId(this.id), 'vaccineId')
    )

    this.mixinInputData = {
      patient: this.inputPatient,
      insuranceDetail: this.insuranceDetail,
      patientVaccines: this.filteredInputPatientVaccines,
      patientVaccinePeriods: this.filteredInputPatientVaccinePeriods
    }
    this.mixinSetInitialData()
  },

  beforeDestroy() {
    if (this.$route.name !== 'owner') {
      this.$store.dispatch('timeTable/clearReservationId')
    }
  },

  methods: {
    backPatientsListPage() {
      this.$router.push({ path: `/main/owners/${this.ownerId}` })
    },
    createPatientShowId() {
      this.mixinAllocatePatientShowId(this.ownerId, this.inputPatient)
    },
    inputPatientImage(image) {
      this.inputPatient.image = image
    },
    deletePatientImage() {
      this.inputPatient.image = null
    },
    inputProperty(val, key) {
      const { insurance } = this.inputPatient
      if (key === 'insurance' && insurance && insurance !== val) {
        const oldInsurance = insurance
        const newInsurance = val
        this.inputPatient.insurance = newInsurance // 一度変更しないと元にもどせない
        this.inputPatient.insurance = oldInsurance // 選択前の値に戻す
        this.popup.type = 'alert'
        this.popup.title = '注意'
        this.popup.message =
          '変更前の保険は利用できなくなりますがよろしいですか？'
        this.popup.buttons = ['キャンセル', 'OK']
        this.popup.cancel = () => {
          this.popupFlg = false
        }
        this.popup.decision = () => {
          this.inputPatient.insurance = newInsurance
          this.popupFlg = false
        }
        this.popupFlg = true
      } else {
        this.inputPatient[key] = val
      }
    },
    inputAnicom({ key, val }) {
      this.inputAnicomPatient[key] = val
    },
    inputIpet({ key, val }) {
      this.inputIpetPatientNative[key] = val
    },
    inputDocomo({ key, val }) {
      this.inputIpetPatientDocomo[key] = val
    },
    renewPeriod() {
      const inputKey = this.getCurrentInputKey()
      if (this[inputKey]) {
        const addYear = YYYYMMDD =>
          moment(YYYYMMDD, 'YYYYMMDD')
            .add(1, 'year')
            .format('YYYYMMDD')
        this[inputKey].startDate = addYear(this[inputKey].startDate)
        this[inputKey].endDate = addYear(this[inputKey].endDate)
      }
    },
    clearDate() {
      const inputKey = this.getCurrentInputKey()
      const dateKey = 'firstContractDate'
      if (this[inputKey]?.hasOwnProperty(dateKey)) {
        this[inputKey][dateKey] = ''
      }
    },
    getCurrentInputKey() {
      const { insurance } = this.inputPatient
      const inputKeys = {
        anicom: 'inputAnicomPatient',
        ipet: 'inputIpetPatientNative',
        docomo: 'inputIpetPatientDocomo'
      }
      const key = inputKeys[insurance] ?? ''
      return key
    },
    async editData() {
      const sortedVaccines = [...this.inputPatientVaccines].sort(
        (a, b) => a.vaccineId - b.vaccineId
      )
      const vaccineIdOrder = sortedVaccines.map(v => v.vaccineId)
      let sortedVaccinePeriods = [...this.inputPatientVaccinePeriodGroups]
        .filter(period => period.length)
        .sort((a, b) => a[0]?.vaccineId - b[0]?.vaccineId)
      const formattedVaccinePeriods = vaccineIdOrder.map(
        id =>
          sortedVaccinePeriods.find(period => period[0].vaccineId === id) || []
      )
      this.waitFlg = true
      let sendPatient = _.cloneDeep(this.inputPatient)
      delete sendPatient.cancelReservations
      sendPatient.deathFlg = sendPatient.deathFlg ? 1 : 0
      const result = this.inputPatient.cancelReservations
        ? await this.$store.dispatch('patients/update', {
            patient: sendPatient,
            anicomPatient: this.getInputByCategory('anicom'),
            ipetPatient: this.getInputByCategory('ipet'),
            patientVaccines: sortedVaccines,
            patientVaccinePeriodGroups: formattedVaccinePeriods,
            reservations: this.futureReservations.map(v => {
              return {
                ...v,
                cancelFlg: 1,
                cancelDatetime: moment().format('YYYYMMDDHHmmss')
              }
            })
          })
        : await this.$store.dispatch('patients/update', {
            patient: sendPatient,
            anicomPatient: this.getInputByCategory('anicom'),
            ipetPatient: this.getInputByCategory('ipet'),
            patientVaccines: sortedVaccines,
            patientVaccinePeriodGroups: formattedVaccinePeriods
          })
      if (result === true) {
        this.popup.type = 'success'
        this.popup.title = '完了'
        this.popup.message = '編集しました'
        this.mixinSetInitialData()
      } else {
        this.popup.type = 'failure'
        this.popup.title = '失敗'
        if (result === 'used showId') {
          this.popup.message =
            `${'編集に失敗しました。患者IDは同じIDがすでに存在します。'}\n` +
            `${'自動ID割振り機能を使用して編集に失敗した場合は、他のユーザーが同じIDで先に編集していた可能性がございます。'}\n` +
            `${'再度、自動ID割振りボタンをクリックしご確認をお願いします。'}\n`
        } else if (result === 'used mcCode') {
          this.popup.message = `編集に失敗しました。\n同じMC識別番号がすでに存在します。`
        } else if (result === 'no patient' || result === 'no data in clinic') {
          this.popup.message =
            '編集に失敗しました。\n編集中の患者は既に削除されています。'
          this.noDataFlg = true
        } else if (
          result === 'patient death date before last medicalRecord date'
        ) {
          this.popup.message =
            '編集に失敗しました。\n死亡年月日は最後のカルテの日付以降にして下さい。'
        } else {
          this.popup.message = '編集に失敗しました'
        }
      }
      this.popup.buttons = ['閉じる']
      this.popupFlg = true
      this.waitFlg = false
    },
    getInputByCategory(category) {
      const { insurance } = this.inputPatient
      const categories = { anicom: 'anicom', ipet: 'ipet', docomo: 'ipet' }
      const currentCategory = categories[insurance]
      const inputKey =
        category === currentCategory ? this.getCurrentInputKey() : ''
      const input = this[inputKey] ?? null
      return input
    },
    closePopup() {
      this.popupFlg = false
      if (this.popup.type === 'success' || this.noDataFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.$router.push({ path: `/main/owners/${this.ownerId}` })
      }
    },
    openEditVaccinePopup(patientVaccine) {
      this.editPatientVaccine = patientVaccine
      this.editPatientVaccinePeriods =
        this.inputPatientVaccinePeriodGroups.find(
          v => v.length && v[0].vaccineId === patientVaccine.vaccineId
        ) || []
      this.vaccinePopupFlg = true
    },
    calculateVaccineIndex(key) {
      return this.inputPatientVaccines.findIndex(v => v.key === key)
    },
    calculatePeriodIndex(vaccineId) {
      return this.inputPatientVaccinePeriodGroups
        .map((arr, i) => {
          if (arr.length) {
            if (arr[0].vaccineId === vaccineId) {
              return i
            }
          }
          return -1
        })
        .find(val => val !== -1)
    },
    addPatientVaccine({ patientVaccine, patientVaccinePeriods }) {
      if (!patientVaccine.id) {
        const patientVaccineWithKey = {
          ...patientVaccine,
          key: this.patientVaccineKey++
        }
        this.inputPatientVaccines.push(patientVaccineWithKey)
        if (patientVaccinePeriods.length)
          this.inputPatientVaccinePeriodGroups.push(patientVaccinePeriods)
      } else {
        const vaccineIndex = this.calculateVaccineIndex(patientVaccine.key)
        this.inputPatientVaccines.splice(vaccineIndex, 1, patientVaccine)
        const periodIndex = this.calculatePeriodIndex(patientVaccine.vaccineId)
        const registeredDeletedPeriods =
          this.inputPatientVaccinePeriodGroups[periodIndex]?.filter(
            period => period.delFlg === 1
          ) || []
        if (patientVaccinePeriods.length || registeredDeletedPeriods.length) {
          this.inputPatientVaccinePeriodGroups.splice(
            periodIndex ?? this.inputPatientVaccinePeriodGroups.length,
            1,
            [...registeredDeletedPeriods, ...patientVaccinePeriods]
          )
        }
      }
    },
    updatePatientVaccine({ patientVaccine, patientVaccinePeriods }) {
      const vaccineIndex = this.calculateVaccineIndex(patientVaccine.key)
      if (patientVaccine.delFlg && !patientVaccine.id) {
        this.inputPatientVaccines.splice(vaccineIndex, 1)
      } else {
        this.inputPatientVaccines.splice(vaccineIndex, 1, patientVaccine)
      }
      const periodIndex = this.calculatePeriodIndex(patientVaccine.vaccineId)
      if (
        patientVaccine.delFlg &&
        !patientVaccine.id &&
        patientVaccinePeriods.length
      ) {
        // データベースに登録してない予防接種を削除時
        this.inputPatientVaccinePeriodGroups.splice(periodIndex, 1)
      } else if (
        patientVaccine.delFlg &&
        patientVaccine.id &&
        periodIndex >= 0
      ) {
        // データベースに登録してある予防接種を削除時、
        const registeredVaccinePeriods = patientVaccinePeriods.filter(
          period => period.id
        )
        if (registeredVaccinePeriods.length) {
          // データベースに登録してある接種期間も削除
          this.inputPatientVaccinePeriodGroups.splice(
            periodIndex,
            1,
            registeredVaccinePeriods
          )
        } else {
          // データベースに登録してない接種期間データのみ
          this.inputPatientVaccinePeriodGroups.splice(periodIndex, 1)
        }
      } else if (patientVaccinePeriods.length) {
        // 接種期間アップデート、追加、削除
        this.inputPatientVaccinePeriodGroups.splice(
          periodIndex ?? this.inputPatientVaccinePeriodGroups.length,
          1,
          patientVaccinePeriods
        )
      } else if (periodIndex >= 0) {
        // データベースに登録してない接種期間を削除、残りの接種期間なし
        this.inputPatientVaccinePeriodGroups.splice(periodIndex, 1)
      }
    },
    closeVaccinePopup() {
      this.vaccinePopupFlg = false
      this.editPatientVaccine = undefined
      this.editPatientVaccinePeriods = []
    },
    setUploadingImg(uploadingImgState) {
      this.uploadingImg = uploadingImgState
    }
  }
}
</script>
<style lang="scss" scoped>
.patient-edit {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-sizing: border-box;
  width: 100%;
  padding: 25px 48px;
  text-align: left;
  > .observer {
    > .patient-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 880px;
      > .setting-title {
        font-size: 20px;
        font-weight: bold;
        border-bottom: 1px solid #{$light-grey};
        padding-bottom: 20px;
      }
      > .content-title {
        font-size: 15px;
        font-weight: bold;
        border-bottom: 1px solid #{$light-grey};
        padding-bottom: 20px;
        margin-top: 22px;
      }
      > .content {
        margin-top: 26px;
        display: flex;
        > .input-form {
          margin-left: 30px;
        }
      }
    }
    > .button-area {
      display: flex;
      justify-content: center;
      margin-top: 52px;
      > .button {
        margin: 0 16px;
      }
    }
  }
}
</style>
