<template>
  <div class="owner-new">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="wrap">
        <div class="title-wrap">
          <div class="title">飼主基本情報登録</div>
        </div>
        <div class="contents-wrap">
          <div class="form-area">
            <div class="heading">
              <div class="text">基本項目</div>
              <base-button-small-white
                v-if="reservationFlg"
                @click="backReservationPopup(true)"
                >予約へ戻る</base-button-small-white
              >
            </div>
            <div class="form">
              <owner-input-form
                :owner="newOwner"
                :apiResultPostalCode="mixinApiResultPostalCode"
                @auto-input-show-id="mixinAllocateOwnerShowId(newOwner)"
                @input-tags="inputTags"
                @auto-input-address="mixinInputAddress('newOwner')"
                @input="inputProperty"
              />
            </div>
          </div>
          <div class="button-area">
            <base-button-medium-white
              class="button"
              @click="$router.push({ path: `/main/owners` })"
              >戻る</base-button-medium-white
            >
            <base-button-medium-orange
              class="button"
              v-if="lookOnlyFlg === 0"
              :disabled="invalid || !birthdayValidFlg || waitFlg"
              @click="createData"
              >登録</base-button-medium-orange
            >
          </div>
        </div>
      </div>
      <announce-popup
        v-if="emailConfirmationPopup.show"
        v-bind="emailConfirmationPopup"
        @cancel="closeEmailConfirmationPopup(false)"
        @decision="closeEmailConfirmationPopup(true)"
        >{{ emailConfirmationPopup.content }}</announce-popup
      >
      <announce-popup
        v-if="alertFlg"
        :title="title"
        :buttons="buttons"
        :type="type"
        @close="closeAlert"
        @cancel="backReservationPopup"
        @decision="pushToPatientNew"
        >{{ popupMessage }}</announce-popup
      >
      <unsaved-leave-popup />
    </validation-observer>
  </div>
</template>

<script>
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import OwnerInputForm from '@/components/parts/organisms/OwnerInputForm'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import AllocateShowId from '@/components/mixins/AllocateShowId'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import AutoInputPrefecture from '@/components/mixins/AutoInputPrefecture'
import { ValidationObserver } from 'vee-validate'
import { VALID_DATE_EMPTY_REGEX } from '@/utils/define'
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'OwnerNew',

  components: {
    BaseButtonSmallWhite,
    OwnerInputForm,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup,
    ValidationObserver
  },

  mixins: [AllocateShowId, CheckInputDifference, AutoInputPrefecture],

  data() {
    return {
      newOwner: {
        showId: '',
        lastName: '',
        firstName: '',
        lastNameKana: '',
        firstNameKana: '',
        sex: 0,
        birthday: '',
        postalCode: '',
        prefectureId: 0,
        address: '',
        building: '',
        tel: '',
        email: '',
        contactSmsFlg: 0,
        note: '',
        ownerTagIds: [],
        englishFlg: 0
      },
      waitFlg: false,
      alertFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: ['閉じる'],
      createOwnerId: 0,
      reservationFlg: false,
      emailConfirmationPopup: {
        show: false,
        layerNumber: 2,
        type: 'alert',
        title: 'メール確認',
        content: '予約を変更しました。 飼主様に確認メールを送信しますか？',
        buttons: ['いいえ', 'はい']
      },
      emailConfirmationResolve: null,
      mailFlgs: {
        new: true,
        change: false,
        cancel: false
      }
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      getMasterDatum: 'master/getDataById',
      reservationSetting: 'reservationSetting/getData'
    }),
    birthdayValidFlg() {
      const birthday = this.newOwner.birthday
      return VALID_DATE_EMPTY_REGEX.test(birthday) ? true : false
    }
  },

  created() {
    const paramsReservation = this.$route.params.reservation
    if (paramsReservation) {
      this.newOwner.lastName = paramsReservation.ownerLastName
      this.newOwner.firstName = paramsReservation.ownerFirstName
      this.newOwner.lastNameKana = paramsReservation.ownerLastNameKana
      this.newOwner.firstNameKana = paramsReservation.ownerFirstNameKana
      this.reservationFlg = true
    }
    this.mixinAllocateOwnerShowId(this.newOwner)
    this.mixinInputData = this.newOwner
    this.mixinSetInitialData()
  },

  methods: {
    inputTags(ownerTagIds) {
      this.newOwner.ownerTagIds = ownerTagIds
    },
    inputProperty(val, property) {
      if (
        property === 'postalCode' &&
        !this.mixinApiResultPostalCode?.existingPostalCodeFlg &&
        val.length !== 7
      ) {
        this.mixinApiResultPostalCode = null
      }
      this.newOwner[property] = val
    },
    async createData() {
      this.waitFlg = true
      const sendOwner = _.cloneDeep(this.newOwner)
      sendOwner.ownerTagIds = sendOwner.ownerTagIds.join(',')
      if (this.reservationSetting.reservationMailAuto === 'off')
        this.mailFlgs.new = false
      else if (
        this.reservationFlg &&
        this.reservationSetting.reservationMailAuto === 'check' &&
        this.newOwner.email
      ) {
        const { date, startTime } = this.$route.params.reservation
        const upcomingReservation =
          date + startTime >= moment().format('YYYYMMDDHHmm')
        if (upcomingReservation) await this.openEmailConfirmationPopup()
      }
      const res = this.reservationFlg
        ? await this.$store.dispatch('owners/create', {
            owner: sendOwner,
            reservation: this.$route.params.reservation,
            mailFlgs: this.mailFlgs
          })
        : await this.$store.dispatch('owners/create', { owner: sendOwner })
      if (res.result === true) {
        this.createOwnerId = res.ownerId
        this.type = 'success'
        this.title = '完了'
        this.mixinSetInitialData()
        if (this.reservationFlg) {
          this.buttons = ['予約作成に戻る', '患者登録を行う']
        }
        this.popupMessage = this.reservationFlg
          ? `飼主の登録と予約の変更をしました。続けて患者登録を行いますか？\n※患者登録をしない場合、患者名を設定する事ができません`
          : '新規登録しました'
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.popupMessage =
          res === 'used showId'
            ? '飼主IDは同じIDがすでに存在します'
            : res === 'used email'
            ? '入力したメールアドレスは既に使用されています'
            : res === 'no reservationColumn'
            ? '他のユーザーが予約列データを削除した為、予約の変更と飼主の登録に失敗しました'
            : res === 'no reservationPurpose'
            ? '他のユーザーが来院目的データを削除した為、予約の変更と飼主の登録に失敗しました'
            : res === 'no staff'
            ? '他のユーザーがスタッフデータを削除した為、予約の変更と飼主の登録に失敗しました'
            : res === 'no reservation' || res === 'no data in clinic'
            ? '編集中の予約が削除またはキャンセルされている為、\n予約の変更と飼主の登録に失敗しました'
            : this.$route.params.reservation
            ? '飼主の登録と予約の変更に失敗しました'
            : '登録に失敗しました'
        if (res === 'no reservation' || res === 'no data in clinic') {
          this.reservationFlg = false
          this.$store.dispatch('timeTable/clearReservationId')
        }
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    async openEmailConfirmationPopup() {
      this.emailConfirmationPopup.show = true
      await new Promise(resolve => (this.emailConfirmationResolve = resolve))
    },
    closeEmailConfirmationPopup(flg) {
      this.mailFlgs.new = flg
      this.emailConfirmationPopup.show = false
      this.emailConfirmationResolve()
      this.emailConfirmationResolve = null
    },
    closeAlert() {
      this.alertFlg = false
      if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.$router.push({ path: `/main/owners/${this.createOwnerId}` })
      }
    },
    backReservationPopup(checkFlg = false) {
      if (!checkFlg) this.$store.dispatch('petorelu/okLeave')
      this.$store.dispatch('timeTable/showPopup')
      const initialReservation = this.$store.getters[
        'reservations/getDataById'
      ](this.$route.params.reservation.id)
      this.$store.dispatch('timeTable/setInitialReservation', {
        ...initialReservation
      })
      this.$store.dispatch(
        'timeTable/setMoveEndTimeOfReservation',
        this.$route.params.moveEndTimeObj
      )
      this.$router.push({
        name: 'main',
        params: { scrollInfo: this.$route.params.scrollInfo }
      })
    },
    pushToPatientNew() {
      this.$store.dispatch('petorelu/okLeave')
      this.$router.push({
        name: 'patient-new',
        params: {
          ownerId: this.createOwnerId,
          reservation: this.$route.params.reservation,
          moveEndTimeObj: this.$route.params.moveEndTimeObj,
          scrollInfo: this.$route.params.scrollInfo
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.owner-new {
  width: 100%;
  text-align: left;
  overflow: auto;
  > .validation-observer {
    > .wrap {
      min-width: 710px;
      padding: 24px 48px;
      box-sizing: border-box;
      > .title-wrap {
        > .title {
          padding-bottom: 20px;
          font-size: 20px;
          font-weight: bold;
          border-bottom: 1px solid #{$light-grey};
        }
      }
      > .contents-wrap {
        > .form-area {
          > .heading {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 62px;
            border-bottom: 1px solid #{$light-grey};
            > .text {
              font-size: 15px;
              font-weight: bold;
            }
          }
          > .form {
            margin-top: 25px;
          }
        }
        > .button-area {
          display: flex;
          justify-content: center;
          margin: 60px 0;
          > .button {
            margin: 0 16px;
          }
        }
      }
    }
  }
}
</style>
