<template>
  <div class="owner-edit">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="wrap">
        <div class="title-wrap">
          <div class="title">飼主基本情報編集</div>
        </div>
        <div class="contents-wrap">
          <div class="form-area">
            <div class="heading">基本項目</div>
            <div class="form">
              <owner-input-form
                :owner="editOwner"
                :updatePatientShowId="updatePatientShowId"
                :apiResultPostalCode="mixinApiResultPostalCode"
                @auto-input-show-id="mixinAllocateOwnerShowId(editOwner)"
                @update-patients-show-id="handleUpdatePatients"
                @input-tags="inputTags"
                @auto-input-address="mixinInputAddress('editOwner')"
                @input="inputProperty"
              />
            </div>
          </div>
          <div class="button-area">
            <base-button-medium-white
              class="button"
              @click="$router.push({ path: `/main/owners/${id}` })"
              >戻る</base-button-medium-white
            >
            <base-button-medium-orange
              class="button"
              v-if="lookOnlyFlg === 0"
              :disabled="invalid || !birthdayValidFlg || waitFlg"
              @click="updateData"
              >登録</base-button-medium-orange
            >
          </div>
        </div>
      </div>
      <announce-popup
        v-if="alertFlg"
        :title="title"
        :buttons="buttons"
        :type="type"
        @close="closeAlert"
        >{{ popupMessage }}</announce-popup
      >
      <unsaved-leave-popup />
    </validation-observer>
  </div>
</template>

<script>
import OwnerInputForm from '@/components/parts/organisms/OwnerInputForm'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import AllocateShowId from '@/components/mixins/AllocateShowId'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import AutoInputPrefecture from '@/components/mixins/AutoInputPrefecture'
import { ValidationObserver } from 'vee-validate'
import { VALID_DATE_EMPTY_REGEX } from '@/utils/define'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'OwnerEdit',

  components: {
    OwnerInputForm,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    AnnouncePopup,
    UnsavedLeavePopup,
    ValidationObserver
  },

  mixins: [AllocateShowId, CheckInputDifference, AutoInputPrefecture],

  props: {
    id: { type: Number }
  },

  data() {
    return {
      editOwner: {},
      waitFlg: false,
      alertFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: ['閉じる'],
      updatePatientShowId: false,
      noDataFlg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      getMasterDatum: 'master/getDataById'
    }),
    birthdayValidFlg() {
      const birthday = this.editOwner.birthday
      return VALID_DATE_EMPTY_REGEX.test(birthday) ? true : false
    }
  },

  created() {
    this.editOwner = { ...this.$store.getters['owners/getDataById'](this.id) }
    this.mixinInputData = this.editOwner
    this.mixinSetInitialData()
  },

  beforeDestroy() {
    if (this.$route.name !== 'owner') {
      this.$store.dispatch('timeTable/clearReservationId')
    }
  },

  methods: {
    handleUpdatePatients(checked) {
      this.updatePatientShowId = checked
    },
    inputTags(ownerTagIds) {
      this.editOwner.ownerTagIds = ownerTagIds
    },
    inputProperty(val, property) {
      if (
        property === 'postalCode' &&
        !this.mixinApiResultPostalCode?.existingPostalCodeFlg &&
        val.length !== 7
      ) {
        this.mixinApiResultPostalCode = null
      }
      this.editOwner[property] = val
    },
    async updateData() {
      this.waitFlg = true
      const sendOwner = _.cloneDeep(this.editOwner)
      sendOwner.ownerTagIds = sendOwner.ownerTagIds.join(',')
      const result = await this.$store.dispatch('owners/update', {
        owner: sendOwner,
        updatePatients: this.updatePatientShowId
      })
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '編集しました'
        this.mixinSetInitialData()
      } else {
        this.type = 'failure'
        this.title = '失敗'
        if (result === 'used showId') {
          this.popupMessage = '飼主IDは同じIDがすでに存在します'
        } else if (result === 'used email') {
          this.popupMessage = '入力したメールアドレスは既に使用されています'
        } else if (result === 'no owner' || result === 'no data in clinic') {
          this.popupMessage =
            '編集に失敗しました。\n編集中の飼主は既に削除されています。'
          this.noDataFlg = true
        } else {
          this.popupMessage = '編集に失敗しました'
        }
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    closeAlert() {
      this.alertFlg = false
      if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.$router.push({ path: `/main/owners/${this.id}` })
      }
      if (this.noDataFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.$router.push({ path: '/main/owners' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.owner-edit {
  width: 100%;
  text-align: left;
  overflow: auto;
  > .validation-observer {
    > .wrap {
      min-width: 710px;
      padding: 24px 48px;
      box-sizing: border-box;
      > .title-wrap {
        > .title {
          padding-bottom: 20px;
          font-size: 20px;
          font-weight: bold;
          border-bottom: 1px solid #{$light-grey};
        }
      }
      > .contents-wrap {
        margin-top: 20px;
        > .form-area {
          > .heading {
            padding-bottom: 20px;
            font-size: 15px;
            font-weight: bold;
            border-bottom: 1px solid #{$light-grey};
          }
          > .form {
            margin-top: 25px;
          }
        }
        > .button-area {
          display: flex;
          justify-content: center;
          margin: 60px 0;
          > .button {
            margin: 0 16px;
          }
        }
      }
    }
  }
}
</style>
