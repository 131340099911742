<template>
  <div class="owner">
    <div class="wrap">
      <div class="link">
        <router-link :to="{ name: 'owners' }"><base-back-link /></router-link>
      </div>
      <div class="title-wrap">
        <div class="title">飼主・患者詳細</div>
        <div class="buttons-wrap">
          <base-button-small-white
            v-if="reservationId !== 0"
            :styles="{ width: '135px' }"
            @click="openReservationPopup"
            >予約へ戻る</base-button-small-white
          >
          <base-button-register
            v-if="owner && owner.email && lookOnlyFlg === 0"
            :text="'メール作成'"
            :styles="{ width: '135px' }"
            class="make-mail"
            @click="mailPopupFlg = true"
          />
          <base-button-register
            v-if="lookOnlyFlg === 0"
            :text="'予約作成'"
            :styles="{ width: '135px' }"
            class="make-reservation"
            @click="makeOwnerReservation"
          />
        </div>
      </div>
      <div class="contents">
        <div class="owner-area">
          <owner-show-content
            :id="id"
            @open-owner-edit="openOwnerEdit"
            @open-owners="openOwners"
          />
        </div>
        <div class="patients-area">
          <div class="patients-area-top">
            <div class="area-title">患者詳細</div>
            <base-button-register
              v-if="lookOnlyFlg === 0"
              :text="'患者登録'"
              :styles="{ width: '135px' }"
              @click="$router.push({ path: `/main/owners/${id}/patients/new` })"
            />
          </div>
          <div class="patients">
            <div
              v-for="patient in patients"
              class="patient"
              ref="patient"
              :key="patient.id"
              :data-hash="`#patient-${patient.id}`"
            >
              <patient-show-content
                :id="patient.id"
                @open-patient-edit="openPatientEdit"
                @open-reservation="openReservation"
                @open-delete-popup="openDeleteFirstPopup"
                @set-navigation-popup-type="setNavigationPopupType"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <announce-popup
      v-if="alertFlg"
      :type="type"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      @cancel="cancelPatientDelete"
      @close="closePopup"
      @decision="clickDeleteButton"
      >{{ popupMessage }}</announce-popup
    >
    <mail-popup
      v-if="mailPopupFlg"
      :owners="[owner]"
      @close="mailPopupFlg = false"
    />
    <unsaved-leave-popup
      v-bind="{ layerNumber: 2, popupType: navigationPopupType }"
    />
  </div>
</template>

<script>
import BaseBackLink from '@/components/parts/atoms/BaseBackLink'
import OwnerShowContent from '@/components/parts/organisms/OwnerShowContent'
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import PatientShowContent from '@/components/parts/organisms/PatientShowContent'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import moment from 'moment'
import MailPopup from '@/components/popups/MailPopup'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'Owner',

  components: {
    BaseBackLink,
    BaseButtonRegister,
    OwnerShowContent,
    PatientShowContent,
    AnnouncePopup,
    MailPopup,
    BaseButtonSmallWhite,
    UnsavedLeavePopup
  },

  props: {
    id: { type: Number }
  },

  data() {
    return {
      patients: [],
      waitFlg: false,
      alertFlg: false,
      type: '',
      title: '',
      buttons: ['閉じる'],
      popupMessage: '',
      mailPopupFlg: false,
      owner: this.$store.getters['owners/getDataById'](this.id),
      deletePatientId: 0,
      timeoutId: null,
      noDataFlg: false,
      deleteFirstDecisionFlg: false,
      navigationPopupType: 'default'
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      karteFlg: 'auth/karteFlg'
    }),
    reservationId() {
      return this.$store.getters['timeTable/get'].reservationId
    }
  },

  async created() {
    const acquiredPatientIds = this.$store.getters[
      'owners/getAcquiredPatientIds'
    ]
    const patients =
      this.$store.getters['patients/getDataByOwnerId'](this.id) || []
    const patientIds = patients.map(v => v.id)
    const notAcquiredPatientIds = patientIds.filter(
      v => !acquiredPatientIds.has(v)
    )
    const result =
      notAcquiredPatientIds.length > 0
        ? await this.$store.dispatch('owners/get', {
            id: this.id,
            notAcquiredPatientIds
          })
        : true
    this.noDataFlg = !this.owner || this.owner.delFlg === 1
    if (result !== true || this.noDataFlg) {
      this.alertFlg = true
      this.type = 'failure'
      this.title = '失敗'
      this.popupMessage =
        result === 'no owner' || this.noDataFlg
          ? '飼主データが削除されています'
          : '患者データの取得時に通信エラーが発生しました'
      this.buttons = ['閉じる']
    }
    this.setPatients()
    this.$store.watch(
      () => this.$store.getters['patients/getData'],
      () => this.setPatients()
    )
  },

  mounted() {
    const hash = this.$route.hash
    if (hash) {
      this.$nextTick(() => {
        this.timeoutId = setTimeout(() => {
          const targetPatient = this.$refs.patient.find(
            patient => hash === patient.dataset.hash
          )
          if (targetPatient) this.scrollToElement(targetPatient)
        }, 100)
      })
    }
  },

  beforeDestroy() {
    if (
      !(
        this.$route.name === 'owner-edit' ||
        this.$route.name === 'patient-new' ||
        this.$route.name === 'patient-edit'
      )
    ) {
      this.$store.dispatch('timeTable/clearReservationId')
    }
    clearTimeout(this.timeoutId)
  },

  methods: {
    setPatients() {
      this.patients = _.cloneDeep(
        this.$store.getters['patients/getDataByOwnerId'](this.id)
      )
    },
    makeOwnerReservation() {
      const diffMinutes = this.$store.getters['reservationSetting/getData']
        .reservationTimeUnit
      this.$store.dispatch('timeTable/setInitialReservation', {
        id: 0,
        ownerId: this.id,
        startTime:
          moment()
            .add(1, 'h')
            .format('HH') + '00',
        endTime:
          moment()
            .add(1, 'h')
            .format('HH') + `${diffMinutes}`,
        ownerPatientPageFlg: true
      })
      this.$router.push({ name: 'main', params: { fromPage: 'owner' } })
    },
    openOwnerEdit() {
      this.$router.push({ path: `/main/owners/${this.id}/edit` })
    },
    openOwners() {
      this.$router.push({ path: '/main/owners' })
    },
    openPatientEdit(patientId) {
      this.$router.push({
        path: `/main/owners/${this.id}/patients/${patientId}/edit`
      })
    },
    openReservation(id) {
      const reservation = this.$store.getters['reservations/getDataById'](id)
      if (reservation.cancelFlg === 1) {
        this.$router.push({
          path: `/main/notifications/reservation-cancels/${id}`
        })
      } else {
        this.$store.dispatch('timeTable/setInitialReservation', {
          ...reservation
        })
        this.$store.dispatch('timeTable/changeDate', reservation.date)
        this.$store.dispatch('timeTable/showPopup')
        this.$router.push({ path: '/main' })
      }
    },
    openReservationPopup() {
      const initialReservation = this.$store.getters[
        'reservations/getDataById'
      ](this.reservationId)
      this.$store.dispatch('timeTable/setInitialReservation', {
        ...initialReservation
      })
      this.$store.dispatch('timeTable/showPopup')
      this.$router.push({ name: 'main' })
    },
    openDeleteFirstPopup(id) {
      this.alertFlg = true
      this.type = 'alert'
      this.title = '注意'
      this.popupMessage = '削除してもよろしいですか？'
      this.buttons = ['削除しない', '削除する']
      this.deletePatientId = id
    },
    cancelPatientDelete() {
      this.alertFlg = false
      this.deletePatientId = 0
      this.deleteFirstDecisionFlg = false
    },
    clickDeleteButton() {
      if (!this.deleteFirstDecisionFlg) {
        this.popupMessage = this.karteFlg
          ? '本当に削除してもよろしいですか？\n\n※患者の全てのデータ（予約・予約申請・カルテ・会計・予防接種・見積など）\nが削除されます。\n※削除したデータの復旧はできません。'
          : '本当に削除してもよろしいですか？\n\n※患者の全てのデータ（予約・予約申請）が削除されます。\n※削除したデータの復旧はできません。'
        this.buttons = ['削除しない', '削除する']
        this.deleteFirstDecisionFlg = true
      } else {
        this.deletePatient()
      }
    },
    async deletePatient() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'patients/delete',
        this.deletePatientId
      )
      this.buttons = ['閉じる']
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '削除しました'
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.popupMessage =
          result === 'used in reservationRequests'
            ? '今日以降の予約申請で使用されている患者は削除できません'
            : result === 'used in reservations'
            ? '今日以降の予約で使用されている患者は削除できません'
            : '削除に失敗しました'
      }
      this.alertFlg = true
      this.waitFlg = false
      this.deleteFirstDecisionFlg = false
    },
    scrollToElement(element) {
      element.scrollIntoView()
    },
    closePopup() {
      this.alertFlg = false
      if (this.noDataFlg) this.openOwners()
    },
    setNavigationPopupType(type) {
      this.navigationPopupType = type
    }
  }
}
</script>

<style lang="scss" scoped>
.owner {
  width: 100%;
  text-align: left;
  overflow: auto;
  > .wrap {
    min-width: 710px;
    padding: 24px 48px;
    box-sizing: border-box;
    > .link {
      margin-left: -26px;
    }
    > .title-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 33px;
      margin-top: 20px;
      border-bottom: 1px solid #{$light-grey};
      padding-bottom: 20px;
      > .title {
        font-size: 20px;
        font-weight: bold;
      }
      > .buttons-wrap {
        display: flex;
        > .make-mail {
          margin-left: 20px;
        }
        > .make-reservation {
          margin-left: 20px;
        }
      }
    }

    > .contents {
      > .owner-area {
        margin-top: 60px;
      }
      > .patients-area {
        margin-top: 70px;
        > .patients-area-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 33px;
          border-bottom: 1px solid #{$light-grey};
          padding-bottom: 20px;
          > .area-title {
            font-size: 20px;
            font-weight: bold;
          }
        }
        > .patients {
          margin-top: 45px;
          > .patient {
            padding-top: 15px;
            ~ .patient {
              margin-top: 45px;
            }
          }
        }
      }
    }
  }
}
</style>
