<template>
  <div class="search-area-detail" data-test="search-area-detail">
    <validation-observer class="validation-observer" v-slot="{ invalid }">
      <div class="search-area">
        <div class="input-form">
          <div class="header">検索条件</div>
          <div class="search-area-detail-form-wrapper">
            <search-area-detail-form
              v-for="(searchCondition, index) in searchConditions"
              :key="searchCondition.id + 'searchDetail'"
              :index="index"
              :searchCondition="searchCondition"
              :selectBoxData="filteredSelectBoxData(index)"
              @select="select"
              @input-text="inputText"
              @input-text2="inputText2"
              @input-text3="inputText3"
              @clear="clear"
              @trash="trash"
              @select-tag="selectTag"
              @keyup="search(invalid)"
            />
          </div>
        </div>
        <base-button-small-white @click="hideDetail" :styles="buttonWidth"
          >簡易検索</base-button-small-white
        >
      </div>
      <div class="button-area">
        <base-button-plus :text="'検索条件追加'" @click="addSearchCondition" />
        <base-button-small-orange
          v-if="searchButtonFlg"
          :styles="buttonWidth"
          :disabled="waitFlg || invalid"
          @click="search"
          >{{ searchButtonLabel }}</base-button-small-orange
        >
      </div>
    </validation-observer>
  </div>
</template>

<script>
import SearchAreaDetailForm from '@/components/parts/molecules/SearchAreaDetailForm'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import BaseButtonPlus from '@/components/parts/atoms/BaseButtonPlus'
import DetectWindowSize from '@/components/mixins/DetectWindowSize'
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'SearchAreaDetail',

  components: {
    SearchAreaDetailForm,
    BaseButtonSmallWhite,
    BaseButtonSmallOrange,
    BaseButtonPlus,
    ValidationObserver
  },

  mixins: [DetectWindowSize],

  props: {
    selectBoxData: { type: Array },
    searchConditions: { type: Array },
    searchButtonFlg: { type: Boolean, default: false },
    searchButtonLabel: { type: String, default: '検索' },
    waitFlg: { type: Boolean, default: false }
  },

  computed: {
    buttonWidth() {
      return this.mixinWindowWidth < 1024
        ? { width: '70px' }
        : { width: '120px' }
    },
    filteredSelectBoxData() {
      return index => {
        const usedSelectIds = this.searchConditions.flatMap((sc, i) => {
          if (index === i) return []
          return sc.selectId
        })
        return this.selectBoxData.filter(
          datum =>
            !(
              (datum.type === 'radioButton' ||
                datum.type === 'selectBox' ||
                datum.type === 'periodRadioButton' ||
                datum.type === 'tagsSelect' ||
                datum.type === 'textBoxes') &&
              usedSelectIds.includes(datum.id)
            )
        )
      }
    }
  },

  methods: {
    hideDetail() {
      this.$emit('hide-detail')
    },
    select(idIndex) {
      this.$emit('select', idIndex)
    },
    inputText(textObj) {
      this.$emit('input-text', textObj)
    },
    inputText2(textObj) {
      this.$emit('input-text2', textObj)
    },
    inputText3(textObj) {
      this.$emit('input-text3', textObj)
    },
    clear(clearObj) {
      this.$emit('clear', clearObj)
    },
    addSearchCondition() {
      this.$emit('add-search-condition')
    },
    trash(id) {
      this.$emit('trash', id)
    },
    search(invalid) {
      if (!invalid) this.$emit('search')
    },
    selectTag(tagId, index) {
      this.$emit('select-tag', tagId, index)
    }
  }
}
</script>

<style lang="scss" scoped>
.search-area-detail {
  box-sizing: border-box;
  width: 100%;
  min-width: 614px;
  border: solid 1px #{$light-grey};
  background-color: #{$white_f7};
  font-size: 13px;
  padding: 20px;
  > .validation-observer {
    > .search-area {
      display: flex;
      justify-content: space-between;
      > .input-form {
        > .search-area-detail-form-wrapper {
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          gap: 18px 0;
        }
      }
    }
    > .button-area {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
