<template>
  <div class="patient-input-form">
    <div class="row">
      <auto-input-form
        :requiredFlg="true"
        :maxlength="20"
        :placeholder="'20文字まで'"
        :inputStyle="{ width: '150px' }"
        :buttonText="'自動ID割振り'"
        :buttonStyle="{ width: '140px' }"
        :validationRules="{ regexShowId: true, maxRule: 20 }"
        v-model="patient.showId"
        :buttonShowFlg="commonSetting.patientShowIdHelperKind != 0"
        @click="handleClick"
        >患者ID</auto-input-form
      >
    </div>
    <div class="row">
      <text-input-form
        :validationRules="{ numericString: { length: 15 } }"
        :maxlength="15"
        :placeholder="'15文字'"
        :styles="textInputStyles"
        :fullWidthNumbersToHalfWidthNumbers="true"
        v-model="patient.mcCode"
        >MC識別番号</text-input-form
      >
    </div>
    <div class="row">
      <date-input-form
        :date="patient.mcDate"
        :minDate="mcMinDate"
        :maxDate="mcMaxDate"
        :styles="{ width: '600px' }"
        :headingStyle="{ width: '200px', minWidth: '200px' }"
        @input-date="inputMcDate"
        >MC装着日</date-input-form
      >
    </div>
    <div class="row">
      <text-input-form
        :requiredFlg="true"
        :maxlength="30"
        :placeholder="'30文字まで'"
        :styles="textInputStyles"
        v-model="patient.name"
        >患者名</text-input-form
      >
    </div>
    <div class="row">
      <text-input-form
        :validationRules="{ regexKana: true }"
        :maxlength="30"
        :placeholder="'30文字まで'"
        :styles="textInputStyles"
        v-model="inputPatientNameKana"
        >患者名（全角カナ）</text-input-form
      >
    </div>
    <div class="row">
      <select-box-form
        :selectData="patientSexesZero"
        v-model.number="patient.sex"
        >性別</select-box-form
      >
    </div>
    <div class="row">
      <text-input-form
        :maxlength="50"
        :placeholder="'50文字まで'"
        :styles="textInputStyles"
        v-model="patient.hairColor"
        >毛色</text-input-form
      >
    </div>
    <div class="row">
      <select-box-form
        :requiredFlg="true"
        :selectData="selectSpecies"
        v-model.number="patient.speciesId"
        >種別</select-box-form
      >
    </div>
    <div class="row">
      <text-input-form
        :maxlength="50"
        :placeholder="'50文字まで'"
        :styles="textInputStyles"
        v-model="patient.breed"
        >品種</text-input-form
      >
    </div>
    <div class="row">
      <birthday-input-form
        :birthday="patient.birthday"
        :requiredFlg="birthdayRequiredFlg"
        :errorShowFlg="birthdayRequiredFlg"
        @input="inputBirthday"
        >生年月日</birthday-input-form
      >
    </div>
    <div class="row">
      <select-box-form
        :selectData="selectDataOnlyDoctor"
        v-model.number="patient.staffId"
        >主治医</select-box-form
      >
    </div>
    <div class="row insurance">
      <select-box-form
        data-test="insurance"
        :selectData="insuranceList"
        :styles="{ width: '150px' }"
        :value="insuranceToId(patient.insurance)"
        @input="inputInsurance"
        >保険</select-box-form
      >
    </div>
    <div
      v-show="patient.insurance === 'anicom'"
      class="row anicom"
      data-test="row-anicom"
    >
      <div class="label space"></div>
      <validation-observer :disabled="patient.insurance !== 'anicom'">
        <anicom-form
          data-test="anicom"
          :anicomPatient="anicomPatient"
          @input="inputAnicom"
          @renew-period="renewPeriod('anicom')"
        />
      </validation-observer>
    </div>
    <div
      v-show="patient.insurance === 'ipet'"
      class="row ipet"
      data-test="row-ipet"
    >
      <div class="label space"></div>
      <validation-observer :disabled="patient.insurance !== 'ipet'">
        <ipet-form
          data-test="ipet"
          :insurance="patient.insurance"
          :ipetPatient="ipetPatientNative"
          @input="inputIpet"
          @renew-period="renewPeriod('ipet')"
          @clear-date="clearDate"
        />
      </validation-observer>
    </div>
    <div
      v-show="patient.insurance === 'docomo'"
      class="row docomo"
      data-test="row-docomo"
    >
      <div class="label space"></div>
      <validation-observer :disabled="patient.insurance !== 'docomo'">
        <ipet-form
          data-test="docomo"
          :insurance="patient.insurance"
          :ipetPatient="ipetPatientDocomo"
          @input="inputDocomo"
          @renew-period="renewPeriod('docomo')"
          @clear-date="clearDate"
        />
      </validation-observer>
    </div>
    <div class="row vaccinations" v-if="karteFlg === 1">
      <div class="label">
        予防接種<base-button-plus
          class="add-button"
          :styles="{ width: '100px' }"
          :text="'追加'"
          @click="$emit('open-new-vaccine-popup')"
        />
      </div>
      <list-table
        v-if="displayVaccinations.length !== 0"
        class="list"
        :showUpperFrame="false"
        :headerData="vaccinationTable.headers"
        :propertyData="vaccinationTable.properties"
        :bodyData="displayVaccinations"
        :headerItemStyleData="vaccinationTable.itemStyles"
        :bodyItemStyleData="vaccinationTable.itemStyles"
        :directOrderFlg="true"
        @click="openEditVaccinePopup"
      />
    </div>
    <div class="row">
      <multiple-text-input-form
        v-model="patient.note"
        :styles="{ width: '346px' }"
        >備考</multiple-text-input-form
      >
    </div>
    <div class="row death">
      <div class="label">死亡登録</div>
      <div class="field check-box">
        <base-check-box
          :isChecked="patient.deathFlg"
          v-model="patient.deathFlg"
        />
      </div>
    </div>
    <div v-if="patient.deathFlg" class="row death">
      <date-input-form
        :date="patient.deathDate"
        :minDate="deathMinDate"
        :maxDate="deathMaxDate"
        :requiredFlg="true"
        :styles="{ width: '600px' }"
        :headingStyle="{ width: '200px', minWidth: '200px' }"
        @input-date="inputDeathDate"
        >死亡年月日</date-input-form
      >
    </div>
    <div v-if="patient.deathFlg && reservations.length > 0" class="row death">
      <div class="label">予約キャンセルの確認</div>
      <div class="wrapper">
        <div class="checkbox">
          <base-check-box
            :isChecked="patient.cancelReservations"
            :labelText="'以下の予約を全てキャンセルする'"
            v-model="patient.cancelReservations"
          />
        </div>
        <list-table
          class="list"
          :showUpperFrame="false"
          :headerData="reservationHistoryTable.headers"
          :headerItemStyleData="reservationHistoryTable.itemStyles"
          :propertyData="reservationHistoryTable.properties"
          :bodyData="displayReservations"
          :bodyItemStyleData="displayReservations.itemStyles"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AutoInputForm from '@/components/parts/molecules/AutoInputForm'
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import DateInputForm from '@/components/parts/molecules/DateInputForm'
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm'
import BirthdayInputForm from '@/components/parts/molecules/BirthdayInputForm'
import MultipleTextInputForm from '@/components/parts/molecules/MultipleTextInputForm'
import ListTable from '@/components/parts/organisms/ListTable'
import BaseButtonPlus from '@/components/parts/atoms/BaseButtonPlus'
import AnicomForm from '@/components/parts/organisms/AnicomForm'
import IpetForm from '@/components/parts/organisms/IpetForm'
import { hiraToKata } from '@/utils/convert_string'
import {
  computePreviousDate,
  computeNextVaccinePeriod
} from '@/utils/vaccine_utils'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'PatientInputForm',

  components: {
    AutoInputForm,
    BaseCheckBox,
    DateInputForm,
    TextInputForm,
    SelectBoxForm,
    BirthdayInputForm,
    MultipleTextInputForm,
    ListTable,
    BaseButtonPlus,
    AnicomForm,
    IpetForm,
    ValidationObserver
  },

  props: {
    patient: { type: Object },
    anicomPatient: { type: Object },
    ipetPatientNative: { type: Object },
    ipetPatientDocomo: { type: Object },
    reservations: { type: Array, default: () => [] },
    patientVaccines: { type: Array, default: () => [] },
    patientVaccinePeriods: { type: Array, default: () => [] }
  },

  data() {
    return {
      reservationHistoryTable: {
        headers: ['予約日', '予約時間'],
        properties: ['date', 'time'],
        itemStyles: [
          { width: '200px', minWidth: '200px', cursor: 'default' },
          { width: '150px', minWidth: '150px', cursor: 'default' }
        ]
      },
      textInputStyles: { width: '208px' },
      vaccinationTable: {
        headers: ['接種名', '次回の接種日（予定）', 'アラート'],
        properties: ['name', 'nextDate', 'alert'],
        itemStyles: [
          { textAlign: 'center', minWidth: '120px' },
          { textAlign: 'center', minWidth: '270px' },
          { textAlign: 'center', minWidth: '80px' }
        ],
        itemStylesRed: [
          { textAlign: 'center', minWidth: '120px' },
          { textAlign: 'center', minWidth: '270px', color: '#de3d3d' },
          { textAlign: 'center', minWidth: '80px' }
        ]
      },
      insuranceList: [
        { id: 0, name: '未設定', value: '' },
        { id: 1, name: 'アニコム', value: 'anicom' },
        { id: 2, name: 'アイペット', value: 'ipet' },
        { id: 3, name: 'ドコモ', value: 'docomo' }
      ]
    }
  },

  computed: {
    ...mapGetters({
      antibodyTypesByPatientId: 'antibodyTypes/getDataByPatientId',
      commonSetting: 'commonSetting/getData',
      getAntibodyById: 'antibodies/getDataById',
      getMasterDatum: 'master/getDataById',
      karteFlg: 'auth/karteFlg',
      medicalRecords: 'medicalRecords/getData',
      patientSexesZero: 'master/selectPatientSexesZero',
      selectSpecies: 'species/selectData',
      staffsZero: 'staffs/selectDataZero'
    }),
    birthdayRequiredFlg() {
      return this.patient.insurance !== ''
    },
    selectDataOnlyDoctor() {
      return this.staffsZero.filter(obj => {
        return obj.id === 0 || obj.jobId === 1
      })
    },
    inputPatientNameKana: {
      get() {
        return this.patient.nameKana
      },
      set(val) {
        this.$set(this.patient, 'nameKana', hiraToKata(val))
      }
    },
    deathMinDate() {
      const medicalRecords = this.medicalRecords.filter(
        v => v.patientId === this.patient.id
      )
      let lastDateMedicalRecord
      if (medicalRecords.length > 0) {
        const dates = medicalRecords.map(v => v.date)
        lastDateMedicalRecord = String(Math.max(...dates))
      }
      return lastDateMedicalRecord
        ? lastDateMedicalRecord
        : this.patient.birthday.length === 8
        ? this.patient.birthday
        : this.patient.birthday.length === 6
        ? moment(this.patient.birthday, 'YYYYMM').format('YYYYMMDD')
        : this.patient.birthday.length === 4
        ? moment(this.patient.birthday, 'YYYY').format('YYYYMMDD')
        : ''
    },
    deathMaxDate() {
      return moment().format('YYYYMMDD')
    },
    mcMinDate() {
      return this.patient.birthday !== ''
        ? moment(this.patient.birthday).format('YYYYMMDD')
        : ''
    },
    mcMaxDate() {
      return this.patient.deathDate !== ''
        ? this.patient.deathDate
        : moment().format('YYYYMMDD')
    },
    displayReservations() {
      const patientReservations = this.reservations
      return patientReservations
        .sort((a, b) => {
          return a.date > b.date ? -1 : 1
        })
        .sort((a, b) => {
          if (a.date === b.date) return a.startTime > b.startTime ? -1 : 1
        })
        .sort((a, b) => {
          if (a.date === b.date && a.startTime === b.startTime)
            return a.endTime > b.endTime ? -1 : 1
        })
        .map(r => {
          const time = r.startTime
          r = {
            id: r.id,
            date: moment(r.date).format(`Y年M月D日（dd）`),
            time: `${parseInt(time.slice(0, 2))}:${time.slice(2, 4)} `,
            itemStyles: this.reservationHistoryTable.itemStyles
          }
          return r
        })
    },
    displayVaccinations() {
      return this.patientVaccines.map((patientVaccine, i) => {
        const vaccine = this.getMasterDatum(
          'vaccines',
          patientVaccine.vaccineId
        )
        const previousDate = computePreviousDate({
          patientAntibodyTypes: this.patient.id
            ? this.antibodyTypesByPatientId(this.patient.id)
            : [],
          vaccineId: vaccine.id,
          getAntibodyById: this.getAntibodyById
        })
        const { hasImmunity, nextPeriod } = computeNextVaccinePeriod(
          this.patientVaccinePeriods,
          previousDate,
          patientVaccine
        )
        const nextDate = nextPeriod
          ? nextPeriod.endDate
            ? this.formatDate(nextPeriod.startDate) +
              ' ~ ' +
              this.formatDate(nextPeriod.endDate)
            : this.formatDate(nextPeriod.startDate)
          : ''
        const beforeOrAfter = patientVaccine.alertBeforeFlg === 1 ? '前' : '後'
        const alertText = patientVaccine.alertFlg
          ? patientVaccine.alertBeforeDay + '日' + beforeOrAfter
          : ''
        return {
          id: i + 1,
          name: vaccine.name,
          vaccineId: vaccine.id,
          nextDate,
          alert: alertText,
          itemStyles: hasImmunity
            ? this.vaccinationTable.itemStyles
            : this.vaccinationTable.itemStylesRed
        }
      })
    }
  },

  methods: {
    inputMcDate(val) {
      this.$emit('input', val, 'mcDate')
    },
    inputBirthday(val) {
      this.$emit('input', val, 'birthday')
    },
    inputDeathDate(val) {
      this.$emit('input', val, 'deathDate')
    },
    insuranceToId(insurance) {
      const target = this.insuranceList.find(v => v.value === insurance)
      const id = target ? target.id : ''
      return id
    },
    inputInsurance(id) {
      const target = this.insuranceList.find(v => v.id === id)
      const val = target ? target.value : ''
      this.$emit('input', val, 'insurance')
    },
    inputAnicom({ key, val }) {
      this.$emit('input-anicom', { key, val })
    },
    inputIpet({ key, val }) {
      this.$emit('input-ipet', { key, val })
    },
    inputDocomo({ key, val }) {
      this.$emit('input-docomo', { key, val })
    },
    renewPeriod(insurance) {
      this.$emit('renew-period', insurance)
    },
    clearDate() {
      this.$emit('clear-date')
    },
    handleClick() {
      this.$emit('click')
    },
    formatDate(date, dayFlg = false) {
      return dayFlg
        ? moment(date, 'YYYYMMDD').format('Y年M月D日（dd）')
        : moment(date, 'YYYYMMDD').format('Y年M月D日')
    },
    openEditVaccinePopup(displayId) {
      const displayPatientVaccine = this.displayVaccinations.find(
        v => v.id === displayId
      )
      const patientVaccine = this.patientVaccines.find(
        v => v.vaccineId === displayPatientVaccine.vaccineId
      )
      this.$emit('open-edit-vaccine-popup', patientVaccine)
    }
  }
}
</script>

<style lang="scss" scoped>
.patient-input-form {
  width: 600px;
  > .row {
    margin-bottom: 19px;
    font-size: 15px;
    &.vaccinations {
      display: flex;
      align-items: flex-start;
      > .label {
        display: flex;
        align-items: center;
        width: 200px;
        min-width: 200px;
        > .add-button {
          margin-left: 20px;
        }
      }
    }
    &.death {
      display: flex;
      .label {
        width: 200px;
        min-width: 200px;
      }
      > .wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        > .checkbox {
          padding: 5px 5px 5px 0;
        }
      }
    }
    ::v-deep .auto-input-form .heading,
    ::v-deep .text-input-form .heading,
    ::v-deep .select-box-form .heading,
    ::v-deep .birthday-input-form .heading,
    ::v-deep .multiple-text-input-form .heading {
      width: 200px;
    }
    ::v-deep .list {
      > .table-body {
        max-height: 168px;
      }
    }
  }
  > .anicom,
  .ipet,
  .docomo {
    display: flex;
    > .label {
      width: 200px;
      min-width: 200px;
    }
  }
}
::v-deep .text-input-form .body .validate .error {
  width: 550px;
}
</style>
