<template>
  <div class="base-comb-box">
    <input
      class="text-box"
      type="text"
      :placeholder="placeholder"
      :style="styles"
      :maxlength="maxlength"
      v-model="textBoxValue"
      @blur="$emit('blur')"
    />
    <div class="select-wrapper">
      <select
        class="select-box"
        data-test="select-box"
        :value="value"
        @change="selectValue"
        @blur="$emit('blur')"
      >
        <option v-for="datum in selectData" :value="datum.id" :key="datum.id">
          {{ datum.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseCombBox',
  props: {
    value: { type: [String, Number], default: '' },
    selectData: {
      type: Array, //[{id:int,name:string}]
      required: true,
      validator: arr => {
        return arr.every(v => {
          return (
            Number.isInteger(v.id) &&
            typeof v.name === 'string' &&
            v.id !== null &&
            v.name !== null
          )
        })
      }
    },
    styles: {
      type: Object,
      validator: obj => {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    },
    placeholder: { type: String, default: '' },
    maxlength: { type: Number, default: 50 }
  },
  computed: {
    textBoxValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    selectValue: function(e) {
      const id = parseInt(e.target.value)
      const addValue = this.selectData.find(v => v.id === id).name
      const newValue =
        id === 0
          ? ''
          : this.value === ''
          ? addValue
          : this.value + ',' + addValue
      this.$emit('input', newValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-comb-box {
  width: 100%;
  display: flex;
  > .text-box {
    width: 265px;
    height: 33px;
    border-radius: 4px;
    border: solid 2px #{$light-grey};
    background-color: #{$white};
    padding: 2px 8px;
    font-size: 15px;
    text-align: left;
    box-sizing: border-box;
    &::placeholder {
      color: #{$brown-gray};
    }
    &::-ms-clear {
      display: none;
    }
    &::-ms-reveal {
      display: none;
    }
  }
  > .select-wrapper {
    display: inline-block;
    position: relative;
    margin-left: -24px;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 8px;
      width: 6px;
      height: 6px;
      border: 0px;
      border-bottom: solid 2px #{$pumpkin};
      border-right: solid 2px #{$pumpkin};
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      margin-top: -6px;
      pointer-events: none;
    }
    > .select-box {
      cursor: pointer;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      display: block;
      position: relative;
      height: 33px;
      width: 20px;
      padding: 0 20px 0 0;
      font-size: 13px;
      box-sizing: border-box;
      border: 2px solid transparent;
      border-radius: 4px;
      background-clip: padding-box;
      > .option-default-none {
        display: none;
      }
    }
    > .select-box::-ms-expand {
      display: none;
    }
  }
}
</style>
