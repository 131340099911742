import moment from 'moment'

export const computePreviousDate = ({
  patientAntibodyTypes,
  vaccineId,
  getAntibodyById
}) => {
  // return date (String 'YYYYMMDD') of latest antibody linked to a 'vaccinated' or 'positive' antibodyType
  // return '' if no such antibody exists

  const filteredAntibodyTypes =
    patientAntibodyTypes?.filter(
      v =>
        v.vaccineId === vaccineId &&
        (v.type === 'positive' || v.type === 'vaccinated')
    ) || []
  const filteredAntibodies = filteredAntibodyTypes.map(v =>
    getAntibodyById(v.antibodyId)
  )
  filteredAntibodies.sort((a, b) => b.date - a.date)
  const latestAntibody = filteredAntibodies[0]
  return latestAntibody?.date || ''
}

export const makeRepeatPeriods = sortedPeriods => {
  const today = moment().format('YYYYMMDD')
  let periods = sortedPeriods
  if (periods.length !== 0 && periods[periods.length - 1].repeatFlg === 1) {
    const repeatPeriod = periods[periods.length - 1]
    let startDate = repeatPeriod.startDate
    let endDate = repeatPeriod.endDate
    while (startDate <= today) {
      startDate = moment(startDate, 'YYYYMMDD')
        .add(1, 'year')
        .format('YYYYMMDD')
      endDate = endDate
        ? moment(endDate, 'YYYYMMDD')
            .add(1, 'year')
            .format('YYYYMMDD')
        : ''
      periods.push({
        vaccineId: repeatPeriod.vaccineId,
        startDate,
        endDate,
        repeatFlg: 1,
        delFlg: 0
      })
    }
  }
  return periods
}

export const computeNextVaccinePeriod = (
  patientVaccinePeriods,
  previousVaccineDate,
  patientVaccine
) => {
  // return immunity status (Boolean) and next vaccination period (Obj || null)

  // find closest past period and closest future period
  // decide vaccine status based on previousVaccineDate
  // if vaccinated => return closest future period
  // else => return closest past period

  const sortedPeriods = patientVaccinePeriods
    .filter(v => v.vaccineId === patientVaccine.vaccineId)
    .sort((a, b) => a.startDate - b.startDate)
  const allPeriods = makeRepeatPeriods(sortedPeriods)
  const today = moment().format('YYYYMMDD')
  const nextIndex = allPeriods.findIndex(v => v.startDate > today)
  let pastPeriod, futurePeriod
  if (nextIndex === -1) {
    pastPeriod = allPeriods[allPeriods.length - 1] || null
    futurePeriod = null
  } else {
    pastPeriod = allPeriods[nextIndex - 1] || null
    futurePeriod = allPeriods[nextIndex]
  }
  const hasImmunity = Boolean(
    (!previousVaccineDate && pastPeriod === null) ||
      (previousVaccineDate &&
        (pastPeriod === null || previousVaccineDate >= pastPeriod.startDate))
  )
  return {
    hasImmunity,
    nextPeriod: hasImmunity ? futurePeriod : pastPeriod
  }
}

export const antibodyTypesEnToJp = {
  '': '-',
  vaccinated: '接種',
  positive: '抗体あり',
  negative: '抗体なし',
  waiting: '抗体検査待'
}

export const mediaEnToJp = {
  tel: '電話',
  mail: 'メール',
  letter: '郵便',
  other: 'その他'
}

const swapObjectKeyValue = obj => {
  const temp = {}
  Object.keys(obj).forEach(key => {
    temp[obj[key]] = key
  })
  return temp
}

export const antibodyTypesJpToEn = swapObjectKeyValue(antibodyTypesEnToJp)
