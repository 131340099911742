<template>
  <div class="owner-show-content">
    <div class="title-wrapper">
      <div class="title">飼主基本情報</div>
      <div class="button-area">
        <base-button-small-orange
          class="button"
          v-if="lookOnlyFlg === 0"
          :disabled="displayOwner.email === '' || waitFlg"
          :styles="{ width: '170px' }"
          @click="openSendPasswordPopup"
          >パスワード発行・通知</base-button-small-orange
        >
        <base-button-small-white
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="openOwnerEdit"
          >編集</base-button-small-white
        >
        <base-button-small-red
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="openDeleteFirstPopup"
          >削除</base-button-small-red
        >
      </div>
    </div>
    <div class="content-wrapper">
      <show-data-list
        :displayKeys="displayKeys"
        :keys="keys"
        :data="displayOwner"
      />
    </div>
    <announce-popup
      v-if="sendPasswordPopup.alertFlg"
      :type="this.sendPasswordPopup.type"
      :title="this.sendPasswordPopup.title"
      :buttons="this.sendPasswordPopup.buttons"
      :disabled="waitFlg"
      @cancel="sendPasswordPopup.alertFlg = false"
      @decision="createAndSendPassword"
      @close="closeSendPasswordPopup"
      >{{ sendPasswordPopup.popupMessage }}</announce-popup
    >
    <announce-popup
      v-if="alertFlg"
      :type="type"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      @cancel="cancelOwnerDelete"
      @decision="clickDeleteButton"
      @close="closeAlert"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>
<script>
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import ShowDataList from '@/components/parts/organisms/ShowDataList'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'OwnerShowContent',

  components: {
    BaseButtonSmallOrange,
    BaseButtonSmallWhite,
    BaseButtonSmallRed,
    ShowDataList,
    AnnouncePopup
  },

  props: {
    id: { type: Number }
  },

  data() {
    const smsMode = this.$store.getters['auth/smsFlg']
    const reservationSetting = this.$store.getters['reservationSetting/getData']
    const enAvailable = reservationSetting.englishOptionFlg === 1
    const displayKeys = [
      'タグ',
      '飼主ID',
      '飼主名（カナ）',
      '性別',
      '生年月日（年齢）',
      '住所',
      '電話番号',
      'Eメールアドレス',
      ...(smsMode ? ['連絡方法'] : []),
      '備考',
      ...(enAvailable ? ['英語表記'] : [])
    ]
    const keys = [
      'tags',
      'showId',
      'name',
      'sex',
      'birthAge',
      'fullAddress',
      'tel',
      'email',
      ...(smsMode ? ['contactMethod'] : []),
      'note',
      ...(enAvailable ? ['englishFlg'] : [])
    ]
    return {
      displayKeys,
      keys,
      sendPasswordPopup: {
        alertFlg: false,
        type: '',
        title: '',
        buttons: ['閉じる'],
        popupMessage: ''
      },
      noDataFlg: false,
      alertFlg: false,
      waitFlg: false,
      type: '',
      title: '',
      buttons: ['閉じる'],
      popupMessage: '',
      deleteFirstDecisionFlg: false,
      pushToOwners: false
    }
  },

  computed: {
    ...mapGetters({
      ownerTags: 'ownerTags/getData',
      lookOnlyFlg: 'auth/lookOnlyFlg',
      karteFlg: 'auth/karteFlg',
      getMasterDatum: 'master/getDataById'
    }),
    displayOwner() {
      const owner = this.$store.getters['owners/getDataById'](this.id)
      if (owner) {
        const ownerTags = this.ownerTags.filter(v =>
          owner.ownerTagIds.includes(v.id)
        )
        const age = moment().diff(moment(owner.birthday), 'years') + '歳'
        const prefName =
          this.getMasterDatum('prefectures', owner.prefectureId)?.name || ''
        const address = prefName + owner.address + owner.building
        return {
          showId: owner.showId,
          name: `${owner.lastName} ${owner.firstName}（${owner.lastNameKana} ${owner.firstNameKana}）`,
          tags: ownerTags,
          sex:
            owner.sex === 0
              ? ''
              : owner.sex === 1
              ? '男性'
              : owner.sex === 2
              ? '女性'
              : 'その他',
          birthAge: owner.birthday
            ? moment(owner.birthday).format(`Y年M月D日（${age}）`)
            : '',
          fullAddress: owner.postalCode
            ? owner.postalCode + '\n' + address
            : address,
          email: owner.email,
          contactMethod: owner.contactSmsFlg ? 'SMS' : 'メール',
          tel: owner.tel,
          note: owner.note,
          englishFlg: owner.englishFlg ? '有効' : '無効'
        }
      } else {
        return {}
      }
    }
  },

  methods: {
    async openSendPasswordPopup() {
      this.waitFlg = true
      const res = await this.$store.dispatch(
        'owners/getIsPasswordConfigured',
        this.id
      )
      if (res.result === true) {
        this.sendPasswordPopup.alertFlg = true
        this.sendPasswordPopup.type = 'alert'
        this.sendPasswordPopup.title = '注意'
        this.sendPasswordPopup.buttons = ['送信しない', '送信する']
        this.sendPasswordPopup.popupMessage = res.isPasswordConfigured
          ? 'この飼主には既にパスワードが設定されております。\nランダムなパスワードに設定し直して\n飼主のメールアドレスに通知してもよろしいでしょうか？'
          : 'ランダムなパスワードを作成し\n飼主のメールアドレスに通知してもよろしいでしょうか？'
      } else {
        this.sendPasswordPopup.type = 'failure'
        this.sendPasswordPopup.title = '失敗'
        this.sendPasswordPopup.buttons = ['閉じる']
        this.sendPasswordPopup.popupMessage = '通信エラーが発生しました。'
        this.sendPasswordPopup.alertFlg = true
      }
      this.waitFlg = false
    },
    async createAndSendPassword() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'owners/createAndSendPassword',
        this.id
      )
      if (result === true) {
        this.sendPasswordPopup.type = 'success'
        this.sendPasswordPopup.title = '完了'
        this.sendPasswordPopup.popupMessage =
          'パスワードを発行し、飼主にメールを送信しました。'
        this.sendPasswordPopup.buttons = ['閉じる']
      } else {
        this.sendPasswordPopup.type = 'failure'
        this.sendPasswordPopup.title = '失敗'
        this.sendPasswordPopup.buttons = ['閉じる']
        if (result === 'no data' || result === 'no data in clinic') {
          this.sendPasswordPopup.popupMessage =
            'パスワードの発行とメールの送信に失敗しました。\n飼主は既に削除されています。'
          this.noDataFlg = true
        } else {
          this.sendPasswordPopup.popupMessage =
            'パスワードの発行とメールの送信に失敗しました'
        }
      }
      this.sendPasswordPopup.alertFlg = true
      this.waitFlg = false
    },
    closeSendPasswordPopup() {
      this.sendPasswordPopup.alertFlg = false
      if (this.noDataFlg) {
        this.$emit('open-owners')
      }
    },
    openOwnerEdit() {
      this.$emit('open-owner-edit')
    },
    openDeleteFirstPopup() {
      this.type = 'alert'
      this.title = '注意'
      this.popupMessage = '削除してもよろしいですか？'
      this.buttons = ['削除しない', '削除する']
      this.alertFlg = true
    },
    cancelOwnerDelete() {
      this.alertFlg = false
      this.deleteFirstDecisionFlg = false
    },
    clickDeleteButton() {
      if (!this.deleteFirstDecisionFlg) {
        this.popupMessage = this.karteFlg
          ? '本当に削除してもよろしいですか？\n\n※飼主の受信・送信メール以外の全てのデータ（患者・予約・予約申請・\nカルテ・会計・予防接種・見積など）が削除されます。\n※削除したデータの復旧はできません。'
          : '本当に削除してもよろしいですか？\n\n※飼主の受信・送信メール以外の全てのデータ（患者・予約・予約申請）\nが削除されます。\n※削除したデータの復旧はできません。'
        this.buttons = ['削除しない', '削除する']
        this.deleteFirstDecisionFlg = true
      } else {
        this.deleteOwner()
      }
    },
    async deleteOwner() {
      this.waitFlg = true
      const result = await this.$store.dispatch('owners/delete', this.id)
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = '削除しました'
        this.buttons = ['閉じる']
        this.pushToOwners = true
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        if (result === 'used in reservationRequests') {
          this.popupMessage =
            '今日以降の予約申請で使用されている飼主は削除できません'
        } else if (result === 'used in reservations') {
          this.popupMessage =
            '今日以降の予約で使用されている飼主は削除できません'
        } else {
          this.popupMessage = '削除に失敗しました'
        }
      }
      this.waitFlg = false
    },
    closeAlert() {
      this.alertFlg = false
      if (this.pushToOwners) {
        this.$emit('open-owners')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.owner-show-content {
  width: 100%;
  > .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 33px;
    width: 100%;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 15px;
    > .title {
      font-size: 15px;
      font-weight: bold;
    }
    > .button-area {
      display: flex;
      > .button {
        margin-left: 20px;
      }
    }
  }
  > .content-wrapper {
    width: 100%;
    margin-top: 30px;
  }
}
</style>
