<template>
  <div class="ipet-form">
    <div class="row plan-nm">
      <select-box-form
        data-test="plan-nm"
        :requiredFlg="true"
        :selectData="plans"
        :styles="{ width: '240px' }"
        :value="nameToId(ipetPatient.planNm)"
        @input="inputPlanNm"
        >商品名</select-box-form
      >
    </div>
    <div class="row claim-rate">
      <select-box-form
        data-test="claim-rate"
        :disabled="!ipetPatient.planNm"
        :requiredFlg="true"
        :selectData="rates"
        :styles="{ width: '100px' }"
        :value="rateToId(ipetPatient.claimRate)"
        @input="inputClaimRate"
        >補償割合</select-box-form
      >
    </div>
    <div class="row policy-no">
      <text-input-form
        data-test="policy-no"
        :requiredFlg="true"
        :validationRules="{ numericString: { length: 10 } }"
        :maxlength="10"
        :styles="{ width: '130px' }"
        :value="ipetPatient.policyNo"
        :fullWidthNumbersToHalfWidthNumbers="true"
        @input="emitProperty('policyNo', $event)"
        >証券番号</text-input-form
      >
    </div>
    <div class="row period">
      <div class="label">
        保険期間
        <div class="required">必須</div>
      </div>
      <div class="value">
        <validation-provider
          class="validate"
          ref="start"
          :rules="{ requiredRule: true, startDateRule: ipetPatient.endDate }"
          v-slot="{ errors }"
          ><base-date-picker
            data-test="start"
            :popoverProps="{ visibility: 'click', ...popoverProps }"
            :value="ipetPatient.startDate"
            @click="focusStart"
            @input="emitProperty('startDate', $event)"
          />
          <div class="error wide" data-test="start-error">{{ errors[0] }}</div>
        </validation-provider>
        <div class="wavy-line">～</div>
        <validation-provider
          class="validate"
          ref="end"
          :rules="{ requiredRule: true }"
          v-slot="{ errors }"
          ><base-date-picker
            data-test="end"
            :popoverProps="{ visibility: 'click', ...popoverProps }"
            :value="ipetPatient.endDate"
            @click="focusEnd"
            @input="emitProperty('endDate', $event)"
          />
          <div class="error" data-test="end-error">{{ errors[0] }}</div>
        </validation-provider>
        <base-button-small-white
          v-if="canRenew"
          :styles="{ width: '100px' }"
          @click="renewPeriod"
          >期間更新</base-button-small-white
        >
      </div>
    </div>
    <div class="row contract-date">
      <div class="label">初年度契約日</div>
      <div class="value">
        <base-date-picker
          data-test="contract-date"
          :popoverProps="{ visibility: 'click', ...popoverProps }"
          :clearFlg="true"
          :value="ipetPatient.firstContractDate"
          @input="emitProperty('firstContractDate', $event)"
          @clear="clearDate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseDatePicker from '@/components/parts/atoms/BaseDatePicker'
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm'
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'

export default {
  name: 'IpetForm',

  components: {
    BaseButtonSmallWhite,
    BaseDatePicker,
    SelectBoxForm,
    TextInputForm,
    ValidationProvider
  },

  props: {
    insurance: { type: String },
    ipetPatient: { type: Object },
    popoverProps: { type: Object, default: () => {} }
  },

  data() {
    return {
      ipetPlans: [
        { id: 1, name: 'うちの子' },
        { id: 2, name: 'うちの子キュート' },
        { id: 3, name: 'うちの子プラス' }
      ],
      docomoPlans: [{ id: 1, name: 'ドコモのペット保険トータルケア' }],
      ratesByPlan: {
        '': [],
        うちの子: [70, 50],
        うちの子キュート: [70, 50, 30],
        うちの子プラス: [100, 70, 50],
        ドコモのペット保険トータルケア: [70, 50]
      }
    }
  },

  computed: {
    canRenew() {
      const { id, startDate, endDate } = this.ipetPatient
      return id && startDate && endDate ? true : false
    },
    plans() {
      let list = []
      const insurance = this.insurance
      if (insurance === 'ipet') list = this.ipetPlans
      else if (insurance === 'docomo') list = this.docomoPlans
      return list
    },
    rates() {
      const { planNm } = this.ipetPatient
      const list = this.ratesByPlan[planNm].map((v, i) => {
        return { id: i + 1, name: `${v}%`, rate: v }
      })
      return list
    }
  },

  methods: {
    nameToId(planNm) {
      const target = this.plans.find(v => v.name === planNm)
      const id = target ? target.id : ''
      return id
    },
    rateToId(claimRate) {
      const target = this.rates.find(v => v.rate === claimRate)
      const id = target ? target.id : ''
      return id
    },
    inputPlanNm(id) {
      const target = this.plans.find(v => v.id === id)
      const planNm = target ? target.name : ''
      this.emitProperty('planNm', planNm)
      this.emitProperty('claimRate', 0)
    },
    inputClaimRate(id) {
      const target = this.rates.find(v => v.id === id)
      const claimRate = target ? target.rate : 0
      this.emitProperty('claimRate', claimRate)
    },
    emitProperty(key, val) {
      this.$emit('input', { key, val })
    },
    focusStart() {
      this.$refs.start.validate()
    },
    focusEnd() {
      this.$refs.end.validate()
    },
    renewPeriod() {
      this.$emit('renew-period')
    },
    clearDate() {
      this.$emit('clear-date')
    }
  }
}
</script>

<style lang="scss" scoped>
$label-width: 150px;
.ipet-form {
  width: 600px;
  > .row {
    margin-bottom: 19px;
    font-size: 15px;
    ::v-deep .text-input-form .heading,
    ::v-deep .select-box-form .heading {
      max-width: $label-width;
    }
  }
  > .policy-no {
    ::v-deep .text-input-form .body .validate .error {
      width: 550px;
    }
  }
  > .period {
    display: flex;
    align-items: center;
    > .label {
      width: $label-width;
      display: flex;
      align-items: center;
      gap: 0 10px;
      > .required {
        font-size: 13px;
        color: #{$tomato};
      }
    }
    > .value {
      display: flex;
      align-items: center;
      gap: 0 10px;
      > .validate {
        position: relative;
        > .error {
          position: absolute;
          z-index: 1;
          color: #{$tomato};
          font-size: 12px;
          text-align: left;
          &.wide {
            width: 400px;
          }
        }
      }
    }
  }
  > .contract-date {
    display: flex;
    align-items: center;
    > .label {
      width: $label-width;
      display: flex;
      align-items: center;
    }
  }
}
</style>
