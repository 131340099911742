<template>
  <div class="owners-list-table-row-item" :style="itemStyle" :title="itemTexts">
    <slot />
    <div class="item-text" v-for="(itemText, index) in itemTexts" :key="index">
      {{ itemText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'OwnersListTableRowItem',

  props: {
    itemTexts: { type: Array },
    itemStyle: {
      type: Object,
      validator: function(obj) {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.owners-list-table-row-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 20px;
  overflow: hidden;
  text-align: center;
  > .item-text {
    width: 100%;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:nth-child(odd) {
      margin-top: 6px;
    }
    &:nth-child(even) {
      margin-bottom: 6px;
    }
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
