<template>
  <div class="comb-input-form">
    <div
      class="heading"
      :style="{ height: headingHeight, width: headingWidth }"
    >
      <div class="text"><slot></slot></div>
      <div class="required" v-if="requiredFlg">必須</div>
    </div>
    <div class="body" :style="bodyStyle">
      <validation-provider
        :rules="{
          ...validationRules,
          requiredRule: requiredFlg,
          notSurrogatePair: true
        }"
        v-slot="{ errors }"
      >
        <base-comb-box
          :selectData="selectData"
          :maxlength="maxlength"
          :placeholder="placeholder"
          :styles="styles"
          v-model="input"
        />
        <div v-if="errorShowFlg" class="error" :style="errorStyles">
          <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
        </div>
      </validation-provider>
    </div>
  </div>
</template>

<script>
import BaseCombBox from '@/components/parts/atoms/BaseCombBox'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'

export default {
  name: 'CombInputForm',

  components: {
    BaseCombBox,
    ValidationProvider
  },

  props: {
    headingStyle: { type: Object },
    bodyStyle: { type: Object },
    validationRules: { type: Object },
    styles: { type: Object },
    value: { type: [String, Number], default: '' },
    selectData: {
      type: Array //[{id:int,name:string}]
    },
    placeholder: { type: String, default: '' },
    maxlength: { type: Number },
    requiredFlg: { type: Boolean, default: false },
    errorShowFlg: { type: Boolean, default: false },
    errorStyles: { type: Object }
  },

  computed: {
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    headingHeight() {
      return this.styles ? (this.styles.height ? this.styles.height : '') : ''
    },
    headingWidth() {
      return this.headingStyle
        ? this.headingStyle.width
          ? this.headingStyle.width
          : ''
        : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.comb-input-form {
  display: flex;
  align-items: flex-start;
  > .heading {
    display: flex;
    align-items: center;
    height: 33px;
    width: 40%;
    font-size: 15px;
    > .required {
      margin-left: 10px;
      font-size: 13px;
      color: #{$tomato};
    }
  }
  > .body {
    width: 60%;
    display: flex;
    flex-direction: row;
    position: relative;
    @include validate-message();
  }
}
</style>
