<template>
  <div class="owner-input-form">
    <div class="row tags">
      <tags-input-form
        :ownerTagIds="owner.ownerTagIds"
        @input="val => $emit('input-tags', val)"
        >タグ</tags-input-form
      >
    </div>
    <div class="row show-id">
      <auto-input-form
        :requiredFlg="true"
        :validationRules="{ regexShowId: true, maxRule: 20 }"
        :maxlength="20"
        :placeholder="'20文字まで'"
        :inputStyle="{ width: '150px' }"
        :buttonShowFlg="commonSetting.ownerShowIdHelperFlg === 1"
        :buttonText="'自動ID割振り'"
        :buttonStyle="{ width: '140px' }"
        v-model="owner.showId"
        @click="$emit('auto-input-show-id')"
        >飼主ID</auto-input-form
      >
    </div>
    <div
      class="row patient-show-id"
      v-if="
        commonSetting.patientShowIdHelperKind === 1 && owner.id && existsPatient
      "
    >
      <div class="label">
        <div class="text">患者IDの一括更新</div>
        <hint-balloon
          :note="
            'チェックを入れて登録した場合、この飼主の患者の\nIDが「飼主ID-xxx」のように更新されます。'
          "
        />
      </div>
      <base-check-box
        :isChecked="updatePatientShowId"
        @input="val => $emit('update-patients-show-id', val)"
      />
    </div>
    <div class="row name">
      <full-name-input-form
        :requiredFlg="true"
        :lastName="owner.lastName"
        :firstName="owner.firstName"
        @input-last-name="inputVal"
        @input-first-name="inputVal"
        >飼主名</full-name-input-form
      >
    </div>
    <div class="row nameKana">
      <full-name-input-form
        :kanaFlg="true"
        :requiredFlg="true"
        :lastName="owner.lastNameKana"
        :firstName="owner.firstNameKana"
        @input-last-name="inputVal"
        @input-first-name="inputVal"
        >飼主名（全角カナ）</full-name-input-form
      >
    </div>
    <div class="row sex">
      <select-box-form :selectData="sexes" v-model.number="owner.sex"
        >性別</select-box-form
      >
    </div>
    <div class="row birthday">
      <birthday-input-form
        :birthday="owner.birthday"
        :errorShowFlg="true"
        @input="inputVal"
        >生年月日</birthday-input-form
      >
    </div>
    <div class="row postal-code">
      <auto-input-form
        :validationRules="{
          regexPostalCode: true,
          apiResultPostalCode: apiResultPostalCode
        }"
        :immediate="true"
        :placeholder="'例：1234567'"
        :maxlength="7"
        :inputStyle="{ width: '120px' }"
        :buttonText="'住所自動入力'"
        :buttonStyle="{ width: '140px' }"
        :fullWidthNumbersToHalfWidthNumbers="true"
        :value="owner.postalCode"
        @input="inputVal($event, 'postalCode')"
        @click="$emit('auto-input-address')"
        >郵便番号</auto-input-form
      >
    </div>
    <div class="row prefectureId">
      <select-box-form
        :selectData="prefecturesZero"
        v-model.number="owner.prefectureId"
        >都道府県</select-box-form
      >
    </div>
    <div class="row address">
      <text-input-form
        :placeholder="'50文字まで'"
        :maxlength="50"
        :styles="{ width: '364px' }"
        v-model="owner.address"
        >市区町村</text-input-form
      >
    </div>
    <div class="row building">
      <text-input-form
        :placeholder="'50文字まで'"
        :maxlength="50"
        :styles="{ width: '364px' }"
        v-model="owner.building"
        >ビル・マンション名</text-input-form
      >
    </div>
    <div class="row tel">
      <text-input-form
        :validationRules="{ regexTel: true }"
        :placeholder="'例：09012345678'"
        :maxlength="11"
        :styles="{ width: '160px' }"
        :fullWidthNumbersToHalfWidthNumbers="true"
        v-model="owner.tel"
        >電話番号（半角数字）</text-input-form
      >
    </div>
    <div class="row emailForm">
      <text-input-form
        :validationRules="{ emailRule: true }"
        :placeholder="'例：petorelu@email.com'"
        :type="'email'"
        :maxlength="254"
        :styles="{ width: '364px' }"
        v-model="owner.email"
        >メールアドレス</text-input-form
      >
    </div>
    <div v-if="showSms()" class="row sms">
      <select-box-form
        :selectData="contactMethods"
        v-model.number="owner.contactSmsFlg"
        >連絡方法</select-box-form
      >
    </div>
    <div class="row note">
      <multiple-text-input-form v-model="owner.note"
        >備考</multiple-text-input-form
      >
    </div>
    <div v-if="enAvailable" class="row english-flg">
      <radio-button-form
        data-test="radio-button-form"
        :title="'英語表記'"
        :radioButtonData="radioButtonDataEnglishFlg"
        v-model="owner.englishFlg"
      />
    </div>
  </div>
</template>

<script>
import AutoInputForm from '@/components/parts/molecules/AutoInputForm'
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox.vue'
import FullNameInputForm from '@/components/parts/molecules/FullNameInputForm'
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm'
import BirthdayInputForm from '@/components/parts/molecules/BirthdayInputForm'
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import MultipleTextInputForm from '@/components/parts/molecules/MultipleTextInputForm'
import TagsInputForm from '@/components/parts/organisms/TagsInputForm'
import HintBalloon from '@/components/parts/atoms/HintBalloon'
import RadioButtonForm from '@/components/parts/molecules/RadioButtonForm.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'OwnerInputForm',

  components: {
    AutoInputForm,
    BaseCheckBox,
    FullNameInputForm,
    SelectBoxForm,
    BirthdayInputForm,
    TextInputForm,
    MultipleTextInputForm,
    TagsInputForm,
    HintBalloon,
    RadioButtonForm
  },

  props: {
    owner: { type: Object },
    updatePatientShowId: { type: Boolean },
    apiResultPostalCode: { type: Object, default: () => null }
  },

  data() {
    const reservationSetting = this.$store.getters['reservationSetting/getData']
    return {
      sexes: [
        { id: 0, name: '' },
        { id: 1, name: '男性' },
        { id: 2, name: '女性' },
        { id: 3, name: 'その他' }
      ],
      contactMethods: [
        { id: 0, name: 'メール' },
        { id: 1, name: 'SMS' }
      ],
      radioButtonDataEnglishFlg: [
        { id: 1, eachValue: 1, labelName: '有効' },
        { id: 2, eachValue: 0, labelName: '無効' }
      ],
      enAvailable: reservationSetting.englishOptionFlg === 1
    }
  },

  computed: {
    ...mapGetters({
      prefecturesZero: 'master/selectPrefecturesZero',
      commonSetting: 'commonSetting/getData',
      patients: 'patients/getData',
      smsFlg: 'auth/smsFlg'
    }),
    existsPatient() {
      return this.patients.some(o => o.ownerId === this.owner.id)
    }
  },

  methods: {
    inputVal(val, property) {
      this.$emit('input', val, property)
    },
    showSms() {
      return this.smsFlg
    }
  }
}
</script>

<style lang="scss" scoped>
.owner-input-form {
  > .row {
    width: 600px;
    margin-bottom: 20px;
    &.patient-show-id {
      display: flex;
      align-items: center;
      height: 33px;
      > .label {
        width: 240px;
        font-size: 15px;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
