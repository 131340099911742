<template>
  <div class="owners-list-table-header-row">
    <owners-list-table-row-item
      v-for="item in rowItems"
      :key="item.itemId"
      v-bind="item"
      class="row-item"
      ><base-check-box
        v-if="bulkSendMailModeFlg && item.itemId === 1"
        :isChecked="bulkCheckFlg"
        @input="bulkCheckSendMailFlg"
    /></owners-list-table-row-item>
  </div>
</template>

<script>
import OwnersListTableRowItem from '@/components/parts/atoms/OwnersListTableRowItem.vue'
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'

export default {
  name: 'OwnersListTableHeaderRow',

  components: {
    OwnersListTableRowItem,
    BaseCheckBox
  },

  props: {
    rowItems: { type: Array },
    bulkSendMailModeFlg: { type: Boolean, default: false },
    bulkCheckFlg: { type: Boolean, default: false }
  },

  methods: {
    bulkCheckSendMailFlg(bulkCheckFlg) {
      this.$emit('bulk-check-send-mail-flg', bulkCheckFlg)
    }
  }
}
</script>

<style lang="scss" scoped>
.owners-list-table-header-row {
  display: flex;
  width: 100%;
  background-color: #{$pale};
  > .row-item {
    width: 100%;
    border-right: 1px solid #{$skin};
    &:last-child {
      border: none;
    }
  }
}
</style>
