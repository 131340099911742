<template>
  <div class="patient-new">
    <validation-observer v-slot="{ invalid }" class="observer">
      <div class="patient-wrapper">
        <div class="setting-title">患者基本情報登録</div>
        <div class="content-title">基本項目</div>
        <div class="content">
          <base-image-uploader
            class="img"
            :image="inputPatient.image"
            @input="inputPatientImage"
            @delete="deletePatientImage"
            @uploadingImg="setUploadingImg"
          />
          <div class="input-form">
            <patient-input-form
              :patient="inputPatient"
              :anicomPatient="inputAnicomPatient"
              :ipetPatientNative="inputIpetPatientNative"
              :ipetPatientDocomo="inputIpetPatientDocomo"
              :patientVaccines="displayPatientVaccines"
              :patientVaccinePeriods="displayPatientVaccinePeriods"
              @input="inputProperty"
              @input-anicom="inputAnicom"
              @input-ipet="inputIpet"
              @input-docomo="inputDocomo"
              @clear-date="clearDate"
              @click="createPatientShowId"
              @open-new-vaccine-popup="vaccinePopupFlg = true"
              @open-edit-vaccine-popup="openEditVaccinePopup"
            />
          </div>
        </div>
      </div>
      <div class="button-area">
        <base-button-medium-white class="button" @click="backPatientsListPage"
          >戻る</base-button-medium-white
        >
        <base-button-medium-orange
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="createData"
          :disabled="!isValidBirthday || invalid || waitFlg || uploadingImg"
          >登録</base-button-medium-orange
        >
      </div>
      <unsaved-leave-popup :layerNumber="2" />
      <vaccine-popup
        v-if="vaccinePopupFlg"
        :patientId="0"
        :apiFlg="false"
        :patientVaccine="editPatientVaccine"
        :patientVaccines="newPatientVaccines"
        :patientVaccinePeriods="editPatientVaccinePeriods"
        @add-patient-vaccine="addPatientVaccine"
        @update-patient-vaccine="updatePatientVaccine"
        @close="closeVaccinePopup"
      />
      <announce-popup
        v-if="alertFlg"
        @close="openListPage"
        :title="title"
        :buttons="buttons"
        :type="type"
        >{{ popupMessage }}</announce-popup
      >
    </validation-observer>
  </div>
</template>
<script>
import BaseImageUploader from '@/components/parts/atoms/BaseImageUploader'
import BaseButtonMediumWhite from '@/components/parts/atoms/BaseButtonMediumWhite'
import BaseButtonMediumOrange from '@/components/parts/atoms/BaseButtonMediumOrange'
import PatientInputForm from '@/components/parts/organisms/PatientInputForm'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import AllocateShowId from '@/components/mixins/AllocateShowId'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import VaccinePopup from '@/components/popups/VaccinePopup'
import { ValidationObserver } from 'vee-validate'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { VALID_DATE_REGEX } from '@/utils/define'

const baseAnicomPatient = { cId: '', planName: '', startDate: '', endDate: '' }
const baseIpetPatient = {
  planNm: '',
  claimRate: 0,
  policyNo: '',
  startDate: '',
  endDate: '',
  firstContractDate: ''
}

export default {
  name: 'PatientNew',

  components: {
    BaseImageUploader,
    BaseButtonMediumWhite,
    BaseButtonMediumOrange,
    PatientInputForm,
    AnnouncePopup,
    UnsavedLeavePopup,
    VaccinePopup,
    ValidationObserver
  },

  mixins: [AllocateShowId, CheckInputDifference],

  props: {
    ownerId: { type: Number }
  },

  data() {
    return {
      inputPatient: {
        ownerId: this.ownerId,
        image: null,
        showId: '',
        mcCode: '',
        mcDate: '',
        name: '',
        nameKana: '',
        sex: 0,
        hairColor: '',
        speciesId: '',
        breed: '',
        birthday: '',
        staffId: 0,
        insurance: '',
        note: '',
        deathFlg: false,
        deathDate: ''
      },
      inputAnicomPatient: { ...baseAnicomPatient },
      inputIpetPatientNative: { ...baseIpetPatient },
      inputIpetPatientDocomo: { ...baseIpetPatient },
      newPatientVaccines: [],
      newPatientVaccinePeriodGroups: [],
      waitFlg: false,
      alertFlg: false,
      title: '',
      buttons: ['閉じる'],
      type: '',
      popupMessage: '',
      reservationFlg: false,
      vaccinePopupFlg: false,
      editPatientVaccine: undefined,
      patientVaccineKey: 0,
      editPatientVaccinePeriods: [],
      uploadingImg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    displayPatientVaccines() {
      return this.newPatientVaccines.filter(v => v.delFlg !== 1)
    },
    displayPatientVaccinePeriods() {
      return this.newPatientVaccinePeriodGroups
        .map(group => group.filter(v => v.delFlg !== 1))
        .flat()
    },
    isValidBirthday() {
      const { insurance, birthday } = this.inputPatient
      return insurance ? VALID_DATE_REGEX.test(birthday) : true
    },
    insuranceDetail() {
      const inputKey = this.getCurrentInputKey()
      return this[inputKey] ?? {}
    }
  },

  watch: {
    insuranceDetail(newVal) {
      this.mixinInputData.insuranceDetail = newVal
    }
  },

  created() {
    const paramsReservation = this.$route.params.reservation
    if (paramsReservation) {
      this.inputPatient.name = paramsReservation.patientName
      this.reservationFlg = true
    }
    this.mixinAllocatePatientShowId(this.ownerId, this.inputPatient)
    if (this.inputPatient.showId.length > 20) {
      this.inputPatient.showId = this.inputPatient.showId.substring(0, 20)
    }
    this.mixinInputData = {
      patient: this.inputPatient,
      insuranceDetail: this.insuranceDetail,
      patientVaccines: this.newPatientVaccines,
      patientVaccinePeriods: this.newPatientVaccinePeriodGroups
    }
    this.mixinSetInitialData()
  },

  destroyed() {
    if (this.$route.name !== 'owner') {
      this.$store.dispatch('timeTable/clearReservationId')
    }
  },

  methods: {
    backPatientsListPage() {
      this.$router.push({ path: `/main/owners/${this.ownerId}` })
    },
    createPatientShowId() {
      this.mixinAllocatePatientShowId(this.ownerId, this.inputPatient)
    },
    inputPatientImage(image) {
      this.inputPatient.image = image
    },
    deletePatientImage() {
      this.inputPatient.image = null
    },
    inputProperty(val, key) {
      this.inputPatient[key] = val
    },
    inputAnicom({ key, val }) {
      this.inputAnicomPatient[key] = val
    },
    inputIpet({ key, val }) {
      this.inputIpetPatientNative[key] = val
    },
    inputDocomo({ key, val }) {
      this.inputIpetPatientDocomo[key] = val
    },
    clearDate() {
      const inputKey = this.getCurrentInputKey()
      const dateKey = 'firstContractDate'
      if (this[inputKey]?.hasOwnProperty(dateKey)) {
        this[inputKey][dateKey] = ''
      }
    },
    getCurrentInputKey() {
      const { insurance } = this.inputPatient
      const inputKeys = {
        anicom: 'inputAnicomPatient',
        ipet: 'inputIpetPatientNative',
        docomo: 'inputIpetPatientDocomo'
      }
      const key = inputKeys[insurance] ?? ''
      return key
    },
    async createData() {
      const sortedVaccines = [...this.newPatientVaccines].sort(
        (a, b) => a.vaccineId - b.vaccineId
      )
      const vaccineIdOrder = sortedVaccines.map(v => v.vaccineId)
      const sortedVaccinePeriods = [...this.newPatientVaccinePeriodGroups]
        .filter(period => period.length)
        .sort((a, b) => a[0]?.vaccineId - b[0]?.vaccineId)
      const formattedVaccinePeriods = vaccineIdOrder.map(
        id =>
          sortedVaccinePeriods.find(period => period[0].vaccineId === id) || []
      )
      this.waitFlg = true
      let sendData = _.cloneDeep(this.inputPatient)
      sendData.deathFlg = sendData.deathFlg ? 1 : 0
      const result = this.reservationFlg
        ? await this.$store.dispatch('patients/create', {
            patient: sendData,
            anicomPatient: this.getInputByCategory('anicom'),
            ipetPatient: this.getInputByCategory('ipet'),
            patientVaccines: sortedVaccines,
            patientVaccinePeriodGroups: formattedVaccinePeriods,
            reservation: this.$store.getters['reservations/getDataById'](
              this.$route.params.reservation.id
            ),
            mailFlgs: {
              new: true,
              change: true,
              cancel: true
            }
          })
        : await this.$store.dispatch('patients/create', {
            patient: sendData,
            anicomPatient: this.getInputByCategory('anicom'),
            ipetPatient: this.getInputByCategory('ipet'),
            patientVaccines: sortedVaccines,
            patientVaccinePeriodGroups: formattedVaccinePeriods
          })
      if (result === true) {
        this.type = 'success'
        this.title = '完了'
        this.popupMessage = this.reservationFlg
          ? '患者の登録と予約の変更をしました。予約作成画面に戻ります。'
          : '新規登録しました'
        this.mixinSetInitialData()
      } else {
        this.title = '失敗'
        this.type = 'failure'
        this.popupMessage =
          result === 'no owner'
            ? '既に飼主データが削除されている為、登録に失敗しました'
            : result === 'used showId'
            ? `${'登録に失敗しました。患者IDは同じIDがすでに存在します。'}\n` +
              `${'自動ID割振り機能を使用して登録に失敗した場合は、他のユーザーが同じIDで先に登録していた可能性がございます。'}\n` +
              `${'再度、自動ID割振りボタンをクリックしご確認をお願いします。'}\n`
            : result === 'used mcCode'
            ? `編集に失敗しました。\n同じMC識別番号がすでに存在します。`
            : result === 'no reservationColumn'
            ? '他のユーザーが予約列データを削除した為、予約の変更と患者の登録に失敗しました'
            : result === 'no reservationPurpose'
            ? '他のユーザーが来院目的データを削除した為、予約の変更と患者の登録に失敗しました'
            : result === 'no staff'
            ? '他のユーザーがスタッフデータを削除した為、予約の変更と患者の登録に失敗しました'
            : result === 'no reservation' || result === 'no data in clinic'
            ? '編集中の予約が削除またはキャンセルされている為、\n予約の変更と患者の登録に失敗しました'
            : this.$route.params.reservation
            ? '患者の登録と予約の変更に失敗しました'
            : '登録に失敗しました'
        if (result === 'no reservation' || result === 'no data in clinic') {
          this.reservationFlg = false
          this.$store.dispatch('timeTable/clearReservationId')
        }
      }
      this.waitFlg = false
      this.alertFlg = true
    },
    getInputByCategory(category) {
      const { insurance } = this.inputPatient
      const categories = { anicom: 'anicom', ipet: 'ipet', docomo: 'ipet' }
      const currentCategory = categories[insurance]
      const inputKey =
        category === currentCategory ? this.getCurrentInputKey() : ''
      const input = this[inputKey] ?? null
      return input
    },
    openListPage() {
      this.alertFlg = false
      if (this.type === 'success' && this.reservationFlg) {
        this.$store.dispatch('petorelu/okLeave')
        this.$store.dispatch('timeTable/showPopup')
        const initialReservation = this.$store.getters[
          'reservations/getDataById'
        ](this.$route.params.reservation.id)
        this.$store.dispatch('timeTable/setInitialReservation', {
          ...initialReservation
        })
        this.$store.dispatch(
          'timeTable/setMoveEndTimeOfReservation',
          this.$route.params.moveEndTimeObj
        )
        this.$router.push({
          name: 'main',
          params: { scrollInfo: this.$route.params.scrollInfo }
        })
      } else if (this.type === 'success') {
        this.$store.dispatch('petorelu/okLeave')
        this.$router.push({ path: `/main/owners/${this.ownerId}` })
      }
    },
    openEditVaccinePopup(patientVaccine) {
      this.editPatientVaccine = patientVaccine
      this.editPatientVaccinePeriods =
        this.newPatientVaccinePeriodGroups.find(
          group =>
            group.length !== 0 &&
            group[0].vaccineId === patientVaccine.vaccineId
        ) || []
      this.vaccinePopupFlg = true
    },
    addPatientVaccine({ patientVaccine, patientVaccinePeriods }) {
      this.newPatientVaccines.push({
        ...patientVaccine,
        key: this.patientVaccineKey++
      })
      if (patientVaccinePeriods.length)
        this.newPatientVaccinePeriodGroups.push(patientVaccinePeriods)
    },
    updatePatientVaccine({ patientVaccine, patientVaccinePeriods }) {
      const vaccineIndex = this.newPatientVaccines.findIndex(
        v => v.key === patientVaccine.key
      )
      if (patientVaccine.delFlg) {
        this.newPatientVaccines.splice(vaccineIndex, 1)
      } else {
        this.newPatientVaccines.splice(vaccineIndex, 1, patientVaccine)
      }
      const periodIndex = this.newPatientVaccinePeriodGroups
        .map((arr, i) => {
          if (arr.length) {
            if (arr[0].vaccineId === patientVaccine.vaccineId) {
              return i
            }
          }
          return -1
        })
        .find(val => val !== -1)
      if (
        this.newPatientVaccinePeriodGroups[periodIndex] &&
        (patientVaccine.delFlg || !patientVaccinePeriods.length)
      ) {
        this.newPatientVaccinePeriodGroups.splice(periodIndex, 1)
      } else if (patientVaccinePeriods.length) {
        this.newPatientVaccinePeriodGroups.splice(
          periodIndex ?? this.newPatientVaccinePeriodGroups.length,
          1,
          patientVaccinePeriods
        )
      }
    },
    closeVaccinePopup() {
      this.vaccinePopupFlg = false
      this.editPatientVaccine = undefined
      this.editPatientVaccinePeriods = []
    },
    setUploadingImg(uploadingImgState) {
      this.uploadingImg = uploadingImgState
    }
  }
}
</script>

<style lang="scss" scoped>
.patient-new {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-sizing: border-box;
  width: 100%;
  padding: 25px 48px;
  text-align: left;
  > .observer {
    > .patient-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 880px;
      > .setting-title {
        font-size: 20px;
        font-weight: bold;
        border-bottom: 1px solid #{$light-grey};
        padding-bottom: 20px;
      }
      > .content-title {
        font-size: 15px;
        font-weight: bold;
        border-bottom: 1px solid #{$light-grey};
        padding-bottom: 20px;
        margin-top: 22px;
      }
      > .content {
        margin-top: 26px;
        display: flex;
        > .input-form {
          margin-left: 30px;
        }
      }
    }
    > .button-area {
      display: flex;
      justify-content: center;
      margin-top: 52px;
      > .button {
        margin: 0 16px;
      }
    }
  }
}
</style>
