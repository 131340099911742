<template>
  <div class="patient-show-content" data-e2e="patient-show-content">
    <div class="title-wrapper">
      <div class="title">
        患者ID {{ patient.showId }}<span class="right">基本情報</span>
      </div>
      <div class="button-area">
        <base-button-small-white
          v-if="this.defaultMicrochipPatient"
          :styles="{ width: '160px', marginLeft: '20px' }"
          @click="handleMicrochipButtonClick"
          data-test="microchip-popup-button"
          >MC装着証明書印刷</base-button-small-white
        >

        <base-button-small-white
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="openPatientEdit"
          >編集</base-button-small-white
        >
        <base-button-small-red
          class="button"
          v-if="lookOnlyFlg === 0"
          @click="openDeletePopup"
          >削除</base-button-small-red
        >
      </div>
    </div>
    <div class="content-wrapper">
      <div class="columns-wrapper">
        <div class="left-column">
          <div class="image">
            <base-no-photo v-if="!patient.image" />
            <img
              v-else
              :src="patient.image"
              width="110px"
              height="110px"
              alt="patient-image"
            />
          </div>
          <div class="under-image-buttons">
            <base-button-border-orange
              class="under-image-button"
              :styles="{ width: '130px' }"
              @click="makePatientReservation"
              data-test="reservation-button"
            >
              予約
            </base-button-border-orange>
            <base-button-border-orange
              v-if="karteFlg === 1"
              class="under-image-button"
              :styles="{ width: '130px' }"
              @click="openMedicalRecord"
              ref="medicalRecordButton"
            >
              カルテ
            </base-button-border-orange>
            <base-button-border-orange
              v-if="karteFlg === 1"
              class="under-image-button"
              :styles="{ width: '130px' }"
              @click="openEstimates"
              ref="estimateButton"
            >
              見積書
            </base-button-border-orange>
            <base-button-border-orange
              v-if="karteFlg === 1"
              class="under-image-button"
              :styles="{ width: '130px' }"
              @click="openPayments"
              data-test="payment-button"
            >
              会計
            </base-button-border-orange>
          </div>
        </div>
        <div class="right-column">
          <show-data-list
            :displayKeys="displayKeys"
            :keys="keys"
            :data="displayPatient"
          />
          <div v-if="patient.insurance === 'anicom'" class="row anicom">
            <div class="key space"></div>
            <div class="value">
              <show-data-list
                :displayKeys="displayAnicomKeys"
                :keys="anicomKeys"
                :data="displayAnicomPatient"
              />
            </div>
          </div>
          <div
            v-if="
              patient.insurance === 'ipet' || patient.insurance === 'docomo'
            "
            class="row ipet"
          >
            <div class="key space"></div>
            <div class="value">
              <show-data-list
                :displayKeys="displayIpetKeys"
                :keys="ipetKeys"
                :data="displayIpetPatient"
              />
            </div>
          </div>
          <div class="row vaccinations" v-if="karteFlg === 1">
            <div class="key">
              予防接種
              <base-button-plus
                v-if="lookOnlyFlg === 0"
                class="add-button"
                :styles="{ width: '100px' }"
                :text="'追加'"
                @click="vaccinePopupFlg = true"
              />
            </div>
            <div class="value">
              <list-table
                class="list"
                v-if="displayVaccinations.length !== 0"
                :showUpperFrame="false"
                :headerData="vaccinationTable.headers"
                :propertyData="vaccinationTable.properties"
                :bodyData="displayVaccinations"
                :headerItemStyleData="vaccinationTable.itemStyles"
                :bodyItemStyleData="vaccinationTable.itemStyles"
                :directOrderFlg="true"
                @click="openEditVaccinePopup"
                data-test="vaccination-table"
              />
            </div>
          </div>
          <show-data-list
            :displayKeys="['備考']"
            :keys="['note']"
            :data="displayPatient"
          />
        </div>
      </div>
      <div class="table-wrapper" v-if="karteFlg === 1">
        <base-button-small-white
          class="button"
          @click="openGracePopup"
          data-test="print-grace-button"
          >猶予証明書</base-button-small-white
        >
        <div class="title" :style="{ marginTop: '10px' }">
          予防接種履歴
        </div>
        <div class="history">
          <list-table
            class="list"
            v-if="displayVaccinationHistory.length !== 0"
            :headerData="vaccinationHistoryTable.headers"
            :propertyData="vaccinationHistoryTable.properties"
            :bodyData="displayVaccinationHistory"
            :headerItemStyleData="vaccinationHistoryTable.itemStyles"
            :bodyItemStyleData="vaccinationHistoryTable.itemStyles"
            :directOrderFlg="true"
            :addButtonText="lookOnlyFlg === 0 ? '履歴追加' : ''"
            :addButtonDisabled="patientVaccines.length === 0"
            @add="antibodyPopupFlg = true"
            @click="openEditAntibodyPopup"
            data-test="vaccination-history"
          />
          <div v-else class="no-data">
            <div class="number">0件</div>
            <base-button-plus
              v-if="lookOnlyFlg === 0"
              class="add-button"
              :styles="{ width: '130px' }"
              :text="'履歴追加'"
              :disabled="patientVaccines.length === 0"
              @click="antibodyPopupFlg = true"
              data-test="add-vaccination-history"
            />
          </div>
        </div>
      </div>
      <div class="table-wrapper">
        <div class="title">予約履歴</div>
        <div class="history">
          <list-table
            class="list"
            v-if="displayPatientReservations.length >= 1"
            :headerData="reservationHistoryTable.headers"
            :propertyData="reservationHistoryTable.properties"
            :bodyData="displayPatientReservations"
            :headerItemStyleData="reservationHistoryTable.itemStyles"
            :bodyItemStyleData="displayPatientReservations.itemStyles"
            :directOrderFlg="true"
            :cancelNum="cancelNum"
            @click="openReservation"
          />
          <div class="no-data" v-else>0件</div>
        </div>
      </div>
    </div>
    <vaccine-popup
      v-if="vaccinePopupFlg"
      :apiFlg="true"
      :patientId="id"
      :patientVaccine="editPatientVaccine"
      :patientVaccines="patientVaccines"
      :patientVaccinePeriods="editPatientVaccinePeriods"
      @close="closeVaccinePopup"
    />
    <antibody-popup
      v-if="antibodyPopupFlg"
      :patientVaccines="patientVaccines"
      :antibody="editAntibody"
      :antibodyTypes="editAntibodyTypes"
      @close="closeAntibodiesPopup"
    />
    <microchip-certificate-popup
      v-if="showPdfPopup"
      :defaultMicrochipData="defaultMicrochipPatient"
      :patientId="id"
      @close="closeMicrochipPopup"
    />
    <grace-popup
      v-if="gracePopupFlg"
      :patientId="id"
      :grace="editGrace"
      @close="closeGracePopup"
    />
  </div>
</template>
<script>
import BaseButtonBorderOrange from '@/components/parts/atoms/BaseButtonBorderOrange'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import BaseButtonPlus from '@/components/parts/atoms/BaseButtonPlus'
import BaseNoPhoto from '@/components/parts/atoms/BaseNoPhoto'
import ShowDataList from '@/components/parts/organisms/ShowDataList'
import ListTable from '@/components/parts/organisms/ListTable'
import AntibodyPopup from '@/components/popups/AntibodyPopup'
import VaccinePopup from '@/components/popups/VaccinePopup'
import MicrochipCertificatePopup from '@/components/popups/MicrochipCertificatePopup.vue'
import GracePopup from '@/components/popups/GracePopup'
import {
  computePreviousDate,
  computeNextVaccinePeriod,
  antibodyTypesEnToJp
} from '@/utils/vaccine_utils'
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'PatientShowContent',

  components: {
    BaseButtonBorderOrange,
    BaseButtonSmallWhite,
    BaseButtonSmallRed,
    BaseButtonPlus,
    BaseNoPhoto,
    ListTable,
    ShowDataList,
    AntibodyPopup,
    VaccinePopup,
    MicrochipCertificatePopup,
    GracePopup
  },

  props: {
    id: { type: Number }
  },

  data() {
    return {
      displayKeys: [
        '患者ID',
        'MC識別番号',
        'MC装着日',
        '患者名（カナ）',
        '性別',
        '種別',
        '品種',
        '毛色',
        '生年月日（年齢）',
        '主治医',
        '保険'
      ],
      keys: [
        'showId',
        'mcCode',
        'mcDate',
        'name',
        'sex',
        'species',
        'breed',
        'hairColor',
        'birthAge',
        'staff',
        'insurance'
      ],
      displayAnicomKeys: ['証券番号', '商品名', '窓口精算期間', '支払割合'],
      anicomKeys: ['cId', 'planName', 'period', 'coverageRate'],
      displayIpetKeys: [
        '商品名',
        '補償割合',
        '証券番号',
        '保険期間',
        '初年度契約日'
      ],
      ipetKeys: [
        'planNm',
        'claimRate',
        'policyNo',
        'period',
        'firstContractDate'
      ],
      vaccinationTable: {
        headers: [
          '接種名',
          '前回の接種/検査日',
          '次回の接種日（予定）',
          'アラート'
        ],
        properties: ['name', 'previousDate', 'nextDate', 'alert'],
        itemStyles: [
          { textAlign: 'center', minWidth: '120px' },
          { textAlign: 'center', minWidth: '140px' },
          { textAlign: 'center', minWidth: '160px' },
          { textAlign: 'center', minWidth: '80px' }
        ],
        itemStylesRed: [
          { textAlign: 'center', minWidth: '120px' },
          { textAlign: 'center', minWidth: '140px' },
          { textAlign: 'center', minWidth: '160px', color: '#de3d3d' },
          { textAlign: 'center', minWidth: '80px' }
        ]
      },
      vaccinationHistoryTable: {
        headers: ['接種/検査日', '接種/抗体検査情報'],
        properties: ['date', 'content'],
        itemStyles: [{ width: '200px', minWidth: '200px' }]
      },
      reservationHistoryTable: {
        headers: ['予約日', '予約時間', 'スタッフ名', '診察内容'],
        properties: ['date', 'time', 'staffName', 'care'],
        itemStyles: [
          { width: '200px', minWidth: '200px' },
          { width: '150px', minWidth: '150px' },
          { width: '150px', minWidth: '150px' },
          { textAlign: 'left' }
        ]
      },
      vaccinePopupFlg: false,
      editPatientVaccine: undefined,
      editPatientVaccinePeriods: [],
      antibodyPopupFlg: false,
      editAntibody: undefined,
      editAntibodyTypes: undefined,
      showPdfPopup: false,
      gracePopupFlg: false,
      editGrace: undefined
    }
  },

  computed: {
    ...mapGetters({
      getAntibodyById: 'antibodies/getDataById',
      antibodiesByPatientId: 'antibodies/getDataByPatientId',
      antibodyTypesByPatientId: 'antibodyTypes/getDataByPatientId',
      gracesByPatientId: 'graces/getDataByPatientId',
      lookOnlyFlg: 'auth/lookOnlyFlg',
      karteFlg: 'auth/karteFlg',
      getMasterDatum: 'master/getDataById',
      patientVaccinePeriodsByPatientId:
        'patientVaccinePeriods/getDataByPatientId',
      patientVaccinesByPatientId: 'patientVaccines/getDataByPatientId'
    }),
    patient() {
      return this.$store.getters['patients/getDataById'](this.id)
    },
    displayPatient() {
      const patient = this.patient
      if (patient) {
        const name =
          patient.nameKana !== ''
            ? `${patient.name} （${patient.nameKana}）`
            : `${patient.name}`
        const patientSexes = this.$store.getters[
          'master/selectPatientSexesZero'
        ]
        const species = this.$store.getters['species/getDataById'](
          patient.speciesId
        )
        const staff = this.$store.getters['staffs/getDataById'](patient.staffId)
        const insuranceConvertor = {
          '': '未設定',
          anicom: 'アニコム',
          ipet: 'アイペット',
          docomo: 'ドコモ'
        }
        return {
          showId: patient.showId,
          mcCode: patient.mcCode,
          mcDate: patient.mcDate
            ? moment(patient.mcDate).format('Y年M月D日')
            : '',
          name,
          sex: patientSexes.find(v => v.id === patient.sex).name,
          species: species ? species.name : '',
          breed: patient.breed,
          hairColor: patient.hairColor,
          birthAge: this.makeBirthAge(patient),
          staff: staff ? `${staff.lastName} ${staff.firstName}` : '',
          note: patient.note,
          redTextData: patient.deathFlg ? { birthAge: '死亡' } : {},
          insurance: insuranceConvertor[patient.insurance]
        }
      } else {
        return {}
      }
    },
    displayAnicomPatient() {
      const { insurance } = this.patient
      let displayAnicomPatient
      if (insurance === 'anicom') {
        const anicomPatient = this.$store.getters[
          'anicomPatients/getAnicomPatient'
        ](this.id)
        displayAnicomPatient = {
          cId: anicomPatient?.cId || '',
          planName: anicomPatient?.planName || '',
          period: this.toPeriod(anicomPatient),
          coverageRate: this.toRate(anicomPatient, 'coverageRate')
        }
      }
      return displayAnicomPatient
    },
    displayIpetPatient() {
      const { insurance } = this.patient
      let displayIpetPatient
      if (insurance === 'ipet' || insurance === 'docomo') {
        const ipetPatient = this.$store.getters['ipetPatients/getIpetPatient'](
          this.id
        )
        displayIpetPatient = {
          planNm: ipetPatient?.planNm || '',
          claimRate: this.toRate(ipetPatient, 'claimRate'),
          policyNo: ipetPatient?.policyNo || '',
          period: this.toPeriod(ipetPatient),
          firstContractDate: this.formatDate(
            ipetPatient?.firstContractDate || ''
          )
        }
      }
      return displayIpetPatient
    },
    defaultMicrochipPatient() {
      if (this.patient.mcCode) {
        const clinic = this.$store.getters['clinic/getData']
        const {
          postalCode,
          prefectureId,
          address,
          building,
          name: clinicName,
          tel
        } = clinic
        const {
          mcCode,
          name: patientName,
          breed,
          hairColor,
          birthday,
          mcDate
        } = this.patient
        return {
          currentDate: moment().format('Y年M月D日'),
          mcCode,
          patientName,
          species:
            this.displayPatient.species === '犬'
              ? 1
              : this.displayPatient.species === '猫'
              ? 2
              : '',
          breed,
          hairColor,
          birthday: birthday ? moment(birthday).format('YYYYMMDD') : '',
          sex:
            this.displayPatient.sex === '♂'
              ? 1
              : this.displayPatient.sex === '♀'
              ? 2
              : this.displayPatient.sex === 'C'
              ? 1
              : this.displayPatient.sex === 'S'
              ? 2
              : '',
          additional: '',
          chipDate: mcDate || '',
          postalCode,
          prefecture: prefectureId || 0,
          address,
          building,
          clinicName,
          tel,
          staffName: ''
        }
      }
      return ''
    },
    patientReservations() {
      return this.$store.getters['reservations/getPatientReservations'](
        this.patient.id
      )
    },
    displayPatientReservations() {
      const patientReservations = this.patientReservations.slice()
      return patientReservations
        .sort((a, b) => {
          return a.date > b.date ? -1 : 1
        })
        .sort((a, b) => {
          if (a.date === b.date) return a.startTime > b.startTime ? -1 : 1
        })
        .sort((a, b) => {
          if (a.date === b.date && a.startTime === b.startTime)
            return a.endTime > b.endTime ? -1 : 1
        })
        .map(r => {
          const staff = this.$store.getters['staffs/getDataById'](r.staffId)
          const time = r.startTime
          r = {
            id: r.id,
            date: this.formatDate(r.date, true),
            time: `${parseInt(time.slice(0, 2))}:${time.slice(2, 4)} `,
            staffName: staff ? `${staff.lastName} ${staff.firstName}` : '',
            care: r.care,
            cancelFlg: r.cancelFlg,
            itemStyles: this.reservationHistoryTable.itemStyles
          }
          if (r.cancelFlg === 1) {
            r.care = '※この予約はキャンセルされました'
            r.itemStyles = this.reservationHistoryTable.itemStyles.map(v => {
              return { ...v, color: '#de3d3d' }
            })
          }
          return r
        })
    },
    cancelNum() {
      return this.patientReservations.filter(r => r.cancelFlg === 1).length
    },
    patientVaccines() {
      return this.patientVaccinesByPatientId(this.patient.id) || []
    },
    patientAntibodies() {
      return this.antibodiesByPatientId(this.patient.id) || []
    },
    patientAntibodyTypes() {
      return this.antibodyTypesByPatientId(this.patient.id) || []
    },
    patientGraces() {
      return this.gracesByPatientId(this.patient.id) || []
    },
    patientVaccinePeriods() {
      return this.patientVaccinePeriodsByPatientId(this.patient.id) || []
    },
    displayVaccinations() {
      return this.patientVaccines.map(patientVaccine => {
        const vaccine = this.getMasterDatum(
          'vaccines',
          patientVaccine.vaccineId
        )
        const previousDate = computePreviousDate({
          patientAntibodyTypes: this.patientAntibodyTypes,
          vaccineId: vaccine.id,
          getAntibodyById: this.getAntibodyById
        })
        const { hasImmunity, nextPeriod } = computeNextVaccinePeriod(
          this.patientVaccinePeriods,
          previousDate,
          patientVaccine
        )
        const nextDate = nextPeriod
          ? nextPeriod.endDate
            ? this.formatDate(nextPeriod.startDate) +
              ' ~ ' +
              this.formatDate(nextPeriod.endDate)
            : this.formatDate(nextPeriod.startDate)
          : ''
        const beforeOrAfter = patientVaccine.alertBeforeFlg === 1 ? '前' : '後'
        const alertText = patientVaccine.alertFlg
          ? patientVaccine.alertBeforeDay + '日' + beforeOrAfter
          : ''
        return {
          id: patientVaccine.id,
          name: vaccine.name,
          previousDate: previousDate ? this.formatDate(previousDate) : '',
          nextDate,
          alert: alertText,
          itemStyles: hasImmunity
            ? this.vaccinationTable.itemStyles
            : this.vaccinationTable.itemStylesRed
        }
      })
    },
    displayVaccinationHistory() {
      const antibodies = [...this.patientAntibodies]
      return antibodies
        .sort((a, b) => b.date - a.date)
        .map(antibody => {
          const antibodyTypes = this.patientAntibodyTypes.filter(
            v => v.antibodyId === antibody.id
          )
          let content = ''
          antibodyTypes.forEach(antibodyType => {
            const vaccine = this.getMasterDatum(
              'vaccines',
              antibodyType.vaccineId
            )
            if (antibodyType.type !== '')
              content += `${vaccine.name}（${
                antibodyTypesEnToJp[antibodyType.type]
              }）、`
          })
          return {
            id: antibody.id,
            date: this.formatDate(antibody.date, true),
            content: content.slice(0, -1)
          }
        })
    }
  },

  methods: {
    openEstimates() {
      this.$router.push({
        path: `/main/karte/owners/${this.patient.ownerId}/patients/${this.id}/estimates`
      })
    },
    openMedicalRecord() {
      this.$router.push({
        path: `/main/karte/owners/${this.patient.ownerId}/patients/${this.id}/medical-records/new`
      })
    },
    openPayments() {
      this.$router.push({
        path: `/main/karte/owners/${this.patient.ownerId}/patients/${this.id}/payments`
      })
    },
    calcAge(patient) {
      const latestDate = patient.deathDate
        ? moment(patient.deathDate)
        : moment()
      if (patient.birthday.length === 8 || patient.birthday.length === 6) {
        const y =
          latestDate.diff(moment(patient.birthday), 'years') > 0
            ? latestDate.diff(moment(patient.birthday), 'years')
            : 0
        const m =
          latestDate.diff(moment(patient.birthday), 'month') % 12 > 0
            ? latestDate.diff(moment(patient.birthday), 'month') % 12
            : 0
        return patient.birthday.length === 8
          ? y + '歳' + m + 'ヶ月'
          : '推定' + y + '歳' + m + 'ヶ月'
      } else if (patient.birthday.length === 4) {
        const y = latestDate.year() - moment(patient.birthday).year()
        return y >= 0 ? '推定' + y + '歳' : '推定0歳'
      } else {
        return ''
      }
    },
    makeBirthAge(patient) {
      const birthdayText =
        patient.birthday.length === 8
          ? moment(patient.birthday).format('Y年M月D日')
          : patient.birthday.length === 6
          ? moment(patient.birthday).format('Y年M月')
          : patient.birthday.length === 4
          ? moment(patient.birthday).format('Y年')
          : ''
      if (patient.deathFlg === 1) {
        if (patient.birthday && patient.deathDate) {
          return birthdayText + `（${this.calcAge(patient)}）`
        } else if (patient.birthday) {
          return birthdayText + ' '
        } else {
          return ''
        }
      } else {
        return patient.birthday
          ? birthdayText + `（${this.calcAge(patient)}）`
          : ''
      }
    },
    formatDate(date, dayFlg = false) {
      return date === ''
        ? ''
        : dayFlg
        ? moment(date, 'YYYYMMDD').format('Y年M月D日（dd）')
        : moment(date, 'YYYYMMDD').format('Y年M月D日')
    },
    toPeriod(obj) {
      const startDate = obj?.startDate || ''
      const endDate = obj?.endDate || ''
      return `${this.formatDate(startDate)} ～ ${this.formatDate(endDate)}`
    },
    toRate(obj, key) {
      if (!obj) return '未取得'
      const rate = obj[key] === 0 ? '未取得' : `${obj[key]}%`
      return rate
    },
    openPatientEdit() {
      this.$emit('open-patient-edit', this.id)
    },
    openDeletePopup() {
      this.$emit('open-delete-popup', this.id)
    },
    openReservation(id) {
      this.$emit('open-reservation', id)
    },
    makePatientReservation() {
      const diffMinutes = this.$store.getters['reservationSetting/getData']
        .reservationTimeUnit
      this.$store.dispatch('timeTable/setInitialReservation', {
        id: 0,
        ownerId: this.patient.ownerId,
        patientId: this.id,
        startTime:
          moment()
            .add(1, 'h')
            .format('HH') + '00',
        endTime:
          moment()
            .add(1, 'h')
            .format('HH') + `${diffMinutes}`,
        ownerPatientPageFlg: true
      })
      this.$router.push({ name: 'main', params: { fromPage: 'patient' } })
    },
    openEditVaccinePopup(patientVaccineId) {
      this.editPatientVaccine = this.patientVaccines.find(
        v => v.id === patientVaccineId
      )
      this.editPatientVaccinePeriods = this.patientVaccinePeriods.filter(
        v => v.vaccineId === this.editPatientVaccine.vaccineId
      )
      this.vaccinePopupFlg = true
    },
    closeVaccinePopup() {
      this.vaccinePopupFlg = false
      this.editPatientVaccine = undefined
      this.editPatientVaccinePeriods = []
    },
    openEditAntibodyPopup(antibodyId) {
      this.editAntibody = this.patientAntibodies.find(v => v.id === antibodyId)
      this.editAntibodyTypes = this.patientAntibodyTypes.filter(
        v => v.antibodyId === antibodyId
      )
      this.antibodyPopupFlg = true
    },
    closeAntibodiesPopup() {
      this.antibodyPopupFlg = false
      this.editAntibody = undefined
      this.editAntibodyTypes = undefined
    },
    closeMicrochipPopup() {
      this.showPdfPopup = false
      this.$emit('set-navigation-popup-type', 'default')
    },
    handleMicrochipButtonClick() {
      this.showPdfPopup = true
      this.$emit('set-navigation-popup-type', 'print')
    },
    openGracePopup() {
      this.editGrace = this.patientGraces
        .sort((a, b) => b.id - a.id)
        .find(v => true)
      this.gracePopupFlg = true
    },
    closeGracePopup() {
      this.gracePopupFlg = false
      this.editGrace = undefined
    }
  }
}
</script>
<style lang="scss" scoped>
.patient-show-content {
  width: 100%;
  > .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 33px;
    width: 100%;
    border-bottom: 1px solid #{$light-grey};
    padding-bottom: 15px;
    > .title {
      font-size: 15px;
      > .right {
        margin-left: 20px;
      }
    }
    > .button-area {
      display: flex;
      > .button {
        margin-left: 20px;
      }
    }
  }
  > .content-wrapper {
    width: 100%;
    margin-top: 30px;
    > .columns-wrapper {
      width: 100%;
      display: flex;
      > .left-column {
        display: flex;
        flex-direction: column;
        width: 170px;
        min-width: 170px;
        align-items: center;
        > .image {
          height: 110px;
          width: 110px;
          > img {
            width: 110px;
            height: 110px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
        > .under-image-buttons {
          margin-top: 10px;
          > .under-image-button {
            margin-top: 10px;
          }
        }
      }
      > .right-column {
        flex: 1;
        > .row {
          display: flex;
          width: 100%;
          margin-top: 25px;
          > .key {
            width: 240px;
            font-size: 15px;
          }
          > .value {
            width: calc(100% - 240px);
            font-size: 13px;
          }
          &.anicom,
          &.ipet {
            > .value {
              min-width: 360px;
              ::v-deep
                .show-data-list
                .show-data-list-row
                .show-data-list-row-key {
                width: 100px;
                font-size: 13px;
              }
            }
          }
          &.vaccinations {
            margin-bottom: 25px;
            > .key {
              height: 33px;
              display: flex;
              align-items: center;
              > .add-button {
                margin-left: 20px;
              }
            }
          }
        }
      }
    }
    > .table-wrapper {
      margin-top: 20px;
      font-size: 15px;
      > .history {
        margin-top: 10px;
        > .no-data {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 64px;
          padding-left: 20px;
          box-sizing: border-box;
          border: solid 1px #{$light-grey};
          > .add-button {
            margin-right: 20px;
          }
        }
      }
    }
    ::v-deep .list {
      > .table-body {
        max-height: 168px;
      }
    }
  }
  .title {
    font-weight: bold;
  }
}

@media (max-width: 950px) {
  .patient-show-content {
    > .title-wrapper {
      > .title {
        display: flex;
        flex-direction: column;
        > .right {
          margin-left: 0;
        }
      }
    }
  }
}
</style>
