<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div class="antibody-popup" @keyup.esc.stop="handleCancel" tabindex="-1">
      <validation-observer class="validation-observer" v-slot="{ invalid }">
        <!-- カード2315 サポートログインで登録編集可能にするオプション
         本来の仕様はsupportFlgまたはlookOnlyFlgが１の場合、disabledFlgをtrueになった
        しばらくlookOnlyFlgが１の場合、disabledFlgをtrueになる仕様をキープする-->
        <popup
          :buttons="['閉じる', '登録']"
          :disabledFlg="
            invalid || waitFlg || noAntibodyTypes || lookOnlyFlg === 1
          "
          @cancel="handleCancel"
          @decision="handleDecision"
          @click-close-mark="handleCancel"
        >
          <template v-slot:content>
            <div class="content">
              <div class="header">
                <div class="title">接種/抗体検査情報</div>
                <base-button-small-red
                  v-if="lookOnlyFlg === 0 && antibody"
                  class="delete"
                  @click="openDeletePopup"
                  >削除</base-button-small-red
                >
              </div>
              <div v-if="patientName" class="row name">
                <div class="label">患者名</div>
                <div class="value">
                  <span>{{ patientName }}</span>
                </div>
              </div>
              <div class="row">
                <div class="label">接種/検査日</div>
                <base-date-picker
                  class="date-picker"
                  :clearFlg="false"
                  :fontSize="15"
                  :maxDate="maxDate"
                  :width="160"
                  :disabled="linkedWithKarte"
                  :popoverProps="{
                    ...(linkedWithKarte && { visibility: 'hidden' }),
                    ...popoverProps
                  }"
                  v-model="inputAntibody.date"
                />
              </div>
              <div class="row">
                <div class="label">担当者</div>
                <base-select-box
                  class="select-box"
                  :styles="{ width: '165px' }"
                  :selectData="staffSelectData"
                  :disabled="linkedWithKarte"
                  v-model="inputAntibody.staffId"
                />
              </div>
              <div class="patient-vaccines">
                <div
                  class="item"
                  v-for="(antibodyType, index) in inputAntibodyTypes"
                  :key="index"
                >
                  <div class="item-row">
                    <div class="vaccine-name">
                      {{ makeVaccineName(antibodyType.vaccineId) }}
                    </div>
                    <base-select-box
                      class="select-box"
                      :styles="{ width: '100px' }"
                      :selectData="antibodyTypeSelectData"
                      :disabled="linkedWithKarte"
                      v-model="antibodyType.typeId"
                    />
                  </div>
                  <div class="item-row" v-if="antibodyType.typeId === 1">
                    <div class="lot-no-label">ロット番号</div>
                    <text-input-form
                      :styles="{ width: '200px' }"
                      :headingStyle="{ width: '0' }"
                      :maxlength="30"
                      v-model="antibodyType.lotNo"
                    />
                  </div>
                </div>
              </div>
              <div class="memo">
                <div class="label">備考</div>
                <div class="input">
                  <validation-provider
                    class="provider"
                    :rules="{ notSurrogatePair: true }"
                    v-slot="{ errors }"
                  >
                    <base-multiple-lines-text-box
                      :styles="{ width: '100%', height: '100px' }"
                      v-model="inputAntibody.memo"
                    />
                    <div class="error">{{ errors[0] }}</div>
                  </validation-provider>
                </div>
              </div>
              <div class="karte-notice" v-if="linkedWithKarte">
                ※
                診療明細の処置に紐づいているため、ロット番号と備考のみ編集できます
              </div>
            </div>
          </template>
        </popup>
      </validation-observer>
      <announce-popup
        v-if="announcePopupFlg"
        v-bind="announcePopup"
        :disabled="waitFlg"
        @close="announcePopup.close"
        @cancel="announcePopup.cancel"
        @decision="announcePopup.decision"
        >{{ announcePopup.message }}</announce-popup
      >
    </div>
  </focus-trap>
</template>

<script>
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import BaseDatePicker from '@/components/parts/atoms/BaseDatePicker'
import BaseMultipleLinesTextBox from '@/components/parts/atoms/BaseMultipleLinesTextBox'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import Popup from '@/components/popups/Popup'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import { antibodyTypesEnToJp, antibodyTypesJpToEn } from '@/utils/vaccine_utils'
import { FocusTrap } from 'focus-trap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'AntibodyPopup',

  components: {
    BaseButtonSmallRed,
    BaseDatePicker,
    BaseMultipleLinesTextBox,
    BaseSelectBox,
    TextInputForm,
    Popup,
    AnnouncePopup,
    FocusTrap,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [CheckPopupInputDifference],

  props: {
    patientName: { type: String, default: '' },
    patientVaccines: { type: Array, required: true },
    antibody: { type: Object, default: undefined },
    antibodyTypes: { type: Array, default: undefined },
    popoverProps: { type: Object, default: () => {} }
  },

  data() {
    return {
      inputAntibody: {},
      inputAntibodyTypes: [],
      initialData: {},
      inputData: {},
      antibodyTypeSelectData: [
        { id: 0, name: '-' },
        { id: 1, name: '接種' },
        { id: 2, name: '抗体あり' },
        { id: 3, name: '抗体なし' },
        { id: 4, name: '抗体検査待' }
      ],
      announcePopupFlg: false,
      announcePopup: {
        title: '',
        buttons: ['閉じる'],
        type: '',
        message: '',
        layerNumber: 2,
        cancel: () => {},
        decision: () => {},
        close: () => {}
      },
      updatedFlg: false,
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      getMasterDatum: 'master/getDataById',
      getStaff: 'staffs/getDataById',
      staffSelectDataZero: 'staffs/selectDataZero'
    }),
    diffFlg() {
      return !_.isEqual(this.initialData, this.inputData)
    },
    noAntibodyTypes() {
      return this.inputAntibodyTypes.every(v => v.typeId === 0)
    },
    maxDate() {
      return moment().format('YYYYMMDD')
    },
    staffSelectData() {
      const staff = this.getStaff(this.antibody?.staffId)
      let staffSelectData
      if (staff?.delFlg === 1)
        staffSelectData = this.staffSelectDataZero.concat({
          id: staff.id,
          name: `${staff.lastName} ${staff.firstName}`,
          jobId: staff.jobId
        })
      else staffSelectData = this.staffSelectDataZero
      return staffSelectData
    },
    linkedWithKarte() {
      return this.inputAntibody.medicalPaymentId !== 0
    }
  },

  watch: {
    inputAntibodyTypes: {
      handler() {
        this.inputAntibodyTypes.map(v => {
          if (v.typeId !== 1) v.lotNo = ''
          return v
        })
      },
      deep: true
    }
  },

  created() {
    this.inputAntibody = this.antibody
      ? { ...this.antibody }
      : {
          patientId: this.patientVaccines[0].patientId,
          medicalPaymentId: 0,
          date: moment().format('YYYYMMDD'),
          staffId: 0,
          memo: ''
        }
    if (this.antibody) {
      // 編集する場合の入力データの設定
      // delFlg:0 の patientVaccines
      this.patientVaccines.forEach(patientVaccine => {
        const antibodyType = this.antibodyTypes?.find(
          v => v.vaccineId === patientVaccine.vaccineId
        ) || {
          patientId: patientVaccine.patientId,
          vaccineId: patientVaccine.vaccineId,
          type: '',
          lotNo: ''
        }
        const typeId = this.antibodyTypeSelectData.find(
          v => v.name === antibodyTypesEnToJp[antibodyType.type]
        ).id
        this.inputAntibodyTypes.push({ ...antibodyType, typeId })
      })
      // delFlg:1 の patientVaccines
      this.antibodyTypes.forEach(antibodyType => {
        if (
          antibodyType.type !== '' &&
          !this.patientVaccines.find(
            v => v.vaccineId === antibodyType.vaccineId
          )
        ) {
          const typeId = this.antibodyTypeSelectData.find(
            v => v.name === antibodyTypesEnToJp[antibodyType.type]
          ).id
          this.inputAntibodyTypes.push({ ...antibodyType, typeId })
        }
      })
    } else {
      // 新規登録する場合の入力データの設定
      this.inputAntibodyTypes = this.patientVaccines.map(v => {
        return {
          patientId: v.patientId,
          vaccineId: v.vaccineId,
          typeId: 0,
          lotNo: ''
        }
      })
    }
    // 差分確認用のデータ設定
    this.inputData = {
      antibody: this.inputAntibody,
      antibodyTypes: this.inputAntibodyTypes
    }
    this.initialData = _.cloneDeep(this.inputData)
  },

  methods: {
    makeVaccineName(vaccineId) {
      return this.getMasterDatum('vaccines', vaccineId).name
    },
    async handleDecision() {
      this.antibody ? await this.updateAntibody() : await this.createAntibody()
    },
    handleCancel() {
      if (this.diffFlg === true) {
        this.announcePopup.title = '確認'
        this.announcePopup.type = 'alert'
        this.announcePopup.buttons = ['キャンセル', '閉じる']
        this.announcePopup.message =
          '入力内容を保存せずに閉じようとしています。\nよろしいですか？'
        this.announcePopup.cancel = () => {
          this.announcePopupFlg = false
        }
        this.announcePopup.decision = () => {
          this.$store.dispatch('petorelu/okLeave')
          this.$emit('close')
        }
        this.announcePopupFlg = true
      } else {
        this.$emit('close')
      }
    },
    openDeletePopup() {
      this.announcePopup.type = 'alert'
      this.announcePopup.title = '注意'
      this.announcePopup.buttons = ['削除しない', '削除する']
      this.announcePopup.message = '削除してもよろしいですか？'
      this.announcePopup.cancel = () => {
        this.announcePopupFlg = false
      }
      this.announcePopup.decision = this.deleteAntibody
      this.announcePopupFlg = true
    },
    closeAnnouncePopup() {
      this.$store.dispatch('petorelu/okLeave')
      this.announcePopupFlg = false
      if (this.updatedFlg) {
        this.$emit('close')
        this.$emit('updated')
      }
    },
    async createAntibody() {
      const sendAntibodyTypes = this.inputAntibodyTypes.map(v => {
        const typeName = this.antibodyTypeSelectData.find(
          w => w.id === v.typeId
        ).name
        v.type = antibodyTypesJpToEn[typeName]
        if (v.typeId !== 1) v.lotNo = '' //もしtypeIdが1ではないなら、空文字にする処理
        return v
      })
      this.waitFlg = true
      const result = await this.$store.dispatch('antibodies/create', {
        antibody: this.inputAntibody,
        antibodyTypes: sendAntibodyTypes
      })
      if (result === true) {
        this.initialData = _.cloneDeep(this.inputData)
        this.announcePopup.type = 'success'
        this.announcePopup.title = '完了'
        this.announcePopup.message = '登録しました'
        this.updatedFlg = true
      } else {
        this.announcePopup.type = 'failure'
        this.announcePopup.title = '失敗'
        if (result === 'no patient') {
          this.announcePopup.message =
            '登録に失敗しました。\n対象の患者は既に削除されています。'
          this.updatedFlg = true
        } else {
          this.announcePopup.message = '登録に失敗しました'
        }
      }
      this.announcePopup.buttons = ['閉じる']
      this.announcePopup.close = this.closeAnnouncePopup
      this.announcePopupFlg = true
      this.waitFlg = false
    },
    async updateAntibody() {
      const sendAntibodyTypes = this.inputAntibodyTypes.map(v => {
        const typeName = this.antibodyTypeSelectData.find(
          w => w.id === v.typeId
        ).name
        v.type = antibodyTypesJpToEn[typeName]
        if (v.typeId !== 1) v.lotNo = '' //もしtypeIdが1ではないなら、空文字にする処理
        return v
      })
      this.waitFlg = true
      const result = await this.$store.dispatch('antibodies/update', {
        antibody: this.inputAntibody,
        antibodyTypes: sendAntibodyTypes
      })
      if (result === true) {
        this.initialData = _.cloneDeep(this.inputData)
        this.announcePopup.type = 'success'
        this.announcePopup.title = '完了'
        this.announcePopup.message = '編集しました'
        this.updatedFlg = true
      } else {
        this.announcePopup.type = 'failure'
        this.announcePopup.title = '失敗'
        if (result === 'no antibody' || result === 'no data in clinic') {
          this.announcePopup.message =
            '編集に失敗しました。\n編集中の接種/抗体検査情報は既に削除されています。'
          this.updatedFlg = true
        } else if (result === 'no patient') {
          this.announcePopup.message =
            '編集に失敗しました。\n対象の患者は既に削除されています。'
          this.updatedFlg = true
        } else {
          this.announcePopup.message = '編集に失敗しました'
        }
      }
      this.announcePopup.buttons = ['閉じる']
      this.announcePopup.close = this.closeAnnouncePopup
      this.announcePopupFlg = true
      this.waitFlg = false
    },
    async deleteAntibody() {
      if (this.inputAntibody.medicalPaymentId !== 0) {
        this.announcePopup.type = 'failure'
        this.announcePopup.title = '失敗'
        this.announcePopup.buttons = ['閉じる']
        this.announcePopup.message =
          '削除に失敗しました。\n診療明細の処置に紐づいているため削除できません。'
        this.announcePopup.close = this.closeAnnouncePopup
        this.announcePopupFlg = true
        return
      }
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'antibodies/delete',
        this.inputAntibody.id
      )
      if (result === true) {
        this.initialData = _.cloneDeep(this.inputData)
        this.announcePopup.type = 'success'
        this.announcePopup.title = '完了'
        this.announcePopup.message = '削除しました'
        this.updatedFlg = true
      } else {
        this.announcePopup.type = 'failure'
        this.announcePopup.title = '失敗'
        this.announcePopup.message = '削除に失敗しました'
        if (result === 'no data in clinic') this.updatedFlg = true
      }
      this.announcePopup.buttons = ['閉じる']
      this.announcePopup.close = this.closeAnnouncePopup
      this.announcePopupFlg = true
      this.waitFlg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.antibody-popup {
  position: absolute;
  top: 0;
  left: 0;
  .content {
    width: 640px;
    text-align: left;
    font-size: 15px;
    margin-bottom: 20px;
    padding: 0 5px 0;
    > .header {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      > .title {
        font-size: 16px;
        font-weight: bold;
      }
      > .delete {
        margin-left: auto;
      }
    }
    > .row {
      display: flex;
      align-items: center;
      margin-top: 20px;
      > .label {
        width: 140px;
      }
    }
    > .patient-vaccines {
      margin-top: 25px;
      padding: 0 15px;
      height: auto; //複数の場合、全部表示できるように
      max-height: 350px; //heightはautoになるため、max-heightでheightを制限
      overflow-y: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 30px;
      row-gap: 15px;
      > .item {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-content: space-between;
        row-gap: 5px;
        > .item-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          > .vaccine-name {
            margin-right: 10px;
          }
          > .select-box {
            width: 100px;
          }
          > .lot-no-label {
            margin-right: 10px;
            width: 80px;
          }
        }
      }
    }
    > .memo {
      margin-top: 20px;
      display: flex;
      width: 100%;
      > .label {
        width: 140px;
      }
      > .input {
        flex: 1;
        width: 0;
        @include validate-message();
      }
    }
    > .karte-notice {
      margin-top: 20px;
      display: flex;
      width: 100%;
      font-size: 13px;
    }
  }
}
.provider .error {
  // date-pickerと被らないようにするため
  z-index: 0;
}
</style>
