<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div class="grace-popup" @keyup.esc.stop="handleCancel" tabindex="-1">
      <validation-observer class="validation-observer" v-slot="{ invalid }">
        <popup
          :buttons="['閉じる', '印刷']"
          :disabledFlg="invalid || waitFlg"
          @cancel="handleCancel"
          @decision="handleDecision"
          @click-close-mark="handleCancel"
        >
          <template v-slot:content>
            <div class="content">
              <div class="header">
                <div class="title">猶予証明書</div>
              </div>
              <div class="row">
                <div class="label">鑑札No</div>
                <text-input-form
                  :headingStyle="{ width: '0' }"
                  :maxlength="25"
                  v-model="inputGrace.licenseNo"
                />
              </div>
              <div class="row">
                <div class="label">体格</div>
                <base-select-box
                  class="select-box"
                  :selectData="physiqueSelectData"
                  v-model="inputGrace.physique"
                />
              </div>
              <div class="row">
                <div class="label">Lot No</div>
                <text-input-form
                  :headingStyle="{ width: '0' }"
                  :maxlength="30"
                  v-model="inputGrace.lotNo"
                />
              </div>
              <div class="row">
                <div class="label">猶予期間</div>
                <base-date-picker
                  class="date-picker"
                  :clearFlg="false"
                  :fontSize="15"
                  :width="160"
                  v-model="inputGrace.startDate"
                />
                <div class="wavy-line">～</div>
                <base-date-picker
                  class="date-picker"
                  :clearFlg="false"
                  :fontSize="15"
                  :width="160"
                  v-model="inputGrace.endDate"
                />
              </div>
              <div class="memo">
                <div class="label">実施理由</div>
                <div class="input">
                  <validation-provider
                    class="provider"
                    :rules="{ notSurrogatePair: true }"
                    v-slot="{ errors }"
                  >
                    <base-multiple-lines-text-box
                      :styles="{ width: '100%', height: '100px' }"
                      v-model="inputGrace.reason"
                    />
                    <div class="error">{{ errors[0] }}</div>
                  </validation-provider>
                </div>
              </div>
              <div class="row">
                <div class="label">認定日</div>
                <base-date-picker
                  class="date-picker"
                  :clearFlg="false"
                  :fontSize="15"
                  :width="160"
                  v-model="inputGrace.date"
                />
              </div>
              <div class="row">
                <div class="label">実施者氏名</div>
                <base-select-box
                  class="select-box"
                  :selectData="staffSelectData"
                  v-model="inputGrace.staffId"
                />
              </div>
            </div>
          </template>
        </popup>
      </validation-observer>
    </div>
  </focus-trap>
</template>

<script>
import { printGrace } from '@/utils/print_grace'
import BaseDatePicker from '@/components/parts/atoms/BaseDatePicker'
import BaseMultipleLinesTextBox from '@/components/parts/atoms/BaseMultipleLinesTextBox'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import Popup from '@/components/popups/Popup'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import { FocusTrap } from 'focus-trap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'GracePopup',

  components: {
    BaseDatePicker,
    BaseMultipleLinesTextBox,
    BaseSelectBox,
    TextInputForm,
    Popup,
    FocusTrap,
    ValidationObserver,
    ValidationProvider
  },

  mixins: [CheckPopupInputDifference],

  props: {
    patientId: { type: Number, required: true },
    grace: { type: Object, default: undefined }
  },

  data() {
    return {
      initialData: {},
      inputData: {},
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      clinic: 'clinic/getData',
      getOwner: 'owners/getDataById',
      getPatient: 'patients/getDataById',
      patientSexes: 'master/selectPatientSexesZero',
      prefectures: 'master/selectPrefecturesZero',
      staffSelectDataZero: 'staffs/selectDataZero'
    }),
    physiqueSelectData() {
      return [
        { id: 1, name: '' },
        { id: 2, name: '小型' },
        { id: 3, name: '中型' },
        { id: 4, name: '大型' }
      ]
    },
    staffSelectData() {
      return this.staffSelectDataZero
    }
  },

  created() {
    const converter = v => {
      switch (v) {
        case '':
          return 1
        case 'small':
          return 2
        case 'medium':
          return 3
        case 'big':
          return 4
        default:
          return v
      }
    }
    this.inputGrace = this.grace
      ? {
          startDate: this.grace.startDate,
          endDate: this.grace.endDate,
          date: this.grace.date,
          physique: converter(this.grace.physique),
          staffId: this.grace.staffId,
          reason: this.grace.reason,
          lotNo: this.grace.lotNo,
          licenseNo: this.grace.licenseNo,
          patientId: this.grace.patientId
        }
      : {
          startDate: moment().format('YYYYMMDD'),
          endDate: moment()
            .add(1, 'years')
            .format('YYYYMMDD'),
          date: moment().format('YYYYMMDD'),
          physique: 1,
          staffId: 0,
          reason: '',
          lotNo: '',
          licenseNo: '',
          patientId: this.patientId
        }
    this.inputData = {
      grace: this.inputGrace
    }
  },

  methods: {
    handleCancel() {
      this.$emit('close')
    },
    async handleDecision() {
      await this.createGrace()
    },
    async createGrace() {
      this.waitFlg = true
      const result = await this.$store.dispatch('graces/create', {
        grace: this.inputGrace
      })
      this.waitFlg = false
      this.printGrace()
    },
    async printGrace() {
      const patient = this.getPatient(this.patientId)
      const owner = this.getOwner(patient?.ownerId)
      const staff = this.staffSelectData.find(
        e => e.id === this.inputGrace.staffId
      )
      const args = {
        printOptions: {
          paperSize: 'A4'
        },
        clinic: {
          prefectureName: this.prefectures.find(
            v => v.id === this.clinic.prefectureId
          ).name,
          address: this.clinic.address,
          building: this.clinic.building,
          tel: this.clinic.tel
        },
        printData: [
          {
            owner: {
              lastName: owner?.lastName,
              firstName: owner?.firstName,
              tel: owner?.tel,
              prefectureName: this.prefectures.find(
                v => v.id === owner?.prefectureId
              ).name,
              address: owner?.address,
              building: owner?.building
            },
            patient: {
              birthday: patient?.birthday,
              mcCode: patient?.mcCode,
              name: patient?.name,
              sexName: this.patientSexes.find(v => v.id === patient?.sex).name,
              breed: patient?.breed,
              hairColor: patient?.hairColor
            },
            inputData: this.inputGrace,
            staff: {
              name: staff?.name
            }
          }
        ]
      }
      printGrace(args)
      this.handleCancel()
    }
  }
}
</script>

<style lang="scss" scoped>
.grace-popup {
  position: absolute;
  top: 0;
  left: 0;
  .content {
    width: 640px;
    text-align: left;
    font-size: 15px;
    margin-bottom: 20px;
    padding: 0 5px 0;
    > .header {
      display: flex;
      align-items: center;
      > .title {
        font-size: 16px;
        font-weight: bold;
      }
    }
    > .row {
      display: flex;
      align-items: center;
      margin-top: 20px;
      > .label {
        width: 240px;
      }
      > .wavy-line {
        margin: 0 10px;
      }
    }
    > .memo {
      margin-top: 20px;
      display: flex;
      width: 100%;
      > .label {
        width: 240px;
      }
      > .input {
        flex: 1;
        width: 0;
        @include validate-message();
      }
    }
  }
}
</style>
