<template>
  <div class="tags-select-box">
    <div class="new-tag">
      <div class="form">
        <base-text-box
          class="item tag-input"
          :type="'text'"
          :placeholder="'新しいタグ'"
          :maxlength="15"
          :styles="{
            width: '89px',
            fontSize: '13px',
            height: '26px'
          }"
          v-model="newTag.name"
        />
        <div
          class="color-display item"
          :style="{ backgroundColor: newTag.color }"
        ></div>
        <button
          class="square-button orange-text item"
          v-if="lookOnlyFlg === 0"
          data-test="check"
          :disabled="invalid || waitFlg"
          @click="createTag"
        >
          <font-awesome-icon :icon="checkIcon" />
        </button>
        <div
          class="color-chart-button square-button orange-text item"
          data-test="color-chart-button"
          @click="isActive = !isActive"
        >
          <img
            src="@/assets/images/box_menu_pumpkin_32px.png"
            class="box-menu-icon"
            width="20px"
            height="20px"
            alt="box-menu-pumpkin"
          />
        </div>
        <transition name="fade">
          <base-color-chart
            class="chart"
            v-if="isActive"
            :colorSets="colorSets"
            :value="newTag.color"
            @click="inputColor"
          />
        </transition>
      </div>
      <div class="error" v-if="error">{{ error }}</div>
    </div>
    <div class="tag" v-for="tag in tags" :key="tag.id">
      <base-tag-item
        :tag="tag"
        :tags="tags"
        :editable="true"
        :waitFlg="waitFlg"
        @click="applyTag"
        @update="updateTag"
        @delete="openDeletePopup"
      />
    </div>
    <div
      class="overlay"
      v-if="isActive"
      @click.self="isActive = !isActive"
    ></div>
    <transition name="fade">
      <div class="overlay" v-if="flashMsgFlg">
        <div class="flash">
          <span class="text" data-test="flash-message">{{ flashMsg }}</span>
        </div>
      </div>
    </transition>
    <announce-popup
      v-if="alertFlg"
      :type="type"
      :title="title"
      :buttons="buttons"
      :disabled="waitFlg"
      @cancel="alertFlg = false"
      @decision="deleteTag"
      @close="closeAlert"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseTextBox from '@/components/parts/atoms/BaseTextBox.vue'
import BaseTagItem from '@/components/parts/atoms/BaseTagItem.vue'
import BaseColorChart from '@/components/parts/atoms/BaseColorChart'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import {
  achromaticColors,
  reds,
  oranges,
  yellows,
  yellowGreens,
  greens,
  turquoises,
  lightBlues,
  blues,
  navyBlues,
  purples,
  pinks,
  redPinks
} from '@/utils/color_library'

export default {
  name: 'TagsSelectBox',

  components: {
    BaseTextBox,
    BaseTagItem,
    BaseColorChart,
    AnnouncePopup,
    FontAwesomeIcon
  },

  props: {
    tags: { type: Array, required: true }
  },

  data() {
    return {
      editTagIds: [],
      newTag: {},
      deleteTagId: '',
      isActive: false,
      alertFlg: false,
      waitFlg: false,
      flashMsgFlg: false,
      flashMsg: '',
      checkIcon: faCheck,
      buttons: ['閉じる'],
      timeoutId: null
    }
  },

  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    colorSets() {
      const hue = 4
      return Array(
        [
          achromaticColors,
          reds,
          oranges,
          yellows,
          yellowGreens,
          greens,
          turquoises,
          lightBlues,
          blues,
          navyBlues,
          purples,
          pinks,
          redPinks
        ].map(c => c[hue])
      )
    },
    error() {
      const tagNames = this.tags.map(v => v.name)
      return tagNames.includes(
        this.newTag.name !== undefined && this.newTag.name.toLowerCase()
      )
        ? '既に使用されているタグ名です'
        : /[\uD800-\uDBFF][\uDC00-\uDFFF]/.test(this.newTag.name)
        ? '使用できない文字が含まれています'
        : false
    },
    invalid() {
      return (
        this.newTag.name === undefined ||
        this.newTag.name === '' ||
        this.error !== false
      )
    }
  },

  beforeDestroy() {
    clearTimeout(this.timeoutId)
  },

  methods: {
    async createTag() {
      this.waitFlg = true
      const result = await this.$store.dispatch('ownerTags/create', this.newTag)
      if (result === true) this.showFlashMsg('create')
      else {
        this.alertFlg = true
        this.type = 'failure'
        this.title = '失敗'
        result === 'blank tag name'
          ? (this.popupMessage = '空白のタグ名は登録できません')
          : result === 'used tag name'
          ? (this.popupMessage = 'タグ名は既に存在します')
          : (this.popupMessage = '作成に失敗しました')
      }
      this.newTag = {}
      this.waitFlg = false
    },
    inputColor(color) {
      this.newTag.color = color
      this.isActive = false
    },
    applyTag(id) {
      this.$emit('apply-tag', id)
    },
    async updateTag(editTag) {
      this.waitFlg = true
      const result = await this.$store.dispatch('ownerTags/update', editTag)
      if (result === true) this.showFlashMsg('update')
      else {
        this.alertFlg = true
        this.type = 'failure'
        this.title = '失敗'
        if (result === 'blank tag name') {
          this.popupMessage = '空白のタグ名は登録できません'
        } else if (result === 'used tag name') {
          this.popupMessage = 'タグ名は既に存在します'
        } else if (result === 'no data' || result === 'no data in clinic') {
          this.popupMessage =
            '編集に失敗しました。\n編集中のタグは既に削除されています。'
        } else {
          this.popupMessage = '編集に失敗しました'
        }
      }
      this.waitFlg = false
    },
    openDeletePopup(id) {
      this.alertFlg = true
      this.deleteTagId = id
      this.type = 'alert'
      this.title = '注意'
      this.popupMessage = '削除してもよろしいですか？'
      this.buttons = ['削除しない', '削除する']
    },
    async deleteTag() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'ownerTags/delete',
        this.deleteTagId
      )
      if (result === true) {
        this.alertFlg = false
        this.showFlashMsg('delete')
      } else {
        this.type = 'failure'
        this.title = '失敗'
        this.popupMessage = '削除に失敗しました'
        this.buttons = ['閉じる']
      }
      this.waitFlg = false
    },
    closeAlert() {
      this.alertFlg = false
    },
    showFlashMsg(type) {
      this.flashMsgFlg = true
      type === 'create'
        ? (this.flashMsg = 'タグを作成しました')
        : type === 'update'
        ? (this.flashMsg = 'タグを編集しました')
        : type === 'delete'
        ? (this.flashMsg = 'タグを削除しました')
        : (this.flashMsg = '')
      this.timeoutId = setTimeout(() => {
        this.flashMsgFlg = false
        this.flashMsg = ''
      }, 3000)
    }
  }
}
</script>

<style lang="scss" scoped>
.tags-select-box {
  box-sizing: border-box;
  border-radius: 6px;
  border: solid 2px #{$light-grey};
  background-color: #{$very-light-grey};
  padding: 5px;
  margin-top: 5px;
  max-height: 200px;
  overflow: auto;
  > .new-tag {
    margin-bottom: 4px;
    > .form {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 0 5px;
      > .tag-input {
        display: flex;
      }
      > .color-display {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: #{$pumpkin};
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.6);
      }
      > .square-button {
        height: 30px;
        width: 30px;
        font-size: 13px;
        background-color: white;
        box-sizing: border-box;
        padding: auto;
        border: solid 2px #{$light-grey};
        border-radius: 6px;
        cursor: pointer;
        &:disabled {
          pointer-events: none;
          background-color: #{$light-grey};
          color: #{$brown-gray};
        }
        &:hover {
          opacity: 0.8;
          background-color: #{$plus_ee_gray};
        }
        &:active {
          position: relative;
          top: 2px;
          height: 28px;
        }
      }
    }
    > .error {
      width: 100%;
      color: #{$tomato};
      font-size: 12px;
    }
  }
  > .tag ~ .tag {
    margin-top: 4px;
  }
  .orange-text {
    color: #{$pumpkin};
  }
  .color-chart-button {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    > .box-menu-icon {
      width: 20px;
      height: 20px;
    }
  }
  > .chart {
    z-index: 60;
  }
  > .overlay {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: fixed;
    z-index: 50;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    > .flash {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      font-size: 18px;
      background: black;
      margin-top: 130px;
      padding: 0 20px;
      border-radius: 5px;
      > .text {
        color: white;
      }
    }
  }
  .fade-leave-active,
  .fade-enter-active {
    transition: opacity 0.5s;
  }
  .fade-leave-to,
  .fade-enter {
    opacity: 0;
  }
}
</style>
