<template>
  <div class="search-area-detail-form">
    <base-select-box
      class="select-box"
      :value="searchCondition.selectId"
      :selectData="selectBoxData"
      :styles="selectBoxWidth"
      :disabled="searchCondition.isSelectIdBoxDisabled"
      @input="select"
    />
    <validation-provider
      class="validate"
      :rules="{ notSurrogatePair: true }"
      v-slot="{ errors }"
      v-if="inputType === 'textBox'"
      ><base-text-box
        class="input-form"
        :value="searchCondition.text"
        :styles="{ width: '265px' }"
        @input="inputText"
        @keyup="$emit('keyup')"
      /><span class="error" data-test="error">{{ errors[0] }} </span>
    </validation-provider>
    <div class="radio-button-form" v-if="inputType === 'radioButton'">
      <base-radio-button
        v-for="datum in radioButtonData"
        :key="datum.id + 'radio'"
        :option="datum"
        :value="searchCondition.text"
        :styles="radioButtonWidth"
        @input="inputText"
      />
    </div>
    <v-date-picker
      class="input-form date-picker"
      data-test="v-date-picker"
      v-if="inputType === 'datePicker'"
      :input-props="{ class: 'v-date-picker', readonly: true }"
      :mode="'range'"
      :value="date"
      @input="inputDate"
    />
    <base-select-box
      class="input-form select-box"
      v-if="inputType === 'selectBox'"
      :value="searchCondition.text"
      :selectData="selectData"
      @input="inputText"
    />
    <div
      class="one-date-picker-select-box"
      v-if="inputType === 'oneDatePickerSelectBox'"
    >
      <base-select-box
        class="input-form select-box"
        :value="searchCondition.text"
        :selectData="selectData"
        @input="inputText"
      />
      <v-date-picker
        class="input-form date-picker"
        data-test="v-date-picker"
        :input-props="{ class: 'v-date-picker', readonly: true }"
        :value="date"
        @input="inputOneDate2"
      />
    </div>
    <div class="text-boxes" v-if="inputType === 'textBoxes'">
      <validation-provider
        class="validate"
        :rules="{ notSurrogatePair: true, regexNumber: true }"
        v-slot="{ errors }"
        ><base-text-box
          :value="searchCondition.text"
          :styles="{ width: '90px' }"
          :fullWidthNumbersToHalfWidthNumbers="true"
          @input="inputText"
          @keyup="$emit('keyup')"
        /><span
          v-if="inputType === 'textBoxes'"
          class="error"
          data-test="error-text1"
          >{{ errors[0] }}</span
        >
      </validation-provider>
      <span class="young">{{ textBoxesText }}以上</span>
      <div class="kara">～</div>
      <validation-provider
        class="validate"
        :rules="{ notSurrogatePair: true, regexNumber: true }"
        v-slot="{ errors }"
        ><base-text-box
          :value="searchCondition.text2"
          :styles="{ width: '90px' }"
          :fullWidthNumbersToHalfWidthNumbers="true"
          @input="inputText2"
          @keyup="$emit('keyup')"
        /><span
          v-if="inputType === 'textBoxes'"
          class="error"
          data-test="error-text2"
          >{{ errors[0] }}</span
        >
      </validation-provider>
      <div class="old">{{ textBoxesText }}以下</div>
    </div>
    <div
      class="radio-button-period-form"
      v-if="inputType === 'radioButtonPeriod'"
    >
      <div class="radio-buttons">
        <base-radio-button
          v-for="datum in radioButtonData"
          :key="datum.id + 'radio'"
          :option="datum"
          :value="searchCondition.text"
          :styles="radioButtonWidth"
          @input="inputText"
        />
      </div>
      <v-date-picker
        class="input-form"
        data-test="radio-v-date-picker"
        v-if="radioButtonPeriodShowFlg"
        :input-props="{ class: 'v-date-picker', readonly: true }"
        :mode="'range'"
        :min-date="new Date()"
        :value="date"
        @input="inputDate2"
      />
    </div>
    <div
      class="period-radio-button-form"
      v-if="inputType === 'periodRadioButton'"
    >
      <div class="period">
        <validation-provider
          class="validate"
          :rules="`startDateRule:${searchCondition.text2}`"
          v-slot="{ errors }"
          ><base-date-picker
            data-test="period-start"
            :clearFlg="true"
            :value="searchCondition.text"
            @input="inputText"
            @clear="clear('text')"
          />
          <div class="error" data-test="error-date1">{{ errors[0] }}</div>
        </validation-provider>
        <div class="wavy-line">～</div>
        <base-date-picker
          data-test="period-end"
          :clearFlg="true"
          :value="searchCondition.text2"
          @input="inputText2"
          @clear="clear('text2')"
        />
      </div>
      <div class="radio-buttons">
        <div class="label">{{ searchCondition.label }}</div>
        <base-radio-button
          v-for="datum in radioButtonData"
          :key="datum.id + 'radio'"
          :option="datum"
          :value="searchCondition.text3"
          :styles="{ marginRight: '10px' }"
          @input="inputText3"
        />
      </div>
    </div>
    <tags-area
      v-if="inputType === 'tagsSelect'"
      :tags="ownerTags"
      :isOwnerSearch="true"
      :value="searchCondition.text"
      @select-tag="selectTag"
    ></tags-area>
    <base-button-trash class="trash-button" v-if="index !== 0" @click="trash" />
  </div>
</template>

<script>
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import BaseButtonTrash from '@/components/parts/atoms/BaseButtonTrash'
import BaseRadioButton from '@/components/parts/atoms/BaseRadioButton'
import BaseDatePicker from '@/components/parts/atoms/BaseDatePicker'
import TagsArea from '@/components/parts/atoms/TagsArea'
import moment from 'moment'
import { mapGetters } from 'vuex'
import DetectWindowSize from '@/components/mixins/DetectWindowSize'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'

export default {
  name: 'SearchAreaDetailForm',

  components: {
    BaseSelectBox,
    BaseTextBox,
    BaseButtonTrash,
    BaseRadioButton,
    BaseDatePicker,
    TagsArea,
    ValidationProvider
  },

  mixins: [DetectWindowSize],

  props: {
    index: { type: Number },
    searchCondition: { type: Object },
    selectBoxData: { type: Array }
  },

  computed: {
    ...mapGetters({
      species: 'species/getData',
      staffs: 'staffs/selectDataZero',
      ownerTags: 'ownerTags/getData',
      selectDataPatientSexes: 'master/selectPatientSexesZero'
    }),
    inputType() {
      const selectBoxDatum = this.selectBoxData.find(
        v => v.id === this.searchCondition.selectId
      )
      return selectBoxDatum !== undefined ? selectBoxDatum.type : ''
    },
    textBoxesText() {
      return this.searchCondition.selectId === 17 ||
        this.searchCondition.selectId === 7
        ? '歳'
        : '円'
    },
    radioButtonData() {
      return this.searchCondition.radioButtonData
        ? this.searchCondition.radioButtonData
        : this.searchCondition.selectId === 8
        ? //飼主IDの有無
          [
            { id: 1, eachValue: '有り', labelName: '有り' },
            { id: 2, eachValue: '無し', labelName: '無し' }
          ]
        : this.searchCondition.selectId === 9
        ? //飼主の性別
          [
            { id: 3, eachValue: '未設定', labelName: '未設定' },
            { id: 4, eachValue: '男性', labelName: '男性' },
            { id: 5, eachValue: '女性', labelName: '女性' },
            { id: 6, eachValue: 'その他', labelName: 'その他' }
          ]
        : this.searchCondition.selectId === 10
        ? //メールアドレスの有無
          [
            { id: 7, eachValue: '有り', labelName: '有り' },
            { id: 8, eachValue: '無し', labelName: '無し' }
          ]
        : this.searchCondition.selectId === 19
        ? //次回予約の有無
          [
            { id: 9, eachValue: '有り', labelName: '有り' },
            { id: 10, eachValue: '無し', labelName: '無し' }
          ]
        : //死亡患者を含む
          [
            { id: 11, eachValue: '全て', labelName: '全て' },
            { id: 12, eachValue: '死亡患者のみ', labelName: '死亡患者のみ' },
            { id: 13, eachValue: '生存患者のみ', labelName: '生存患者のみ' }
          ]
    },
    selectData() {
      return this.searchCondition.selectData
        ? this.searchCondition.selectData
        : this.searchCondition.selectId === 13
        ? //患者の性別
          this.selectDataPatientSexes
        : this.searchCondition.selectId === 14
        ? //患者の種別
          this.species
        : this.searchCondition.selectId === 18
        ? //患者の主治医
          this.staffs
        : this.searchCondition.selectId === 21
        ? //保険
          [
            { id: 0, name: 'アニコム' },
            { id: 1, name: 'アイペット' },
            { id: 2, name: '保険なし' }
          ]
        : //言語
          [
            { id: 0, name: '日本語' },
            { id: 1, name: '英語' }
          ]
    },
    selectBoxWidth() {
      return this.mixinWindowWidth < 1024
        ? { width: '100px' }
        : { width: '170px' }
    },
    radioButtonWidth() {
      return this.mixinWindowWidth < 1024
        ? { marginLeft: '0px', marginRight: '25px' }
        : { marginLeft: '10px', marginRight: '30px' }
    },
    radioButtonPeriodShowFlg() {
      return (
        this.inputType === 'radioButtonPeriod' &&
        this.searchCondition.text === this.radioButtonData[1].eachValue
      )
    },
    date() {
      const makeStartEnd = searchConditionText => {
        const [start, end] =
          searchConditionText.length > 0
            ? searchConditionText
                .split(',')
                .map(v => moment(v, 'YYYYMMDD').toDate())
            : [new Date(), new Date()]
        return { start, end }
      }
      if (this.inputType === 'datePicker') {
        return makeStartEnd(this.searchCondition.text)
      } else if (this.inputType === 'radioButtonPeriod') {
        return makeStartEnd(this.searchCondition.text2)
      } else if (this.inputType === 'oneDatePickerSelectBox') {
        return this.searchCondition.text2.length > 0
          ? moment(this.searchCondition.text2, 'YYYYMMDD').toDate()
          : new Date()
      } else {
        //上記のinputType以外の場合はthis.dateは使用しないためnullで返す
        return null
      }
    }
  },

  methods: {
    select(id) {
      const idIndex = { id, index: this.index }
      this.$emit('select', idIndex)
    },
    inputText(text) {
      const textObj = { text, index: this.index, type: this.inputType }
      this.$emit('input-text', textObj)
    },
    inputText2(text) {
      const textObj = { text, index: this.index, type: this.inputType }
      this.$emit('input-text2', textObj)
    },
    inputText3(text) {
      const textObj = { text, index: this.index, type: this.inputType }
      this.$emit('input-text3', textObj)
    },
    //↓現状inputOneDateという名前の関数はないですが、この関数はtest2の方の値を変更するため、
    // 関数名に2をつけて他の関数と統一感があるようにしました。
    inputOneDate2(date) {
      const dateText = moment(date).format('YYYYMMDD')
      const textObj = {
        text: dateText,
        index: this.index,
        type: this.inputType
      }
      this.$emit('input-text2', textObj)
    },
    inputDate(obj) {
      const dateText = `${moment(obj.start).format('YYYYMMDD')},${moment(
        obj.end
      ).format('YYYYMMDD')}`
      const textObj = {
        text: dateText,
        index: this.index,
        type: this.inputType
      }
      this.$emit('input-text', textObj)
    },
    inputDate2(obj) {
      const dateText = `${moment(obj.start).format('YYYYMMDD')},${moment(
        obj.end
      ).format('YYYYMMDD')}`
      const textObj = {
        text: dateText,
        index: this.index,
        type: this.inputType
      }
      this.$emit('input-text2', textObj)
    },
    clear(key) {
      const clearObj = { index: this.index, key }
      this.$emit('clear', clearObj)
    },
    trash() {
      this.$emit('trash', this.searchCondition.id)
    },
    selectTag(tagId) {
      this.$emit('select-tag', tagId, this.index)
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin v-date-picker($width: 265px) {
  ::v-deep {
    > span {
      .v-date-picker {
        text-align: right;
        padding-right: 12px;
        width: $width;
        height: 33px;
        border-radius: 5px;
        border: solid 2px #{$light-grey};
        text-indent: 5px;
        cursor: pointer;
        background-image: url('../../../assets/images/calendar.png');
        background-size: 18px 18px;
        background-repeat: no-repeat;
        background-position: 5px center;
      }
    }
  }
}
.search-area-detail-form {
  height: 33px;
  display: flex;
  > .select-box {
    margin-right: 20px;
  }
  > .date-picker {
    margin-right: 20px;
  }
  @include v-date-picker();
  @include validate-message();
  > .validate {
    margin-bottom: 5px;
    > .input-form {
      margin-right: 20px;
    }
  }
  > .radio-button-form {
    display: flex;
  }
  > .one-date-picker-select-box {
    display: flex;
    gap: 0 10px;
    @include v-date-picker(auto);
  }
  > .text-boxes {
    display: flex;
    margin-bottom: 5px;
    @include validate-message();
    > .young {
      margin-left: 10px;
      line-height: 33px;
    }
    > .kara {
      margin: 0 10px;
      line-height: 33px;
    }
    > .old {
      margin: 0 20px 0 10px;
      line-height: 33px;
    }
  }
  > .radio-button-period-form {
    display: flex;
    > .radio-buttons {
      margin-top: 2px;
      display: flex;
    }
    @include v-date-picker();
  }
  > .period-radio-button-form {
    display: flex;
    align-items: center;
    margin-right: 20px;
    > .period {
      display: flex;
      align-items: center;
      @include validate-message();
      > .wavy-line {
        margin: 0 10px;
      }
    }
    > .radio-buttons {
      margin-left: 15px;
      display: flex;
      > .label {
        margin-right: 10px;
      }
      ::v-deep .base-radio-button label {
        padding-left: 15px;
      }
    }
  }
}
</style>
