var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"anicom-form"},[_c('div',{staticClass:"row c-id"},[_c('text-input-form',{attrs:{"data-test":"c-id","requiredFlg":true,"validationRules":{ anicomCIdRule: true },"maxlength":10,"styles":{ width: '130px' },"value":_vm.anicomPatient.cId},on:{"input":function($event){return _vm.emitProperty('cId', $event)}}},[_vm._v("証券番号")])],1),_c('div',{staticClass:"row plan-name"},[_c('select-box-form',{attrs:{"data-test":"plan-name","selectData":_vm.anicomList,"styles":{ width: '240px' },"value":_vm.nameToId(_vm.anicomPatient.planName)},on:{"input":_vm.inputPlanName}},[_vm._v(" 商品名 ")])],1),_c('div',{staticClass:"row period"},[_c('div',{staticClass:"label"},[_vm._v(" 窓口精算期間 ")]),_c('div',{staticClass:"value"},[_c('validation-provider',{ref:"start",staticClass:"validate",attrs:{"rules":{
          requiredRule: _vm.anicomPatient.endDate ? true : false,
          startDateRule: _vm.anicomPatient.endDate
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('base-date-picker',{attrs:{"data-test":"start","popoverProps":Object.assign({}, {visibility: 'click'}, _vm.popoverProps),"value":_vm.anicomPatient.startDate},on:{"click":_vm.focusStart,"input":function($event){return _vm.emitProperty('startDate', $event)}}}),_c('div',{staticClass:"error wide",attrs:{"data-test":"start-error"}},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',{staticClass:"wavy-line"},[_vm._v("～")]),_c('validation-provider',{ref:"end",staticClass:"validate",attrs:{"rules":{ requiredRule: _vm.anicomPatient.startDate ? true : false }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('base-date-picker',{attrs:{"data-test":"end","popoverProps":Object.assign({}, {visibility: 'click'}, _vm.popoverProps),"value":_vm.anicomPatient.endDate},on:{"click":_vm.focusEnd,"input":function($event){return _vm.emitProperty('endDate', $event)}}}),_c('div',{staticClass:"error",attrs:{"data-test":"end-error"}},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.canRenew)?_c('base-button-small-white',{attrs:{"styles":{ width: '100px' }},on:{"click":_vm.renewPeriod}},[_vm._v("期間更新")]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }