<template>
  <div
    class="base-date-picker"
    :style="{ ...styles, height: `${height}px` }"
    @click="$emit('click')"
  >
    <v-date-picker
      class="v-date-picker"
      data-test="v-date-picker"
      is-required
      :input-props="{ class: 'input', readonly: true, disabled }"
      :min-date="toDateOrNull(minDate)"
      :max-date="toDateOrNull(maxDate)"
      :style="computedDatePickerStyles"
      :popover="popoverProps"
      :disabled-dates="disabledDates"
      @input="inputDate"
      v-model="date"
    />
    <div
      v-if="clearFlg"
      class="clear"
      data-test="clear"
      :style="computedClearButtonStyles"
      @click="clearDate"
    >
      ✕
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'BaseDatePicker',

  data() {
    return {
      date: null // Date objects,
    }
  },

  props: {
    value: { type: String }, // YYYYMMDD
    clearFlg: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    disabledDates: { type: Object },
    minDate: { type: String }, // YYYYMMDD
    maxDate: { type: String }, // YYYYMMDD
    popoverProps: { type: Object },
    height: { type: Number, default: 33 },
    width: { type: Number, default: 140 },
    fontSize: { type: Number, default: 13 },
    styles: {
      type: Object,
      validator: obj => {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    }
  },

  watch: {
    value() {
      if (this.value) {
        this.date = moment(this.value, 'YYYYMMDD').toDate()
      } else {
        this.date = null
      }
    }
  },

  computed: {
    computedDatePickerStyles() {
      return {
        '--width': this.width + 'px',
        '--font-size': this.fontSize + 'px',
        '--padding-right': this.fontSize > 13 ? '5px' : '12px'
      }
    },
    computedClearButtonStyles() {
      return { '--height': String(this.height - 4) + 'px' }
    }
  },

  created() {
    if (this.value) {
      this.date = moment(this.value, 'YYYYMMDD').toDate()
    }
  },

  methods: {
    inputDate(date) {
      if (this.date !== null) {
        this.$emit('input', moment(date).format('YYYYMMDD'))
      }
    },
    clearDate() {
      this.date = null
      this.$emit('clear')
    },
    toDateOrNull(date) {
      return date ? moment(date, 'YYYYMMDD').toDate() : null
    }
  }
}
</script>

<style lang="scss" scoped>
.base-date-picker {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: solid 2px #{$light-grey};
  background-color: #{$white};
  overflow: hidden;
  > .v-date-picker {
    box-sizing: border-box;
    height: 100%;
    > ::v-deep .input {
      cursor: pointer;
      box-sizing: border-box;
      width: var(--width);
      height: 100%;
      display: flex;
      align-items: center;
      text-align: right;
      font-size: var(--font-size);
      padding: 0 var(--padding-right) 0 0;
      text-indent: 5px;
      background-image: url('../../../assets/images/calendar.png');
      background-size: 18px 18px;
      background-repeat: no-repeat;
      background-position: 5px center;
      border-width: 0px;
      &:disabled {
        cursor: default;
        opacity: 0.7;
        background-color: #{$light-grey};
        color: #{$brown-gray};
        filter: grayscale(1);
      }
    }
  }
  > .clear {
    cursor: pointer;
    box-sizing: border-box;
    background-color: #{$white-f7};
    border: solid 2px #{$light-grey};
    border-width: 0 0 0 2px;
    width: var(--height);
    height: var(--height);
    line-height: var(--height);
    font-weight: bold;
    text-align: center;
    &:hover {
      opacity: 0.8;
      background-color: #{$plus_ee_gray};
    }
    &:active {
      padding-top: 1px;
    }
  }
}
</style>
