<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div class="microchip-popup" @keyup.esc.stop="checkDiff" tabindex="-1">
      <validation-observer
        class="validation-observer"
        ref="observer"
        v-slot="{ invalid }"
      >
        <popup
          title="MC装着証明書の印刷"
          :buttons="['閉じる', '印刷']"
          :buttonBorderOrangeFlg="true"
          :disabledFlg="invalid || incompleteRequiredFields"
          :headerStyles="{ padding: '15px 0' }"
          :contentStyle="{}"
          @cancel="checkDiff"
          @click-close-mark="checkDiff"
          @decision="createUpdateMicrochipFacility"
        >
          <template v-slot:content>
            <div class="content" v-if="defaultMicrochipData">
              <div class="message">この情報を全て確認してください</div>
              <div class="row">
                <text-input-form
                  data-test="input mc-code"
                  :validationRules="{ numericString: { length: 15 } }"
                  :maxlength="15"
                  :requiredFlg="true"
                  :errorStyles="errorStylesText"
                  :errorShowFlg="true"
                  :fullWidthNumbersToHalfWidthNumbers="true"
                  v-model="formData.mcCode"
                  >マイクロチップの識別番号</text-input-form
                >
              </div>
              <div class="row">
                <text-input-form
                  :maxlength="30"
                  :requiredFlg="true"
                  :errorStyles="errorStylesText"
                  v-model="formData.patientName"
                  >犬又は猫の名</text-input-form
                >
              </div>
              <div class="row">
                <select-box-form
                  :requiredFlg="true"
                  :selectData="speciesSelectData"
                  :errorStyles="errorStylesText"
                  v-model="formData.species"
                  >犬又は猫の別</select-box-form
                >
              </div>
              <div class="row">
                <text-input-form
                  :maxlength="30"
                  :requiredFlg="true"
                  :errorStyles="errorStylesText"
                  v-model="formData.breed"
                  >犬又は猫の品種</text-input-form
                >
              </div>
              <div class="row">
                <text-input-form
                  :maxlength="30"
                  :requiredFlg="true"
                  :errorStyles="errorStylesText"
                  v-model="formData.hairColor"
                  >犬又は猫の毛色</text-input-form
                >
              </div>
              <div class="row">
                <birthday-input-form
                  :birthday="formData.birthday"
                  :requiredFlg="true"
                  @input="setPatientBirthday"
                  >犬又は猫の生年月日</birthday-input-form
                >
              </div>
              <div class="row">
                <select-box-form
                  :requiredFlg="true"
                  :selectData="sexSelectData"
                  :errorStyles="errorStylesSelect"
                  v-model="formData.sex"
                  >犬又は猫の性別</select-box-form
                >
              </div>
              <div class="row">
                <text-input-form
                  :maxlength="30"
                  :errorStyles="errorStylesText"
                  v-model="formData.additional"
                  >ここまでのほか犬又は猫の特徴となるべき事項</text-input-form
                >
              </div>
              <div class="row date-row">
                <div class="row-heading">
                  マイクロチップの装着日
                  <div class="required">必須</div>
                </div>
                <base-date-picker
                  :value="defaultMicrochipData.chipDate"
                  :minDate="minDate"
                  :maxDate="maxDate"
                  @input="setPatientChipDate"
                ></base-date-picker>
              </div>
              <div class="line"></div>
              <div class="row">
                <div class="sub-heading">
                  マイクロチップを装着した施設名及び所在地
                </div>
                <div class="address-row">
                  <text-input-form
                    data-test="input postal-code"
                    :maxlength="7"
                    :requiredFlg="true"
                    :errorStyles="errorStylesText"
                    :validationRules="{ regexPostalCode: true }"
                    :fullWidthNumbersToHalfWidthNumbers="true"
                    v-model="formData.postalCode"
                    >郵便番号</text-input-form
                  >
                </div>
                <div class="address-row">
                  <select-box-form
                    :requiredFlg="true"
                    :validationRules="{
                      requiredRule: true,
                      oneOfRule: prefectures
                    }"
                    :selectData="prefectures"
                    :errorStyles="errorStylesText"
                    v-model.number="formData.prefecture"
                    @input="setClinicPrefecture"
                    >都道府県</select-box-form
                  >
                </div>
                <div class="address-row">
                  <text-input-form
                    :maxlength="26"
                    :requiredFlg="true"
                    :errorStyles="errorStylesText"
                    v-model="formData.address"
                    >市区町村</text-input-form
                  >
                </div>
                <div class="address-row">
                  <text-input-form
                    v-model="formData.building"
                    :maxlength="30"
                    :errorStyles="errorStylesText"
                    >ビル・マンション名</text-input-form
                  >
                </div>
                <div class="address-row">
                  <text-input-form
                    v-model="formData.clinicName"
                    :maxlength="30"
                    :requiredFlg="true"
                    :errorStyles="errorStylesText"
                    >施設名</text-input-form
                  >
                </div>
              </div>
              <div class="row">
                <text-input-form
                  data-test="input tel"
                  :validationRules="{ regexTel: true }"
                  :maxlength="11"
                  :requiredFlg="true"
                  :errorStyles="errorStylesText"
                  :fullWidthNumbersToHalfWidthNumbers="true"
                  v-model="formData.tel"
                  >マイクロチップを装着した施設の電話番号</text-input-form
                >
              </div>
              <div class="row">
                <comb-input-form
                  :selectData="veterinarians"
                  :maxlength="22"
                  :errorShowFlg="true"
                  :requiredFlg="true"
                  :errorStyles="errorStylesText"
                  v-model="formData.staffName"
                  >マイクロチップを装着した獣医師の氏名</comb-input-form
                >
              </div>
              <div class="footnote">
                備考 この証明書の用紙の大きさは、日本産業規格Ａ４とすること。
              </div>
            </div>
          </template>
        </popup>
        <announce-popup
          v-if="popupFlg"
          :type="popup.type"
          :title="popup.title"
          :buttons="popup.buttons"
          :layerNumber="2"
          @close="popupFlg = false"
          @cancel="popupFlg = false"
          @decision="$emit('close')"
          >{{ popup.message }}</announce-popup
        >
      </validation-observer>
    </div>
  </focus-trap>
</template>

<script>
import BaseDatePicker from '@/components/parts/atoms/BaseDatePicker'
import BirthdayInputForm from '@/components/parts/molecules/BirthdayInputForm'
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm'
import CombInputForm from '@/components/parts/molecules/CombInputForm'
import Popup from '@/components/popups/Popup'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import { FocusTrap } from 'focus-trap-vue'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import { ValidationObserver } from 'vee-validate'
import { PDFDocument } from 'pdf-lib'
import fontkit from '@pdf-lib/fontkit'
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'MicrochipCertificatePopup',

  components: {
    BaseDatePicker,
    BirthdayInputForm,
    SelectBoxForm,
    Popup,
    AnnouncePopup,
    TextInputForm,
    FocusTrap,
    CombInputForm,
    ValidationObserver
  },

  mixins: [CheckPopupInputDifference],

  props: {
    defaultMicrochipData: { type: Object },
    patientId: { type: Number }
  },

  data() {
    return {
      speciesSelectData: [
        { id: 1, name: '犬' },
        { id: 2, name: '猫' }
      ],
      sexSelectData: [
        { id: 1, name: '雄（オス）' },
        { id: 2, name: '雌（メス）' }
      ],
      formData: {},
      errorStylesText: {
        position: 'absolute',
        top: '6px',
        left: '270px',
        width: '200px'
      },
      errorStylesSelect: {
        position: 'absolute',
        top: '6px',
        left: '105px',
        width: 'fit-content'
      },
      popupFlg: false,
      popup: {
        type: '',
        title: '',
        message: '',
        buttons: []
      },
      initialData: {}
    }
  },

  computed: {
    ...mapGetters({
      prefectures: 'master/getPrefectures',
      getPrefectureById: 'master/getPrefectureIndexedById',
      staffSelect: 'staffs/selectDataZero',
      getPatientMicrochipFacility: 'microchipFacilities/getDataByPatientId'
    }),
    diffFlg() {
      return !_.isEqual(this.initialData, this.formData)
    },
    existingMicrochipFacility() {
      const records = this.getPatientMicrochipFacility(this.patientId)
      if (!records) return
      const latest = records.sort(
        (a, b) =>
          moment(b.updatedAt).format('YYYYMMDDHHmm') -
          moment(a.updatedAt).format('YYYYMMDDHHmm')
      )[0]
      return latest
    },
    veterinarians() {
      return this.staffSelect?.filter(staff => staff.jobId === 1) || []
    },
    minDate() {
      return this.formData.birthday
        ? moment(this.formData.birthday).format('YYYYMMDD')
        : ''
    },
    maxDate() {
      return moment().format('YYYYMMDD')
    },
    incompleteRequiredFields() {
      return (
        !this.formData.currentDate ||
        0 > this.formData.mcCode.length > 16 ||
        !this.formData.patientName ||
        !this.formData.species ||
        !this.formData.breed ||
        !this.formData.hairColor ||
        !this.formData.birthday ||
        !this.formData.sex ||
        !this.formData.chipDate ||
        !this.formData.postalCode ||
        !this.formData.prefecture ||
        !this.formData.address ||
        !this.formData.clinicName ||
        !this.formData.tel ||
        !this.formData.staffName
      )
    }
  },

  created() {
    const data = this.existingMicrochipFacility
    const {
      currentDate,
      mcCode,
      patientName,
      species,
      breed,
      hairColor,
      birthday,
      sex,
      additional,
      chipDate,
      postalCode,
      prefecture,
      address,
      building,
      clinicName,
      tel,
      staffName
    } = this.defaultMicrochipData
    this.formData = {
      currentDate,
      mcCode,
      patientName,
      species,
      breed,
      hairColor,
      birthday,
      sex,
      additional: data?.additionalInfo ?? additional,
      chipDate,
      postalCode: data?.postalCode ?? postalCode,
      prefecture: data?.prefectureId ?? prefecture,
      address: data?.address ?? address,
      building: data?.building ?? building,
      clinicName: data?.clinicName ?? clinicName,
      tel: data?.tel ?? tel,
      staffName: data?.veterinarianName ?? staffName
    }
    this.initialData = { ...this.formData }
  },

  methods: {
    setPatientBirthday(newBirthday) {
      this.formData.birthday = newBirthday
    },
    setPatientChipDate(newChipDate) {
      this.formData.chipDate = newChipDate
    },
    setClinicPrefecture(prefecture) {
      this.formData.prefecture = prefecture
    },
    checkDiff() {
      if (this.diffFlg) {
        this.popupFlg = true
        this.popup = {
          type: 'alert',
          title: '確認',
          message: '閉じようとしています。よろしいですか？',
          buttons: ['キャンセル', '閉じる']
        }
      } else {
        this.$emit('close')
      }
    },
    setDataToRender() {
      const prefectureName = this.getPrefectureById(this.formData.prefecture)
        .name
      const displaySpecies =
        this.formData.species === 1
          ? '✓ 犬　            □ 猫'
          : '□ 犬　            ✓ 猫'
      const displaySex =
        this.formData.sex === 1
          ? '✓ 雄（オス）　    □ 雌（メス） '
          : '□ 雄（オス）　    ✓ 雌（メス） '
      return {
        ...this.formData,
        postalCode: `〒${this.formData.postalCode.substring(
          0,
          3
        )}-${this.formData.postalCode.substring(3, 7)}`,
        prefecture: prefectureName,
        birthday: moment(this.formData.birthday).format('Y年M月D日'),
        chipDate: moment(this.formData.chipDate).format('Y年M月D日'),
        prefectureAndAddress: `${prefectureName}${this.formData.address}`,
        species: displaySpecies,
        sex: displaySex
      }
    },
    printPdf() {
      const dataToRender = this.setDataToRender()
      const outputPdf = async () => {
        const formUrl = '/microchip-form.pdf'
        const formPdfBytes = await axios
          .get(formUrl, { responseType: 'arraybuffer' })
          .then(res => {
            return res.data
          })
        const pdfDoc = await PDFDocument.load(formPdfBytes)
        pdfDoc.registerFontkit(fontkit)
        const fontBytes = await fetch(
          'NotoSansMonoCJKJPRegular.otf'
        ).then(res => res.arrayBuffer())
        const customFont = await pdfDoc.embedFont(fontBytes)
        const form = pdfDoc.getForm()
        const textArray = [
          'currentDate',
          'mcCode',
          'patientName',
          'species',
          'breed',
          'hairColor',
          'birthday',
          'sex',
          'additional',
          'chipDate',
          'postalCode',
          'prefectureAndAddress',
          'building',
          'clinicName',
          'tel',
          'staffName'
        ]
        textArray.forEach(field =>
          form.getTextField(field).setText(dataToRender[field])
        )
        if (!dataToRender.building) {
          form.getTextField('building').setText(dataToRender['clinicName'])
          form.getTextField('clinicName').setText('')
        }
        form.getFields().forEach(field => {
          field.setFontSize(9)
        })
        form.updateFieldAppearances(customFont)
        form.flatten()
        const pdfBytes = await pdfDoc.save()
        let blob = new Blob([pdfBytes], { type: 'application/pdf' })
        let link = document.createElement('a')
        link.setAttribute('id', 'pdf-link')
        link.href = window.URL.createObjectURL(blob)
        window.open(link)
      }
      outputPdf()
    },
    async createUpdateMicrochipFacility() {
      const microchipFacilityKeys = [
        'additional',
        'address',
        'building',
        'clinicName',
        'postalCode',
        'prefectureId',
        'tel',
        'staffName'
      ]
      const dataUnchanged = microchipFacilityKeys.every(
        key => this.initialData[key] === this.formData[key]
      )
      if (dataUnchanged) this.printPdf()
      else {
        const {
          postalCode,
          prefecture,
          address,
          building,
          clinicName,
          tel,
          staffName,
          additional
        } = this.formData
        const microchipFacility = {
          patientId: this.patientId,
          postalCode,
          prefectureId: prefecture,
          address,
          building,
          clinicName,
          tel,
          veterinarianName: staffName,
          additionalInfo: additional
        }
        const res = this.existingMicrochipFacility
          ? await this.$store.dispatch('microchipFacilities/update', {
              ...microchipFacility,
              id: this.existingMicrochipFacility.id
            })
          : await this.$store.dispatch(
              'microchipFacilities/create',
              microchipFacility
            )
        if (res === true) {
          this.initialData = { ...this.formData }
          this.printPdf()
        } else {
          let message
          if (res === 'no patient') {
            message = '対象の患者は既に削除されています。'
          } else if (res === 'no microchipFacility') {
            message = '対象の患者にマイクロチップ情報が既に削除されています。'
          } else {
            message = '失敗しました'
          }
          this.popupFlg = true
          this.popup = {
            type: 'alert',
            title: '失敗',
            message,
            buttons: ['閉じる']
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.microchip-popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  .content {
    box-sizing: border-box;
    width: 900px;
    min-height: 420px;
    > .line {
      flex: 1;
      height: 1px;
      background-color: #{$light-grey};
      margin-top: 20px;
    }

    > .message {
      font-size: 14px;
      text-align: center;
      margin-bottom: 15px;
    }

    > .row {
      margin-bottom: 5px;
      width: 900px;

      > .address-row {
        margin-bottom: 5px;
      }

      > .sub-heading {
        margin: 20px 0 10px;
        font-size: 15px;
        font-weight: 600;
      }
    }
    > .date-row {
      display: flex;
      align-items: flex-start;
      > .row-heading {
        display: flex;
        align-items: center;
        height: 33px;
        width: 40%;
        font-size: 15px;
        > .required {
          margin-left: 10px;
          font-size: 13px;
          color: #{$tomato};
        }
      }
    }
    > .footnote {
      font-size: 12px;
      text-align: center;
      margin-top: 10px;
    }
  }
}
</style>
