<template>
  <div class="base-image-uploader">
    <label class="input-area" :for="imageId" :style="{ cursor: cursorStyle }">
      <div
        class="placeholder"
        v-if="image === null && !uploadingImg"
        :style="placeholderStyles"
      >
        <div class="plus">+</div>
        <div class="text">画像を追加</div>
      </div>
      <div
        class="placeholder"
        v-else-if="uploadingImg"
        :style="placeholderStyles"
      >
        <div class="loading">⟳</div>
        <div class="text">画像読み込み中</div>
      </div>
      <img
        v-else
        class="image"
        alt="uploaded-image"
        :src="image"
        :style="imageStyles"
      />
    </label>
    <input
      :id="imageId"
      type="file"
      accept="image/png,image/jpeg,image/gif,image/bmp"
      :key="deletedTimes"
      @change="onImageChange"
      :disabled="uploadingImg"
    />
    <div v-if="image === null" class="accept">
      bmp/jpg/gif/png
    </div>
    <div v-else class="delete-button" @click="deleteImage">×</div>
    <div v-if="uploadError" class="error-msg">画像の追加に失敗しました</div>
  </div>
</template>

<script>
import imageCompression from 'browser-image-compression'

export default {
  name: 'BaseImageUploader',

  props: {
    image: { type: [String, null], default: null },
    keyword: { type: String, default: '' },
    placeholderStyles: {
      type: Object,
      default: () => ({
        width: '110px',
        height: '110px',
        borderRadius: '50%'
      })
    },
    imageStyles: {
      type: Object,
      default: () => ({
        width: '110px',
        height: '110px',
        objectFit: 'cover',
        borderRadius: '50%'
      }),
      validator: obj => Object.values(obj).every(v => typeof v === 'string')
    },
    maxSizeMB: { type: Number, default: 1 },
    maxWidthOrHeight: { type: Number, default: 150 }
  },

  data() {
    return {
      deletedTimes: 0,
      uploadingImg: false,
      uploadError: false,
      prevImg: ''
    }
  },

  computed: {
    imageId() {
      return `base-image-input${this.keyword.length ? `-${this.keyword}` : ''}`
    },
    cursorStyle() {
      return this.uploadingImg ? 'default' : 'pointer'
    }
  },

  updated() {
    if (this.image !== null) {
      this.setUploadingImg(false)
    }
  },

  methods: {
    async onImageChange(e) {
      this.prevImg = this.image
      this.uploadError = false
      if (e.target.files.length !== 0 && !this.uploadingImg) {
        this.setUploadingImg(true)
        this.$emit('delete')
        const resized = await this.resizeImage(e.target.files[0])
        if (!resized) return
        const image = await this.encodeBase64(resized)
        if (!image) return
        this.$emit('input', image)
      }
    },
    async resizeImage(file) {
      try {
        return await imageCompression(file, {
          maxSizeMB: this.maxSizeMB,
          maxWidthOrHeight: this.maxWidthOrHeight
        })
      } catch (error) {
        this.setUploadingImg(false)
        this.handleUploadError()
      }
    },
    encodeBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = () => {
          this.setUploadingImg(false)
          this.handleUploadError()
        }
      }).catch(error => {
        this.setUploadingImg(false)
        this.handleUploadError()
      })
    },
    deleteImage() {
      this.deletedTimes += 1
      this.$emit('delete')
      this.uploadError = false
    },
    setUploadingImg(uploadingState) {
      this.uploadingImg = uploadingState
      this.$emit('uploadingImg', uploadingState)
    },
    handleUploadError() {
      this.uploadError = true
      if (this.prevImg) {
        this.$emit('input', this.prevImg)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.base-image-uploader {
  position: relative;
  > .input-area {
    > .placeholder {
      box-sizing: border-box;
      text-align: center;
      color: #{$pumpkin};
      border: 2px solid #{$pumpkin};
      > .plus {
        font-size: 80px;
        margin-top: -25px;
      }
      > .loading {
        font-size: 50px;
        margin: -2px 0 15px 7px;
      }
      > .text {
        font-size: 13px;
        margin-top: -25px;
      }
    }
  }
  > input {
    display: none;
  }
  > .accept {
    text-align: center;
    font-size: 10px;
    color: #{$pumpkin};
    margin-top: 5px;
  }
  > .delete-button {
    text-align: center;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
  > .error-msg {
    width: max-content;
    position: absolute;
    left: -15px;
    top: 142px;
    font-weight: bold;
    font-size: 12px;
    color: #{$tomato};
  }
}
</style>
