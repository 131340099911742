<template>
  <div
    class="tags-area"
    :style="{ 'margin-top': isOwnerSearch ? '5px' : '20px' }"
  >
    <div v-if="!isOwnerSearch" class="label">タグ</div>
    <div class="tags">
      <div
        :class="{
          tag: true,
          'tag-hover': !tagEditAreaShowFlg,
          selected: value.includes(tag.id)
        }"
        v-for="tag in tags"
        :key="tag.id"
        @click="handleClick(tag.id)"
        data-test="tag"
      >
        {{ tag.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TagsArea',

  props: {
    tagEditAreaShowFlg: { type: Boolean, default: false },
    tags: { type: Array },
    isOwnerSearch: { type: Boolean, default: false },
    value: { type: [Array, String] }
  },

  methods: {
    handleClick(tagId) {
      this.$emit('select-tag', tagId)
    }
  }
}
</script>

<style lang="scss" scoped>
.tags-area {
  display: flex;
  max-height: 50px;
  overflow-y: auto;
  > .label {
    margin: 0 35px 0 20px;
  }
  > .tags {
    display: flex;
    flex-wrap: wrap;
    width: 500px;
    > .tag {
      height: 18px;
      line-height: 18px;
      font-size: 12px;
      color: #{$gray};
      border: 1px solid #{$gray};
      padding: 0 3px;
      border-radius: 50px;
      margin: 0 10px 2px 0;
      cursor: default;
    }
    > .tag-hover {
      @include hover();
    }
    > .selected {
      background: #{$gray};
      color: #{$white};
    }
  }
}
</style>
