<template>
  <div class="anicom-form">
    <div class="row c-id">
      <text-input-form
        data-test="c-id"
        :requiredFlg="true"
        :validationRules="{ anicomCIdRule: true }"
        :maxlength="10"
        :styles="{ width: '130px' }"
        :value="anicomPatient.cId"
        @input="emitProperty('cId', $event)"
        >証券番号</text-input-form
      >
    </div>
    <div class="row plan-name">
      <select-box-form
        data-test="plan-name"
        :selectData="anicomList"
        :styles="{ width: '240px' }"
        :value="nameToId(anicomPatient.planName)"
        @input="inputPlanName"
      >
        商品名
      </select-box-form>
    </div>
    <div class="row period">
      <div class="label">
        窓口精算期間
      </div>
      <div class="value">
        <validation-provider
          class="validate"
          ref="start"
          :rules="{
            requiredRule: anicomPatient.endDate ? true : false,
            startDateRule: anicomPatient.endDate
          }"
          v-slot="{ errors }"
        >
          <base-date-picker
            data-test="start"
            :popoverProps="{ visibility: 'click', ...popoverProps }"
            :value="anicomPatient.startDate"
            @click="focusStart"
            @input="emitProperty('startDate', $event)"
          />
          <div class="error wide" data-test="start-error">{{ errors[0] }}</div>
        </validation-provider>
        <div class="wavy-line">～</div>
        <validation-provider
          class="validate"
          ref="end"
          :rules="{ requiredRule: anicomPatient.startDate ? true : false }"
          v-slot="{ errors }"
        >
          <base-date-picker
            data-test="end"
            :popoverProps="{ visibility: 'click', ...popoverProps }"
            :value="anicomPatient.endDate"
            @click="focusEnd"
            @input="emitProperty('endDate', $event)"
          />
          <div class="error" data-test="end-error">{{ errors[0] }}</div>
        </validation-provider>
        <base-button-small-white
          v-if="canRenew"
          :styles="{ width: '100px' }"
          @click="renewPeriod"
          >期間更新</base-button-small-white
        >
      </div>
    </div>
  </div>
</template>

<script>
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseDatePicker from '@/components/parts/atoms/BaseDatePicker'
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm'
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'

export default {
  name: 'AnicomForm',

  components: {
    BaseButtonSmallWhite,
    BaseDatePicker,
    SelectBoxForm,
    TextInputForm,
    ValidationProvider
  },

  props: {
    anicomPatient: { type: Object },
    popoverProps: { type: Object, default: () => {} }
  },

  data() {
    return {
      anicomList: [
        { id: 1, name: 'どうぶつ健保ふぁみりぃ' },
        { id: 2, name: 'どうぶつ健保しにあ' },
        { id: 3, name: 'どうぶつ健保ぷち' },
        { id: 4, name: 'どうぶつ健保べいびぃ' },
        { id: 5, name: 'どうぶつ健保すまいるべいびぃ' },
        { id: 6, name: 'どうぶつ健保すまいるふぁみりぃ' },
        { id: 7, name: 'どうぶつ健保はっぴぃ' }
      ]
    }
  },

  computed: {
    canRenew() {
      const { id, startDate, endDate } = this.anicomPatient
      return id && startDate && endDate ? true : false
    }
  },

  watch: {
    'anicomPatient.startDate': function() {
      this.$nextTick(() => {
        if (this.anicomPatient.startDate) {
          this.focusEnd()
        }
      })
    },
    'anicomPatient.endDate': function() {
      this.$nextTick(() => {
        if (this.anicomPatient.endDate) {
          this.focusStart()
        }
      })
    }
  },

  methods: {
    nameToId(planName) {
      const target = this.anicomList.find(v => v.name === planName)
      const id = target?.id ?? ''
      return id
    },
    inputPlanName(id) {
      const target = this.anicomList.find(v => v.id === id)
      const val = target?.name ?? ''
      this.emitProperty('planName', val)
    },
    focusStart() {
      this.$refs.start.validate()
    },
    focusEnd() {
      this.$refs.end.validate()
    },
    renewPeriod() {
      this.$emit('renew-period')
    },
    emitProperty(key, val) {
      this.$emit('input', { key, val })
    }
  }
}
</script>

<style lang="scss" scoped>
$label-width: 150px;
.anicom-form {
  width: 600px;
  > .row {
    margin-bottom: 19px;
    font-size: 15px;
    ::v-deep .text-input-form .heading,
    ::v-deep .select-box-form .heading {
      max-width: $label-width;
    }
  }
  > .c-id {
    ::v-deep .text-input-form .body .validate .error {
      width: 550px;
    }
  }
  > .period {
    display: flex;
    align-items: center;
    > .label {
      width: $label-width;
      display: flex;
      align-items: center;
      gap: 0 10px;
      > .required {
        font-size: 13px;
        color: #{$tomato};
      }
    }
    > .value {
      display: flex;
      align-items: center;
      gap: 0 10px;
      > .validate {
        position: relative;
        > .error {
          position: absolute;
          z-index: 1;
          color: #{$tomato};
          font-size: 12px;
          text-align: left;
          &.wide {
            width: 400px;
          }
        }
      }
    }
  }
}
</style>
