import { render, staticRenderFns } from "./SearchAreaDetail.vue?vue&type=template&id=76044840&scoped=true&"
import script from "./SearchAreaDetail.vue?vue&type=script&lang=js&"
export * from "./SearchAreaDetail.vue?vue&type=script&lang=js&"
import style0 from "./SearchAreaDetail.vue?vue&type=style&index=0&id=76044840&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76044840",
  null
  
)

export default component.exports