<template>
  <div class="base-button-trash">
    <button
      type="button"
      @click="handleClick"
      :style="styles"
      :disabled="disabled"
    >
      <img
        v-if="orangeFlg"
        src="@/assets/images/trash_orange.png"
        width="12px"
        height="15px"
        alt="base-button-trash"
      />
      <img
        v-else
        src="@/assets/images/base_button_trash.png"
        width="12px"
        height="15px"
        alt="base-button-trash"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseButtonTrash',

  props: {
    styles: { type: Object, default: () => {} },
    orangeFlg: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },

  methods: {
    handleClick(e) {
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-button-trash {
  > button {
    width: 33px;
    height: 33px;
    border-radius: 6px;
    border: solid 2px #{$tomato};
    background-color: #{$tomato};
    cursor: pointer;
    &:disabled {
      pointer-events: none;
      background-color: #{$light-grey};
      border: solid 2px #{$light-grey};
      color: #{$brown-gray};
    }
    &:hover {
      opacity: 0.8;
    }
    &:active {
      margin: 2px auto 0 auto;
      height: 31px;
    }
  }
}
</style>
