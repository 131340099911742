<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div class="mail-popup" @keyup.esc.stop="checkDiff" tabindex="-1">
      <base-loading :waitFlg="waitFlg" />
      <validation-observer
        class="validation-observer"
        ref="form"
        v-slot="{ invalid }"
      >
        <popup
          :title="'メール送信フォーム'"
          :buttons="['閉じる', '送信']"
          :invalid="invalid || waitFlg || lookOnlyFlg === 1"
          @cancel="checkDiff"
          @click-close-mark="checkDiff"
          @decision="create"
        >
          <template v-slot:content>
            <div class="mail-content">
              <div class="owner">
                <div class="label">飼主名（メールアドレス）</div>
                <div class="body" data-test="owner-name">
                  <div v-for="owner in owners" :key="owner.id">
                    {{ owner.lastName + ' ' + owner.firstName }}（{{
                      owner.email
                    }}）
                  </div>
                </div>
              </div>
              <div class="template">
                <select-box-form
                  class="select-box-form"
                  :selectData="sendMailTemplates"
                  v-model="sendMailTemplateId"
                  @input="inputTemplate"
                >
                  メール定型文
                </select-box-form>
              </div>
              <div class="title">
                <text-input-form
                  v-model="sendMail.title"
                  :requiredFlg="true"
                  :placeholder="'50文字まで'"
                  :maxlength="50"
                  :styles="{ width: '360px' }"
                  >件名</text-input-form
                >
              </div>
              <div class="message">
                <multiple-text-input-form
                  v-model="sendMail.message"
                  :requiredFlg="true"
                  >本文
                  <template v-if="owners.length > 1" #title-supplement
                    ><div :style="supplementStyles">
                      （飼主名）の部分は送信する各飼主名に自動変換されます。<br />
                      （飼主名）の文字を変更すると変換されなくなりますのでご注意下さい。
                    </div></template
                  ></multiple-text-input-form
                >
              </div>
            </div>
          </template>
        </popup>
        <announce-popup
          v-if="alertFlg"
          :title="title"
          :buttons="buttons"
          :type="type"
          :layerNumber="2"
          @close="closeAlert"
          @cancel="closePopup"
          @decision="$emit('close')"
          >{{ popupMessage }}</announce-popup
        >
      </validation-observer>
    </div>
  </focus-trap>
</template>

<script>
import BaseLoading from '@/components/parts/atoms/BaseLoading'
import Popup from '@/components/popups/Popup'
import { ValidationObserver } from 'vee-validate'
import SelectBoxForm from '@/components/parts/molecules/SelectBoxForm'
import TextInputForm from '@/components/parts/molecules/TextInputForm'
import MultipleTextInputForm from '@/components/parts/molecules/MultipleTextInputForm'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import { FocusTrap } from 'focus-trap-vue'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'MailPopup',

  components: {
    BaseLoading,
    Popup,
    ValidationObserver,
    SelectBoxForm,
    TextInputForm,
    MultipleTextInputForm,
    AnnouncePopup,
    FocusTrap
  },

  mixins: [CheckPopupInputDifference],

  props: {
    owners: { type: Array }
  },

  data() {
    return {
      sendMail: { title: '', message: '' },
      initialData: {},
      alertFlg: false,
      waitFlg: false,
      type: '',
      title: '',
      buttons: [],
      popupMessage: '',
      ownerDeletedFlg: false,
      beginOfSentence: '',
      endOfSentence: `\n\n==============================\n本メールは送信専用です。\n本メールに返信しないようお願いいたします。\n==============================`,
      sendMailTemplates: [{ id: 0, name: '', title: '', message: '' }].concat(
        this.$store.getters['sendMailTemplates/getData']
      ),
      sendMailTemplateId: 0,
      supplementStyles: { marginTop: '5px', width: '230px', fontSize: '13px' }
    }
  },

  computed: {
    ...mapGetters({
      getMailTemplate: 'sendMailTemplates/getDataById',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    diffFlg() {
      return !_.isEqual(this.initialData, this.sendMail)
    }
  },

  created() {
    const clinicName = this.$store.getters['clinic/getData'].name
    const ownerName =
      this.owners.length > 1
        ? '（飼主名）'
        : `${this.owners[0].lastName} ${this.owners[0].firstName}`
    this.beginOfSentence = `${clinicName}より${ownerName}様へ\n\n`
    this.sendMail.message = this.beginOfSentence + this.endOfSentence
    this.initialData = _.cloneDeep(this.sendMail)
  },

  methods: {
    checkDiff() {
      if (this.diffFlg) {
        this.type = 'alert'
        this.title = '確認'
        this.buttons = ['キャンセル', '閉じる']
        this.popupMessage =
          '入力内容を保存せずに閉じようとしています。\nよろしいですか？'
        this.alertFlg = true
      } else {
        this.$emit('close')
      }
    },
    closePopup() {
      this.type = ''
      this.title = ''
      this.buttons = ['']
      this.popupMessage = ''
      this.alertFlg = false
    },
    inputTemplate() {
      if (this.sendMailTemplateId === 0) {
        this.sendMail.title = ''
        this.sendMail.message = this.beginOfSentence + this.endOfSentence
      } else {
        const selectTemplate = this.getMailTemplate(this.sendMailTemplateId)
        this.sendMail.title = selectTemplate.title
        this.sendMail.message =
          this.beginOfSentence + selectTemplate.message + this.endOfSentence
      }
    },
    async create() {
      this.waitFlg = true
      const ownerIds = this.owners.map(o => o.id)
      const res = await this.$store.dispatch('sendMails/create', {
        ownerIds,
        title: this.sendMail.title,
        message: this.sendMail.message,
        category: 'manual'
      })
      if (res.result === true) {
        if (res.notSendOwnerIds && res.notSendOwnerIds.length !== 0) {
          const ownerNames = res.notSendOwnerIds.map(ownerId => {
            const owner = this.owners.find(v => v.id === ownerId)
            return `${owner.lastName} ${owner.firstName}`
          })
          this.type = 'alert'
          this.title = '注意'
          this.popupMessage = `メールを送信しました。\n但し、下記の飼主のメール送信には失敗しました。\n・${ownerNames.join(
            '\n'
          )}\n他のユーザーが飼主データを削除、またはメールアドレスを空欄に変更しております。\nブラウザの更新をクリックし、最新の飼主情報をご確認下さい。`
        } else {
          this.type = 'success'
          this.title = '完了'
          this.popupMessage = 'メールを送信しました'
        }
        this.initialData = _.cloneDeep(this.sendMail)
      } else {
        this.type = 'failure'
        this.title = '失敗'
        if (res === 'owners') {
          this.popupMessage = `メール送信に失敗しました。\n他のユーザーが飼主データを削除、またはメールアドレスを空欄に変更しております。\nブラウザの更新をクリックし、最新の飼主情報をご確認下さい。`
        } else if (res === 'owner') {
          this.ownerDeletedFlg =
            this.$route.path === '/main/owners' ? false : true

          this.popupMessage =
            this.$route.path === '/main/owners'
              ? `メール送信に失敗しました。\n既に削除された飼主が使用されています。\nブラウザの更新をクリックし、最新の飼主情報をご確認下さい。`
              : `メール送信に失敗しました。\n既に削除された飼主が使用されています。\n飼主・患者画面に遷移します。`
        } else if (res === 'email') {
          this.popupMessage = `メール送信に失敗しました。\n他のユーザーが飼主のメールアドレスを空欄に変更しております。\nブラウザの更新をクリックし、最新の飼主情報をご確認下さい。`
        } else {
          this.popupMessage = 'メール送信に失敗しました'
        }
      }
      this.waitFlg = false
      this.buttons = ['閉じる']
      this.alertFlg = true
    },
    closeAlert() {
      if (this.type === 'success' || this.type === 'alert') {
        this.alertFlg = false
        this.$emit('close')
      } else if (this.ownerDeletedFlg === true) {
        this.alertFlg = false
        this.$router.push({ path: '/main/owners' })
      } else {
        this.alertFlg = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mail-popup {
  position: absolute;
  top: 0;
  left: 0;
  .mail-content {
    margin-top: 30px;
    width: 740px;
    height: 450px;
    > .owner {
      width: 600px;
      min-height: 33px;
      max-height: 120px;
      display: flex;
      margin: 0 auto 20px;
      font-size: 15px;
      > .label {
        width: 40%;
      }
      > .body {
        width: 60%;
        overflow: auto;
      }
    }
    > .template {
      width: 600px;
      margin: 0 auto;
    }
    > .title {
      width: 600px;
      margin: 30px auto 0;
    }
    > .message {
      width: 600px;
      margin: 30px auto 0;
    }
  }
}
</style>
