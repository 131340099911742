<template>
  <div class="base-no-photo">
    <div class="text">NO PHOTO</div>
  </div>
</template>

<script>
export default {
  name: 'BaseNoPhoto'
}
</script>

<style lang="scss" scoped>
.base-no-photo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110px;
  width: 110px;
  box-sizing: border-box;
  border: 2px solid #{$pumpkin};
  border-radius: 50%;
  > .text {
    font-size: 15px;
    font-weight: bold;
    color: #{$pumpkin};
  }
}
</style>
