<template>
  <div class="date-input-form">
    <div class="heading" :style="headingStyle">
      <div class="text"><slot></slot></div>
      <div class="required" v-if="requiredFlg">必須</div>
    </div>
    <div class="body" :style="bodyStyle">
      <validation-provider
        class="validate"
        :rules="{ requiredRule: requiredFlg }"
      >
        <v-date-picker
          :value="displayDate"
          :input-props="{
            class: 'v-date-picker',
            readonly: true
          }"
          :min-date="displayMinDate"
          :max-date="displayMaxDate"
          @input="inputDate"
          data-test="v-date-picker"
          is-required
        />
      </validation-provider>
    </div>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import '@/utils/validation_rules'
import moment from 'moment'

export default {
  name: 'DateInputForm',
  components: { ValidationProvider },
  props: {
    headingStyle: { type: Object },
    bodyStyle: { type: Object },
    requiredFlg: { type: Boolean, default: false },
    date: { type: String, required: true },
    minDate: { type: String, default: '' },
    maxDate: { type: String, default: '' }
  },
  computed: {
    displayDate() {
      return this.date === '' ? null : moment(this.date).toDate()
    },
    displayMinDate() {
      return this.minDate === '' ? null : moment(this.minDate).toDate()
    },
    displayMaxDate() {
      return this.maxDate === '' ? null : moment(this.maxDate).toDate()
    }
  },
  methods: {
    inputDate(val) {
      if (val !== null) {
        this.$emit('input-date', moment(val).format('YYYYMMDD'))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.date-input-form {
  display: flex;
  align-items: center;
  font-size: 15px;
  > .heading {
    display: flex;
    align-items: center;
    width: 40%;
    > .required {
      margin-left: 10px;
      font-size: 13px;
      color: #{$tomato};
    }
  }
  > .body {
    width: 60%;
    > .validate {
      ::v-deep {
        > span {
          .v-date-picker {
            font-size: 13px;
            text-align: right;
            padding-right: 12px;
            width: 140px;
            height: 33px;
            border-radius: 5px;
            border: solid 2px #{$light-grey};
            text-indent: 5px;
            cursor: pointer;
            background-image: url('../../../assets/images/calendar.png');
            background-size: 18px 18px;
            background-repeat: no-repeat;
            background-position: 5px center;
          }
        }
      }
    }
  }
}
</style>
