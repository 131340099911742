import { makeFooter, makePageSize } from '@/utils/print_utils'
import { createPdf } from 'pdfmake/build/pdfmake.min.js'
import '@/utils/vfs_fonts.js'
import _ from 'lodash'

export const makeDocDefinition = ({ printOptions, clinic, printData }) => {
  // 各印刷内容の作成
  const content = []
  for (const { owner, patient, staff, inputData } of printData) {
    const contentItem = makeContentItem(
      owner,
      patient,
      staff,
      clinic,
      inputData
    )
    content.push(contentItem)
  }

  return {
    content,
    footer: (currentPage, pageCount) =>
      makeFooter({
        currentPage,
        pageCount,
        fontSize: 11
      }),
    styles: {
      cellLeft: { fontSize: 11, alignment: 'left' },
      cellCenter: { fontSize: 11, alignment: 'center' },
      cellRight: { fontSize: 11, alignment: 'right' },
      tinyRight: { fontSize: 8, alignment: 'right' }
    },
    defaultStyle: {
      font: 'NotoSansMono',
      fontSize: 12,
      color: '#3e3e3e',
      preserveLeadingSpaces: true
    },
    pageSize: makePageSize(printOptions.paperSize),
    pageMargins: [20, 20, 20, 30 + 11],
    pageOrientation: 'portrait'
  }
}

const makeContentItem = (owner, patient, staff, clinic, inputData) => {
  const physiqueArr = { 1: '', 2: '小型', 3: '中型', 4: '大型' }
  const physique = physiqueArr[inputData.physique]
  const makeDateStr = baseStr => {
    const year = Number(baseStr.substring(0, 4)) + '年'
    const month = Number(baseStr.substring(4, 6)) + '月'
    const date = Number(baseStr.substring(6, 8)) + '日'

    if (baseStr == '') {
      return ''
    } else if (baseStr.length <= 4) {
      return year
    } else if (baseStr.length <= 6) {
      return year + month
    } else {
      return year + month + date
    }
  }

  const contentItemBody = [
    makeTable1(),
    makeAddressTable({
      label: '所有者（管理者）住所',
      address: owner.prefectureName + owner.address + owner.building,
      name: owner.lastName + ' ' + owner.firstName,
      tel: owner.tel
    }),
    makeTable2({
      licenseNo: inputData.licenseNo,
      color: patient.hairColor,
      physique: physique,
      lotNo: inputData.lotNo,
      birthday: makeDateStr(patient.birthday),
      mcCode: patient.mcCode,
      name: patient.name,
      sex: patient.sexName,
      breed: patient.breed
    }),
    makeTable3({
      startDate: makeDateStr(inputData.startDate),
      endDate: makeDateStr(inputData.endDate),
      reason: inputData.reason
    }),
    makeTable4({ date: makeDateStr(inputData.date) }),
    makeAddressTable({
      label: '実施者住所',
      name: staff.name,
      tel: clinic.tel,
      address: clinic.prefectureName + clinic.address + clinic.building
    })
  ]

  return contentItemBody
}

const makeTable1 = () => {
  return {
    margin: [0, 0, 0, 0],
    table: {
      headerRows: 0,
      widths: [545],
      body: [
        [
          {
            text: '狂犬病予防注射猶予証明書',
            alignment: 'center',
            margin: [0, 10, 0, 10]
          }
        ]
      ]
    }
  }
}

const makeTable2 = ({
  licenseNo,
  color,
  physique,
  lotNo,
  birthday,
  mcCode,
  name,
  sex,
  breed
}) => {
  return {
    margin: [0, 0, 0, 0],
    table: {
      headerRows: 0,
      widths: [56, 112, 82, 102, 56, 92],
      body: [
        [
          {
            text: '鑑札No.',
            alignment: 'center',
            margin: [0, 5, 0, 5],
            border: [true, false, true, true]
          },
          {
            text: licenseNo,
            alignment: 'center',
            margin: [0, 5, 0, 5],
            border: [true, false, true, true]
          },
          {
            text: '毛色',
            alignment: 'center',
            margin: [0, 5, 0, 5],
            border: [true, false, true, true]
          },
          {
            text: color,
            alignment: 'center',
            margin: [0, 5, 0, 5],
            border: [true, false, true, true]
          },
          {
            text: '体格',
            alignment: 'center',
            margin: [0, 5, 0, 5],
            border: [true, false, true, true]
          },
          {
            text: physique,
            alignment: 'center',
            margin: [0, 5, 0, 5],
            border: [true, false, true, true]
          }
        ],
        [
          { text: '種類', alignment: 'center', margin: [0, 5, 0, 5] },
          { text: breed, alignment: 'center', margin: [0, 5, 0, 5] },
          { text: '性別', alignment: 'center', margin: [0, 5, 0, 5] },
          { text: sex, alignment: 'center', margin: [0, 5, 0, 5] },
          { text: '名前', alignment: 'center', margin: [0, 5, 0, 5] },
          { text: name, alignment: 'center', margin: [0, 5, 0, 5] }
        ],
        [
          { text: '生年月日', alignment: 'center', margin: [0, 5, 0, 5] },
          { text: birthday, alignment: 'center', margin: [0, 5, 0, 5] },
          { text: 'ﾏｲｸﾛﾁｯﾌﾟID', alignment: 'center', margin: [0, 5, 0, 5] },
          { text: mcCode, alignment: 'center', margin: [0, 5, 0, 5] },
          { text: 'Lot No.', alignment: 'center', margin: [0, 5, 0, 5] },
          { text: lotNo, alignment: 'center', margin: [0, 5, 0, 5] }
        ]
      ]
    }
  }
}

const makeTable3 = ({ startDate, endDate, reason }) => {
  return {
    margin: [0, 0, 0, 0],
    table: {
      headerRows: 0,
      widths: [545],
      body: [
        [
          {
            text:
              '上記の犬に対し' +
              startDate +
              ' ～ ' +
              endDate +
              'まで狂犬病予防の猶予を認めます。',
            alignment: 'center',
            margin: [0, 5, 0, 5],
            border: [true, false, true, true]
          }
        ],
        [
          {
            text: '実施猶予の理由：' + reason,
            alignment: 'left',
            margin: [0, 5, 0, 5]
          }
        ]
      ]
    }
  }
}

const makeTable4 = ({ date }) => {
  return {
    margin: [0, 0, 0, 0],
    table: {
      headerRows: 0,
      widths: [56, 480],
      body: [
        [
          {
            text: '認定日',
            alignment: 'center',
            margin: [0, 5, 0, 5],
            border: [true, false, true, true]
          },
          {
            text: date,
            alignment: 'center',
            margin: [0, 5, 0, 5],
            border: [true, false, true, true]
          }
        ]
      ]
    }
  }
}

const makeAddressTable = ({ label, name, tel, address }) => {
  return {
    margin: [0, 0, 0, 0],
    table: {
      headerRows: 0,
      widths: [126, 124, 126, 142],
      body: [
        [
          {
            text: label,
            alignment: 'center',
            margin: [0, 5, 0, 5],
            border: [true, false, false, false]
          },
          {
            text: address,
            alignment: 'center',
            margin: [0, 5, 0, 5],
            border: [false, false, true, false],
            colSpan: 3
          },
          {},
          {}
        ],
        [
          {
            text: '氏名',
            alignment: 'center',
            margin: [0, 0, 0, 5],
            border: [true, false, false, true]
          },
          {
            text: name,
            alignment: 'center',
            margin: [0, 0, 0, 5],
            border: [false, false, false, true]
          },
          {
            text: 'TEL',
            alignment: 'center',
            margin: [0, 0, 0, 5],
            border: [false, false, false, true]
          },
          {
            text: tel,
            alignment: 'center',
            margin: [0, 0, 0, 5],
            border: [false, false, true, true]
          }
        ]
      ]
    }
  }
}

// args = {
//   printOptions,
//   clinic,
//   printData
// }
export const printGrace = args => {
  const docDefinition = makeDocDefinition(args)
  const fonts = {
    NotoSansMono: {
      normal: 'NotoSansMonoCJKJPRegular.otf',
      bold: 'NotoSansMonoCJKJPRegular.otf',
      italics: 'NotoSansMonoCJKJPRegular.otf',
      bolditalics: 'NotoSansMonoCJKJPRegular.otf'
    }
  }
  const tableLayouts = {
    treatmentItemsTable: {
      hLineColor: '#3e3e3e',
      vLineColor: '#3e3e3e',
      fillColor: rowIndex => (rowIndex === 0 ? '#f0f0f0' : null)
    },
    priceDetailTable: {
      hLineWidth: () => 0,
      vLineWidth: () => 0,
      paddingLeft: i => (i === 0 ? 5 : 20),
      fillColor: rowIndex => (rowIndex % 2 === 0 ? '#f0f0f0' : null)
    }
  }
  createPdf(docDefinition, tableLayouts, fonts).open()
}
