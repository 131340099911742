import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({ commonSetting: 'commonSetting/getData' })
  },

  methods: {
    mixinAllocateOwnerShowId(owner) {
      if (this.commonSetting.ownerShowIdHelperFlg === 1) {
        const prependText = this.commonSetting.ownerShowIdPrependText
        owner.showId = prependText.length >= 1 ? prependText + '-' : ''
        if (this.commonSetting.ownerShowIdNumberFlg === 1) {
          const ownersShowIds = this.$store.getters['owners/getData'].map(
            v => v.showId
          )
          const reg = new RegExp('^' + prependText + '-')
          const filteredShowIds =
            prependText.length >= 1
              ? ownersShowIds
                  .filter(showId => showId.match(reg))
                  .map(showId => Number(showId.replace(prependText + '-', '')))
                  .filter(showId => !Number.isNaN(showId) && showId > 0)
              : ownersShowIds
                  .map(showId => Number(showId))
                  .filter(showId => !Number.isNaN(showId) && showId > 0)
          const serialNumber =
            filteredShowIds.length !== 0
              ? Math.max.apply(null, filteredShowIds) + 1
              : 1
          owner.showId += String(serialNumber).padStart(
            this.commonSetting.ownerShowIdLength,
            '0'
          )
        }
      }
    },
    mixinAllocatePatientShowId(ownerId, patient) {
      if (this.commonSetting.patientShowIdHelperKind !== 0) {
        const ownerShowId = this.$store.getters['owners/getDataById'](ownerId)
          .showId
        const patientsShowIds = this.$store.getters['patients/getData'].map(
          v => v.showId
        )
        const reg = new RegExp('^' + ownerShowId + '-')
        const filteredShowIds =
          this.commonSetting.patientShowIdHelperKind === 1
            ? patientsShowIds
                .filter(showId => showId.match(reg))
                .map(showId => Number(showId.replace(ownerShowId + '-', '')))
                .filter(showId => !Number.isNaN(showId) && showId > 0)
            : patientsShowIds // patientShowIdHelperKind === 2の場合
                .map(showId => Number(showId))
                .filter(showId => !Number.isNaN(showId) && showId > 0)
        const serialNumber =
          filteredShowIds.length !== 0
            ? Math.max.apply(null, filteredShowIds) + 1
            : 1
        patient.showId =
          this.commonSetting.patientShowIdHelperKind === 1
            ? ownerShowId +
              '-' +
              String(serialNumber).padStart(
                this.commonSetting.patientShowIdLength,
                '0'
              )
            : String(serialNumber).padStart(
                this.commonSetting.patientShowIdLength,
                '0'
              )
      }
    }
  }
}
